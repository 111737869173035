import CookiesManager from "../../Utils/CookiesManager";
import IAction from "../../Types/Action";
import * as ActionTypes from '../ActionTypes';
import jwt from "jsonwebtoken";

export const SetProfile = (jwtToken: string): IAction => {
    const info = jwt.decode(jwtToken)! as { [k: string]: any };
    CookiesManager.setAccessToken(jwtToken);
    return {
        type: ActionTypes.SET_PROFILE,
        payload: {
            id: info['nameid'],
            name: info['given_name'],
            role: info['JobRole'],
            email: info['email']
        }
    }
}


export const SetJobRole = (role: string): IAction => {
    return {
        type: ActionTypes.SET_JOB_ROLE,
        payload: role
    }
}

export const SetNotifications = (numberOfNotifications: number): IAction => {
    return {
        type: ActionTypes.SET_NOTIFICATIONS,
        payload: numberOfNotifications
    }
}


export const Logout = (): IAction => {
    CookiesManager.deleteAllCookies();
    return {
        type: ActionTypes.DELETE_PROFILE,
        payload: {}
    }
}
