import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import IPagedResults, {IPagedSearch} from "Types/Paged";
import {SetIsLoading, ShowAlert} from "State/Actions/UiActions";
import {useDispatch} from "react-redux";
import {IRide, IRideSearch} from "Types/Rides";
import RidesClient from "Api/RidesClient";
import SharedRides from "Components/Shared/SharedRides";
import ScootersClient from "Api/ScootersClient";

interface IProps {
    open: boolean
    onClose: () => void;
    modal?: boolean;
    scooterId: number
}

const ScooterHistoryModal: FunctionComponent<IProps> = ({open, onClose, modal, scooterId}) => {
    const client = new ScootersClient();
    const [results, setResults] = useState<IPagedResults<IRide>>();
    const dispatch = useDispatch()
    const [search, setSearch] = useState<IPagedSearch<{scooterIdOrCode: number | string}>>({
        limit: 25,
        offset: 0,
        search: {
            scooterIdOrCode: scooterId,
        }
    });

    const {t} = useTranslation("rides")

    const onExport = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.ExportIOTDeviceHistory(search);
        dispatch(SetIsLoading(false))
        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer")
        }
    }

    const refresh = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.GetHistory(search);
        if (res.succeeded) {
            setResults(res.data);
        } else {
            dispatch(ShowAlert(t("somethingWentWrong"), "error"));
            onClose()
        }
        dispatch(SetIsLoading(false))
    }
    useEffect(() => {
        refresh().then();
    }, [search.limit, search.offset])


    return (
        <SharedRides
            modal={modal}
            open={open}
            onClose={onClose}
            search={search}
            setSearch={setSearch}
            results={results}
            onExport={onExport}
        />
    )
}
export default ScooterHistoryModal;
