import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title
} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import AnalyticsClient from "Api/AnalyticsClient";
import { SetIsLoading } from "State/Actions/UiActions";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { createStyles, Theme } from "@material-ui/core/styles";
import { GeneralAnalyticsSearch, PackagesCountSearch } from "Types/Analytics";
import SharedDropdown from "Components/Shared/Dropdown";
import { years } from "Utils/general";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title);

const PackagesCount = () => {
    const client = new AnalyticsClient()
    const classes = useStyles();
    const dispatch = useDispatch();
    const [results, setResults] = useState<any>([]);
    const [search, setSearch] = useState<PackagesCountSearch>({
        from: null,
        to: null,
        year: 2024
    })
    // const colors = useRandomColors(results)
    const statuses = [
        {label: 'All', value: undefined},
        {label: 'Active', value: false},
        {label: 'Inactive', value: true}
    ]

    const refresh = async (info: PackagesCountSearch) => {
        dispatch(SetIsLoading(true))
        let res = await client.GetPackagesCount(info);
        if (res.succeeded) {
            setResults(res.data);
        } else {

        }
        dispatch(SetIsLoading(false))
    }

    const exportToExcel = async (info: GeneralAnalyticsSearch) => {
        dispatch(SetIsLoading(true))
        let res = await client.GetPackagesCount(info);
        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer")
        }
        dispatch(SetIsLoading(false))
    }


    useEffect(() => {
        refresh(search).then();
    }, [search.year, search.isDeactivated])
    return (

        <Grid container xs={12}>
            <Box className={classes.container}>
                {/*<RiFileExcel2Fill onClick={() => exportToExcel({...search, exportToExcel: true})} className={classes.excelIcon} size={30}/>*/}
                <Box className={classes.doughnutFilter}>
                    <Typography style={{ fontSize: '24px', fontWeight: '600', paddingTop: '1rem' }}>Packages
                        Count</Typography>
                    {/*<Doughnut data={data}/>*/}
                    {results?.datasets?.length > 0 &&
                        <Grid item container xs={12} justifyContent='space-between'>
                            <Grid 
                                item container xs={12}
                                alignItems= 'center'
                                justifyContent= 'flex-end'
                                spacing={2}
                                >
                                <Grid item xs={5} md={3}>
                                    {/*<RiFileExcel2Fill onClick={() => exportToExcel({...search, exportToExcel: true})}*/}
                                    {/*                  className={classes.excelIcon} size={30}/>*/}
                                    <SharedDropdown
                                        options={years}
                                        label={('Year')}
                                        value={search.year ?? undefined}
                                        onChange={(e: any) => {
                                            setSearch({
                                                ...search,
                                                year: e.target.value

                                            })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5} md={3}>
                                    <SharedDropdown
                                        options={statuses}
                                        label={('Status')}
                                        value={search.isDeactivated ?? undefined}
                                        onChange={(e: any) => {
                                            setSearch({
                                                ...search,
                                                isDeactivated: e.target.value

                                            })
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Line
                                style={{ height: '80%', width: '100%' }}
                                datasetIdKey='id'
                                data={results}
                            />
                        </Grid>
                    }
                </Box>



                {/*<SharedAnalyticsFilter*/}
                {/*    search={search}*/}
                {/*    onChange={setSearch}*/}
                {/*    onClear={async () => {*/}
                {/*        setSearch({from: null, to: null})*/}
                {/*        await refresh(GeneralAnalyticsSearchInitialState)*/}
                {/*    }}*/}
                {/*    onSearch={async () => {*/}
                {/*        await refresh(search)*/}
                {/*    }}*/}
                {/*/>*/}
            </Box>
        </Grid>
    );
}

export default PackagesCount;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: 'white',
            width: '100%',
            height: '100%',
            borderRadius: '20px',
            padding: '10px',
            display: 'flex',
        },
        doughnutFilter: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            minWidth: '85%'
        },
        excelIcon: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
        },
    }),
);

