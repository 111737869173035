import React, {Fragment, FunctionComponent, useEffect, useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {FormControl, InputLabel, MenuItem, Modal, Select, TextField} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {IScooterModel, IUpdateScooterModel} from "../../../../Types/Scooters";
import {ILookupModel} from "../../../../Types/Lookup";
import ScootersClient from "../../../../Api/ScootersClient";
import AreasClient from "../../../../Api/AreasClient";

interface IProps {
    onClose: () => void
    data: IScooterModel | undefined
    id: string | undefined
}

const UpdateIotDevice: FunctionComponent<IProps> = (props: IProps): JSX.Element => {
    const classes = useStyles();
    const {t} = useTranslation("scooters")
    const [info, setInfo] = useState<IUpdateScooterModel>({
        code: props.data!.code!, areaId: props.data!.area!.id!, rate: {
            perMinute: props.data?.rate?.perMinute,
            initialCharge: props.data?.rate?.initialCharge
        },
        imei: props.data?.imei ?? ''
    })
    const [areas, setAreas] = useState<ILookupModel[]>([])
    const client = new ScootersClient();
    const areaClient = new AreasClient();
    const getAreas = async () => {
        let res = await areaClient.GetLookupsAsync();
        if (res.succeeded) {
            setAreas(res.data);
        }
    }
    useEffect(() => {
        getAreas();
    }, [])

    const onSubmit = async () => {

        const res = await client.UpdateAsync(props.id!, info)
        if (res.succeeded) {
            props.onClose()
        }
    }
    return (
        <Modal
            open={true}
            onClose={props.onClose}
            className={classes.modal}
        >
            <div className={classes.paper}>
                <h2 id="simple-modal-title">{t("updateTitle")}</h2>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label={t("codeHeader")}
                            fullWidth
                            value={info?.code}
                            onChange={(e) => setInfo({
                                ...info,
                                code: e.target.value
                            })}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label={"IMEI"}
                            fullWidth
                            value={info?.imei}
                            onChange={(e) => setInfo({
                                ...info,
                                imei: e.target.value
                            })}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label={t("initialCharge")}
                            fullWidth
                            type={"number"}
                            value={info?.rate?.initialCharge}
                            onChange={(e) => setInfo({
                                ...info,
                                rate: {
                                    ...info.rate,
                                    initialCharge: Number(e.target.value)
                                }
                            })}
                        />
                    </Grid> <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        label={t("perMinute")}
                        fullWidth
                        type={"number"}

                        value={info?.rate?.perMinute}
                        onChange={(e) => setInfo({
                            ...info,
                            rate: {
                                ...info.rate,
                                perMinute: Number(e.target.value)
                            }
                        })}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                        <FormControl style={{minWidth: '100%',}}>
                            <Fragment>
                                <InputLabel>{t("areaHeader")}</InputLabel>
                                <Select
                                    fullWidth
                                    value={areas.find(x => x.value == info.areaId)?.value}
                                    onChange={(e) => setInfo({
                                        ...info,
                                        areaId: e.target.value as any
                                    })}
                                >
                                    {areas?.map(m => <MenuItem value={m.value}>{m.label}</MenuItem>)}
                                </Select>

                            </Fragment>

                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                        >
                            {t("saveButton")}
                        </Button>
                    </Grid>
                </Grid>

            </div>

        </Modal>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,

            padding: theme.spacing(2, 4, 3),
        },
    }),
);

export default UpdateIotDevice;
