import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookiesManager from "../Utils/CookiesManager";
import {IPagedSearch} from "../Types/Paged";

class PaymentsClient {
    client: IClient;
    locale: string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }


    async SearchAsync(
        search: IPagedSearch<{mobile: string}>
    ): Promise<ApiResponse> {
        let query = `Payments?limit=${search.limit}&offset=${search.offset}`
        if (search.search?.mobile) {
            query += `&mobile=${search.search?.mobile}`
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async ExportToExcelAsync(
        search: IPagedSearch<{mobile: string}>
    ): Promise<ApiResponse> {
        let query = `Payments/Export`
        if (search.search?.mobile) {
            query += `&mobile=${search.search?.mobile}`
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }
}

export default PaymentsClient;
