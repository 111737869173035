import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookiesManager from "../Utils/CookiesManager";
import {GeneralAnalyticsSearch, PackagesCountSearch} from "Types/Analytics";

class AnalyticsClient {
    client: IClient;
    locale: string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }


    async GetRidesCount(search: GeneralAnalyticsSearch): Promise<ApiResponse> {
        let query = `Analytics/RidesCount`
        if (search.from) query += `?from=${search.from}`
        if (search.to) query += `&to=${search.to}`
        if (search.exportToExcel) {
            query += query.includes('from') ?  '&exportToExcel=true' : '?exportToExcel=true'
        }
        if (search.year) {
            query += query.includes('from') ?  `&year=${search.year}` : `?year=${search.year}`
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async GetRidesPerUser(search: GeneralAnalyticsSearch): Promise<ApiResponse> {
        let query = `Analytics/RidesPerUser`
        if (search.from) query += `?from=${search.from}`
        if (search.to) query += `&to=${search.to}`
        if (search.exportToExcel) {
            query += query.includes('from') ?  '&exportToExcel=true' : '?exportToExcel=true'
        }
        if (search.year) {
            query += query.includes('from') ?  `&year=${search.year}` : `?year=${search.year}`
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async GetScooterStatuses(exportToExcel?: boolean): Promise<ApiResponse> {
        let query = `Analytics/ScooterByStatus`
        if (exportToExcel) {
            query += '?exportToExcel=true'
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async GetScootersPerArea(exportToExcel?: boolean): Promise<ApiResponse> {
        let query = `Analytics/ScootersPerArea`
        if (exportToExcel) {
            query += '?exportToExcel=true'
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async GetPackagesCount(search: PackagesCountSearch): Promise<ApiResponse> {
        let query = `Analytics/PackagesCount`
        if (search.from) {
            query += `?from=${search.from}`
        }
        if (search.to) {
            query += `&to=${search.to}`
        }
        if (search.exportToExcel) {
            query += query.includes('from') ?  '&exportToExcel=true' : '?exportToExcel=true'
        }
        if (search.year) {
            query += query.includes('from') ?  `&year=${search.year}` : `?year=${search.year}`
        }
        if (search.isDeactivated?.toString())
            query += `&isDeactivated=${search.isDeactivated}`
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async GetRidesPerArea(search: GeneralAnalyticsSearch): Promise<ApiResponse> {
        let query = `Analytics/RidesPerArea`
        if (search.from) {
            query += `?from=${search.from}`
        }
        if (search.to) {
            query += `&to=${search.to}`
        }
        if (search.exportToExcel) {
            query += query.includes('from') ?  '&exportToExcel=true' : '?exportToExcel=true'
        }
        if (search.year) {
            query += query.includes('from') ?  `&year=${search.year}` : `?year=${search.year}`
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async UsersActivity(date: string | null, exportToExcel?: boolean): Promise<ApiResponse> {
        let query = `Analytics/ActiveUsers`
        if (date) {
            query += `?date=${date}`
        }
        if (exportToExcel) {
            query += query.includes('date') ?  '&exportToExcel=true' : '?exportToExcel=true'
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

}

export default AnalyticsClient;
