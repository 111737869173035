import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import {makeStyles} from "@material-ui/styles";
import {useTranslation} from "react-i18next";
import CookiesManager from "../../Utils/CookiesManager";
import {Logout} from "../../State/Actions/ProfileActions";
import {useDispatch, useSelector} from "react-redux";
import {ExitToApp} from "@material-ui/icons";
import { useHistory } from 'react-router-dom';
import IAppStateModel from 'Types/AppState';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuButton: {
            marginLeft: -theme.spacing(1),
        },
        iconButtonAvatar: {
            padding: 4,
        },
        link: {
            textDecoration: 'none',
            color: lightColor,
            '&:hover': {
                color: theme.palette.common.white,
            },
        },
        button: {
            borderColor: lightColor,
        },
        numberOfNotifications: {
            color: 'white',
            fontSize: 12,
            fontWeight: 'bold',
        },
        notificationsButton: {
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0, 0.4)',
            height: 20,
            width: 20,
            borderRadius: 10,
            top: 1,
            left: 2
        }
    }),
);

const Header = (props:any) => {
    const history = useHistory()
    const numberOfNotifications = useSelector((state: IAppStateModel) => state.profile?.numberOfNotifications)
    const classes = useStyles();
    const {i18n, t} = useTranslation("navigation");
    const dispatch = useDispatch();

    const isNotifications = numberOfNotifications && numberOfNotifications > 0

    return (

            <AppBar color="primary" position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">
                        {/*// @ts-ignore*/}
                        <Hidden smUp>
                            <Grid item>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={props.onDrawerToggle}
                                    className={classes.menuButton}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>
                        <Grid item xs />
                        <Grid item>
                            <Tooltip title="language">
                                <Button
                                    onClick={() => {
                                        i18n.changeLanguage(i18n.language === "en" ? "ar" : "en");
                                        CookiesManager.setLocale(i18n.language);
                                    }}
                                    color="inherit"
                                >
                                {t("lang")}
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={isNotifications  ? "Alerts • No alerts" : ""}>
                                <IconButton style={{position: 'relative'}} color="inherit" onClick={() => history.replace('/staffNotifications')}>
                                    <NotificationsIcon />
                                    {Boolean(isNotifications) && <Grid className={classes.notificationsButton}>
                                        <Typography className={classes.numberOfNotifications}>
                                            {isNotifications && numberOfNotifications > 99 ? '+99' : numberOfNotifications}
                                        </Typography>
                                    </Grid>}
                                </IconButton>

                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <IconButton color="inherit" className={classes.iconButtonAvatar}>
                                <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Alerts • No alerts">
                                <IconButton onClick={() => dispatch(Logout())} color="inherit">
                                    <ExitToApp />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>


    );
}

export default Header;
