import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import {InitialValuesType} from "Types/MobilePopups";
import {parseDate} from "Utils/dateUtils";
import BlobsClient from "Api/BlobsClient";
import {toBase64} from "Utils/filesUtil";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            width: 900,
            height: 300
        },
        button: {
            color: 'white'
        }
    }),
);

interface IProps {
    onClose: () => void
    open: boolean;
    mobilePopValue: InitialValuesType;
    setMobilePopupValue: (e: InitialValuesType) => void;
    onSubmit: () => Promise<void>;
    modalType?: 'ADD' | 'EDIT'
}

const CreatePopupModal = ({onClose, open, mobilePopValue, setMobilePopupValue, onSubmit, modalType}: IProps) => {
    const classes = useStyles();
    const {t} = useTranslation("mobilePopups")
    let client = new BlobsClient();

    const onUploadFile = async (e: any) => {
        const file = e.target?.files?.[0];
        console.log(file, 'file')
        if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
            const x = await toBase64(file);
            const response = await client.UploadImage({
                //@ts-ignore
                base: x,
                fileName: `${Math.random().toString().slice(0, 9)}`,
                fileType: file.type
            })
            if (response.succeeded) {
                setMobilePopupValue({
                    ...mobilePopValue,
                    props: {
                        ...mobilePopValue.props,
                        imageUrl: response.data.location
                    }
                })
            }
        }
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">{modalType === 'ADD' ? t('addPopup') : t('editPopup')}</h2>
                        <Grid container spacing={3}>

                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    type={"color"}
                                    variant={"outlined"}
                                    label={t("circleColor")}
                                    fullWidth
                                    value={mobilePopValue.props.closeIconCircleColor}
                                    onChange={(e) => {
                                        setMobilePopupValue({
                                            ...mobilePopValue,
                                            props: {
                                                ...mobilePopValue.props,
                                                closeIconCircleColor: e.target.value
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    type={"color"}
                                    variant={"outlined"}
                                    label={t("iconColor")}
                                    fullWidth
                                    value={mobilePopValue.props.closeIconColor}
                                    onChange={(e) => {
                                        setMobilePopupValue({
                                            ...mobilePopValue,
                                            props: {
                                                ...mobilePopValue.props,
                                                closeIconColor: e.target.value
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    type={"text"}
                                    variant={"outlined"}
                                    label={t("link")}
                                    fullWidth
                                    value={mobilePopValue.props.link}
                                    onChange={(e) => {
                                        setMobilePopupValue({
                                            ...mobilePopValue,
                                            props: {
                                                ...mobilePopValue.props,
                                                link: e.target.value
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    style={{marginInline: 5}}
                                    required
                                    type={"date"}
                                    label={t("startsOn")}
                                    fullWidth
                                    value={parseDate(mobilePopValue.startsOn || undefined, true)}
                                    onChange={(e) => {
                                        setMobilePopupValue({
                                            ...mobilePopValue,
                                            startsOn: new Date(e.target.value),
                                        })
                                    }
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    style={{marginInline: 5}}
                                    required
                                    type={"date"}
                                    label={t("expiresOn")}
                                    fullWidth
                                    value={parseDate(mobilePopValue.expiresOn || undefined, true)}
                                    onChange={(e) => {
                                        setMobilePopupValue({
                                            ...mobilePopValue,
                                            expiresOn: new Date(e.target.value),
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={12} sm={4} justifyContent={'flex-start'}>
                                <Grid item>
                                {
                                    mobilePopValue.props.imageUrl &&
                                    <img style={{width: 50, height: 50, marginRight: 10, borderRadius: 5}} src={mobilePopValue.props.imageUrl}/>
                                }
                                </Grid>
                                <Grid item>

                                <Button
                                    style={{marginTop: 10}}
                                    variant="contained"
                                    component="label"
                                >
                                    Attach Image
                                    <input
                                        onChange={onUploadFile}
                                        type="file"
                                        hidden
                                    />
                                </Button>
                                </Grid>

                            </Grid>
                            <Grid container item justifyContent={'flex-end'}>
                                <Button className={classes.button} onClick={onSubmit} variant="contained" color="primary">
                                    {t("submit")}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default CreatePopupModal;
