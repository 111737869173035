import React, {Fragment, FunctionComponent, useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {FormControl, InputLabel, MenuItem, Modal, Select, TextField} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ScootersClient from "../../../../Api/ScootersClient";
import {SetIsLoading, ShowAlert} from "../../../../State/Actions/UiActions";
import {useDispatch} from "react-redux";
import {ILookupModel} from "../../../../Types/Lookup";

interface IProps {
    onClose: () => void;
    id: number | undefined;
}

const statuses = [
    {label: 'Active', value: 11},
    {label: 'Under Maintenance', value: 41},
    {label: 'Lost', value: 61},
    {label: 'Stolen', value: 71},
    {label: 'Out Of Service', value: 81},
]

const options: ILookupModel[] = [
    {label: "Regular", value: "11"},
    {label: "Wheels", value: "12"},
    {label: "Screen", value: "13"},
    {label: "Bell", value: "14"},
    {label: "Throttle", value: "15"},
    {label: "Grip", value: "16"},
    {label: "Tires", value: "17"},
    {label: "IOT_Module", value: "18"},
    {label: "FrontLight", value: "19"},
    {label: "BreakLight", value: "20"},
    {label: "Kickstand", value: "21"},
    {label: "Battery", value: "22"},
    {label: "Motor", value: "23"},
    {label: "PhoneHolder", value: "24"},
    {label: "Brakes", value: "25"},
    {label: "Barcode", value: "26"},
]

const ChangeStatusModal: FunctionComponent<IProps> = (props: IProps): JSX.Element => {
    const classes = useStyles();
    const {t} = useTranslation("scooters")
    const [status, setStatus] = useState<number | undefined>();
    const [reasonId, setReasonId] = useState()
    const [note, setNote] = useState('')
    const client = new ScootersClient();
    const dispatch = useDispatch();
    const onSubmit = async () => {
        //Under Maintenance
        if (status === 41 && !reasonId) {
            return
        }
        if (status) {
            dispatch(SetIsLoading(true))
            const res = await client.ChangeStatus(props.id!, {status, reasonId, note})
            if (res.succeeded) {
                dispatch(ShowAlert(t("statusChangeSuccessfully"), "success"));
                props.onClose()
            } else {
                dispatch(ShowAlert(t("somethingWentWrong", {ns: 'areas'}), "success"));
            }
            dispatch(SetIsLoading(true))
        }
    }

    return (
        <Modal
            open={true}
            onClose={props.onClose}
            className={classes.modal}
        >
            <div className={classes.paper}>
                <h2 id="simple-modal-title">{t("changeStatus")}</h2>
                <Grid item sm={12} style={{marginBottom: 30}}>
                    <FormControl fullWidth>
                        <Fragment>
                            <InputLabel>{t("status")}</InputLabel>
                            <Select
                                fullWidth
                                value={status}
                                onChange={(e) => {
                                    if (note !== '' || reasonId) {
                                        setReasonId(undefined)
                                        setNote('')
                                    }
                                    setStatus(Number(e.target.value))
                                }}
                            >
                                {statuses.map(m => <MenuItem value={m.value}>{m.label}</MenuItem>)}
                            </Select>

                        </Fragment>
                    </FormControl>
                </Grid>
                {
                    status === 41 &&
                    <>
                        <Grid item sm={12} style={{marginBottom: 30}}>
                            <FormControl fullWidth>
                                <InputLabel>{t("reason")}</InputLabel>
                                <Select
                                    fullWidth
                                    value={options.find(x => x.value === reasonId)?.value}
                                    onChange={(e) => {
                                        setReasonId(e.target.value as any)
                                    }}
                                >
                                    {options?.map(m => <MenuItem value={m.value}>{m.label}</MenuItem>)}
                                </Select>

                            </FormControl>
                        </Grid>
                        <Grid item sm={12} style={{marginBottom: 30}}>
                            <FormControl fullWidth>
                                <TextField
                                    required
                                    label={t("note")}
                                    fullWidth
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </>
                }



                <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                        >
                            {t("submit")}
                        </Button>
                    </Grid>
            </div>
        </Modal>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2, 4, 3),
            width: '500px'
        },
    }),
);

export default ChangeStatusModal;
