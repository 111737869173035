import React, {useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import AnalyticsClient from "Api/AnalyticsClient";
import {SetIsLoading} from "State/Actions/UiActions";
import {useDispatch} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Box} from "@material-ui/core";
import {GeneralAnalyticsSearch} from "Types/Analytics";
import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core/styles";
import SharedDropdown from "Components/Shared/Dropdown";
import {years} from "Utils/general";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title);


const RidesCount = () => {
    const client = new AnalyticsClient()
    const dispatch = useDispatch();
    const classes = useStyles();
    const [search, setSearch] = useState<any>({
        from: null,
        to: null,
        year: 2024
    });
    const [results, setResults] = useState<any>();

    const refresh = async (info: GeneralAnalyticsSearch) => {
        dispatch(SetIsLoading(true))
        let res = await client.GetRidesCount(info);
        if (res.succeeded) {
            setResults(res.data);
        } else {

        }
        dispatch(SetIsLoading(false))
    }

    const exportToExcel = async (info: GeneralAnalyticsSearch) => {
        dispatch(SetIsLoading(true))
        let res = await client.GetRidesCount(info);
        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer")
        }
        dispatch(SetIsLoading(false))
    }


    useEffect(() => {
        refresh(search).then();
    }, [search.year])


    return (
        <Grid container xs={12}>
            <Box className={classes.container}>

                <Box className={classes.doughnutFilter}>
                    <Typography style={{fontSize: '24px', fontWeight: '600', paddingTop: '1rem'}}>Rides
                        Count</Typography>
                    {results?.datasets?.length > 0 &&
                        <Line
                            style={{height: '100%', width: '100%'}}
                            datasetIdKey='id'
                            data={results}
                        />
                    }

                </Box>
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start'
                }}>
                    <Box>
                            <SharedDropdown
                            options={years}
                            label={('Year')}
                            value={search.year ?? undefined}
                            onChange={(e: any) => {
                                setSearch({
                                    ...search,
                                    year: e.target.value

                                })
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
}

export default RidesCount;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: 'white',
            width: '100%',
            height: '100%',
            borderRadius: '20px',
            padding: '10px',
            display: 'flex',
        },
        doughnutFilter: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            minWidth: '85%'
        },
        excelIcon: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
        },
    }),
);
