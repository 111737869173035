import React from 'react';
import {useSelector} from 'react-redux';
import IAppStateModel from "Types/AppState";


interface IProps {
    allowedRoles?: string[]
    children: any
    all?: boolean
}

const Authorized = (props: IProps) => {

    const jobRole = useSelector((state: IAppStateModel) => state.profile)?.role
    if (props?.all || props?.allowedRoles?.some(x => x == jobRole)) {
        return (
            <>
                {props.children}
            </>
        );
    } else {
        return <></>;
    }


}

export default React.memo(Authorized);
