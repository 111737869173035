import React, {useEffect, useState} from 'react';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import AnalyticsClient from "Api/AnalyticsClient";
import {SetIsLoading} from "State/Actions/UiActions";
import {useDispatch} from "react-redux";
import {generateRandomColor, unCamelCase} from "Utils/general";
import Grid from "@material-ui/core/Grid";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core/styles";
import {RiFileExcel2Fill} from "react-icons/all";

ChartJS.register(ArcElement, Tooltip, Legend);

const ScootersPerArea = () => {
    const client = new AnalyticsClient()
    const dispatch = useDispatch();
    const [results, setResults] = useState<any>([]);
    const classes = useStyles()
    const data = {
        labels: results?.map((x: any) => unCamelCase(x.areaName)) ?? [],
        datasets: [
            {
                label: '# of Votes',
                data: results?.map((x: any) => x.scooterCount) ?? [],
                backgroundColor: results.map((x: any) => generateRandomColor()),
                borderWidth: 1,
            },
        ],
    };

    const refresh = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.GetScootersPerArea();
        if (res.succeeded) {
            setResults(res.data?.result);
        } else {

        }
        dispatch(SetIsLoading(false))
    }

    const exportToExcel = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.GetScootersPerArea(true);
        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer")
        } else {

        }
        dispatch(SetIsLoading(false))
    }

    useEffect(() => {
        refresh().then();
    }, [])

    return (
        <Grid container xs={12}>
            <Box className={classes.container}>
                <RiFileExcel2Fill onClick={exportToExcel} className={classes.excelIcon} size={30}/>
                <Typography style={{fontSize: '24px', fontWeight: '600', paddingTop: '1rem'}}>Scooters Per Area</Typography>
                <Doughnut data={data}/>
            </Box>
        </Grid>
);
}

export default ScootersPerArea;


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: 'white',
            width: '100%',
            height: '100%',
            borderRadius: '20px',
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            // maxHeight: 392,
            position: 'relative'
        },
        excelIcon: {
            color: theme.palette.primary.main,
            marginTop: 5,
            cursor: 'pointer',
            position: 'absolute',
            right: 20,
            top: 20
        },
    }),
);

