import { makeStyles, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 1200,
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
    Chip: {
      color: "white",
    },
    redChip: {
      backgroundColor: "red",
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: "40px 16px",
    },
    icon: {
      cursor: "pointer",
    },
    currentBalanceContainer: { display: "flex", flexDirection: "row" },
    currentBalanceHeader: {
      color: "#000",
      opacity: 0.87,
      fontSize: "14px",
      padding: 0,
    },
    tableHeader: {
      fontWeight: 600,
      color: theme.palette.primary.dark,
      fontSize: 15,
    },
  })
);
