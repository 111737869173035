import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {parseDate} from "Utils/dateUtils";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {SetIsLoading, ShowAlert} from "State/Actions/UiActions";
import {useTranslation} from "react-i18next";
import {Button} from "@material-ui/core";
import MobilePopupsClient from "Api/MobilePopupsClient";
import {CreatePopupModel, InitialValuesType, MobilePopupsSearchResponseModel} from "Types/MobilePopups";
import IPagedResults, {IPagedSearch} from "Types/Paged";
import CreatePopupModal from "Components/Views/MobilePopups/Components/CreatePopupModal";
import SharedTable from "Components/Shared/SharedTable";

type modalTypes = 'EDIT' | 'ADD' | 'NONE'


const MobilePopups = () => {
    const classes = useStyles();
    const [results, setResults] = useState<IPagedResults<MobilePopupsSearchResponseModel>>();
    const [search, setSearch] = useState<IPagedSearch<any>>({
        limit: 25,
        offset: 0
    });
    const {t} = useTranslation("mobilePopups")
    const [modal, setModal] = useState<modalTypes>('NONE');
    const initialValues = {
        props: {
            // title: '',
            // body: '',
            closeIconCircleColor: '',
            closeIconColor: '',
            link: '',
            imageUrl: '',
            buttonColor: ''
        },
        id: 0,
        startsOn: new Date(),
        expiresOn: new Date(),
        popupPlacement: null,
    }
    const [mobilePopup, setMobilePopup] = useState<InitialValuesType>(initialValues);
    let client = new MobilePopupsClient();
    const dispatch = useDispatch();

    const createMobilePopup = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.CreatePopup({...mobilePopup, props: JSON.stringify(mobilePopup.props)} as CreatePopupModel);
        if (res.succeeded) {
            setModal('NONE')
            dispatch(ShowAlert(t("The Popup was created successfully"), "success"));
            refresh().then()
        } else {
            dispatch(ShowAlert(t("Something went wrong"), "error"));
        }
        dispatch(SetIsLoading(false))
    }

    const updateModalPopup = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.UpdatePopup({...mobilePopup, props: JSON.stringify(mobilePopup.props)} as CreatePopupModel);
        if (res.succeeded) {
            setModal('NONE')
            dispatch(ShowAlert(t("The Popup was updated successfully"), "success"));
            refresh().then()
        } else {
            dispatch(ShowAlert(t("Something went wrong"), "error"));
        }
        dispatch(SetIsLoading(false))
    }



    const refresh = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.SearchAsync(search);
        if (res.succeeded) {
            setResults(res.data);
        } else {
            dispatch(ShowAlert(t("Something went wrong"), "error"));
        }
        dispatch(SetIsLoading(false))
    }

    useEffect(() => {
        refresh().then()
    }, [])

    const columns = [
        {
            field: 'id',
            headerName: t('Id'),
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: 'title',
            headerName: t('iconColor'),
            width: 150,
            sortable: false,
            renderCell: (params: any) => {
                return (
                    params.row.props.closeIconColor
                )
            }

        },
        {
            field: 'body',
            headerName: t('circleColor'),
            width: 150,
            sortable: false,
            renderCell: (params: any) => {
                return (
                    params.row.props.closeIconCircleColor
                )
            }
        },
        {
            field: 'link',
            headerName: t('link'),
            width: 300,
            sortable: false,
            renderCell: (params: any) => {
                return (
                    params.row.props.link
                )
            }
        },
        {
            field: 'startsOn',
            headerName: t('startsOn'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row.startsOn, true)
                )
            }
        },
        {
            field: 'expiresOn',
            headerName: t('expiresOn'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row.expiresOn, true)
                )
            }
        },
        {
            field: 'actions',
            headerName: t('actions', {ns: 'actions'}),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    <div className={classes.buttonContainer}>
                            <Button onClick={() => {
                                setMobilePopup(params.row)
                                setModal('EDIT')
                            }} className={classes.activateButton} variant="contained" color="primary">
                                {t("Edit")}
                            </Button>
                    </div>
                )
            }
        }

    ];

    return (
        <>
            {modal === 'ADD' &&
                <CreatePopupModal
                    modalType={'ADD'}
                    onSubmit={createMobilePopup}
                    mobilePopValue={mobilePopup}
                    onClose={() => setModal('NONE')}
                    setMobilePopupValue={(p => setMobilePopup(p))}
                    open={modal === 'ADD'}
                />
            }
            {modal === 'EDIT' &&
                <CreatePopupModal
                    modalType={'EDIT'}
                    onSubmit={updateModalPopup}
                    mobilePopValue={mobilePopup}
                    onClose={() => setModal('NONE')}
                    setMobilePopupValue={(p => setMobilePopup(p))}
                    open={modal === 'EDIT'}
                />
            }
            {results?.total === 0 && <Grid container justifyContent={'flex-end'}>
                <Grid item style={{marginBottom: 10}}>
                    <Button
                        onClick={() => {
                            setMobilePopup(initialValues)
                            setModal('ADD')
                        }}
                        variant="contained" color="primary" className={classes.addUser}>
                        {t("Add Popup")}
                    </Button>
                </Grid>
            </Grid> }

            <div style={{height: 500, width: '100%', backgroundColor: 'white', borderRadius: '2px'}}>
                <SharedTable
                    columns={columns}
                    rows={results?.matches ?? []}
                    pageSize={search?.limit}
                    onPageChange={(i) => setSearch((s) => ({...s, offset: (i) * s.limit}))}
                    onPageSizeChange={(i) => setSearch((s) => ({...s, limit: i}))}
                    rowCount={results?.total}
                    page={Math.ceil(search?.offset / search?.limit)}
                    getRowId={(i: any) => {
                        return i.id
                    }}
                />
            </div>

        </>
    );
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: 1400,
            margin: 'auto',
            overflow: 'hidden',
        },
        Chip: {
            color: "white"
        },
        redChip: {
            backgroundColor: "red"
        },
        blueChip: {
            backgroundColor: "blue"
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        contentWrapper: {
            margin: '40px 16px',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        deactivateButton: {
            width: '100px',
            color: 'white',
            backgroundColor: 'red'
        },
        activateButton: {
            width: '100px',
            backgroundColor: '#99C455'
        },
    }),
);
export default MobilePopups
