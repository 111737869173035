import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import CookiesManager from "../Utils/CookiesManager";
import {IPagedSearch} from "../Types/Paged";
import {ICreatePromoCode} from "../Types/PromoCodes";

class PromoCodesClient {
    client: IClient;
    locale: string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }


    async SearchAsync(
        search: IPagedSearch<{ searchText?: string }>
    ): Promise<ApiResponse> {
        let query = `promoCodes?limit=${search.limit}&offset=${search.offset}`
        
        if (search.search?.searchText != null)
            query += `&searchText=${search.search?.searchText}`
        
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }


    async CreateAsync(
        data?: ICreatePromoCode
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync(`promoCodes`, data);
        return rs;
    }
}

export default PromoCodesClient;
