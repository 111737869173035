
const palette = {
    primary: {
        light: '#b0cf7e',
        main: '#99C455',
        dark: '#212e0c',
        extraLight: '#bec4b3'
    },
    secondary: {
        light: '#939598',
        main: '#939598',
        dark: '#939598',
    },
};

export const shape = {
    borderRadius: 8,
};
export default palette;
