import React, {useState} from 'react';
import {FormControl, InputLabel, MenuItem, Modal, Select, TextField} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import StaffClient from "Api/StaffClient";
import {ICreateStaffMember} from "Types/Staff";


interface IProps {
    onClose: () => void;
}

const Component = (props: IProps) => {
    const classes = useStyles();

    const roles = [
        {
            label: 'Admin',
            value: 101
        },
        {
            label: 'Operation',
            value: 102
        },
        {
            label: 'Customer Service',
            value: 103
        },
    ]


    const [info, setInfo] = useState<ICreateStaffMember>();
    const [errorMsg, setErrorMsg] = useState('');

    const client = new StaffClient();
    const onSubmit = async () => {
        if (info) {
            let res = await client.AddAsync(info!);
            if (res.succeeded) {
                props.onClose()
            } else {
                setErrorMsg(t('failedError'))
            }
        } else {
            setErrorMsg(t('requiredFieldsError'))
        }

    }

    const {t} = useTranslation("staff")
    return (
        <Modal
            open={true}
            onClose={props.onClose}
            className={classes.modal}
        >
            <div className={classes.paper}>
                <h2 id="simple-modal-title">{t("addTitle")}</h2>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label={t("nameHeader")}
                            fullWidth
                            value={info?.name}
                            onChange={(e) => setInfo({
                                ...info,
                                name: e.target.value
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label={t("emailHeader")}
                            fullWidth
                            value={info?.email}
                            onChange={(e) => setInfo({
                                ...info,
                                email: e.target.value
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label={t("passwordHeader")}
                            fullWidth
                            value={info?.password}
                            onChange={(e) => setInfo({
                                ...info,
                                password: e.target.value
                            })}
                        />

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl style={{minWidth: '100%',}}>
                            <InputLabel>{t("Job Role")}</InputLabel>
                            <Select
                                fullWidth
                                value={info?.jobRole ?? ''}
                                onChange={(e) => {
                                    setInfo({
                                        ...info,
                                        jobRole: e.target.value as any
                                    })
                                }}
                            >
                                {roles?.map((m: { label: string, value: number }) => <MenuItem
                                    value={m.value}>{m.label}</MenuItem>)}
                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid item xs={12}>
                        <Typography color={'error'}>
                            {errorMsg}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                        >
                            {t("addButton")}
                        </Button>
                    </Grid>
                </Grid>

            </div>

        </Modal>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,

            padding: theme.spacing(2, 4, 3),
        },
    }),
);

export default Component;
