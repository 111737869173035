import {IAreaModel} from "./Areas";
import {IRide} from "./Rides";

export interface IScooterModel {
    id: string;
    code: string;
    imei: string;
    manufacturer: string;
    model: string;
    location: LocationModel;
    condition: ConditionModel;
    area?: IAreaModel
    rate?: IRate
    rides: IRide[]
    status: string
    isLowBattery: boolean;
}

export interface IUpdateScooterModel {
    code: string;
    areaId: string | number;
    rate?: IRate
    imei?: string
}

export interface IRate {
    initialCharge?: number
    perMinute?: number
}

export interface LocationModel {
    latitude: string;
    longitude: string;
    timeStamp: string;
}

export interface ConditionModel {
    iotCharge: string;
    scooterCharge: string;
    timeStamp: string;
}

export interface ICreateScooter {
    code?: string;
    imei?: string;
    manufacturer?: string;
    model?: string;
    areaId?: string
}

export interface IMaintenanceLogModel {
    id?: string
    createdOn?: Date
    reason?: string
    reasonId?: number
    note?: string
}

export interface IScooterLog {
    id: string;
    imei: string;
    iotBattery: string;
    latitude: string;
    longitude: string;
    messageNumber: string;
    roaming: boolean;
    scooterBattery: string;
    scooterId: string;
    signalStrength: string;
    speed: string;
    timeStamp: Date;
    tripDistance: string;
    tripTime: string;
    vehicleHardwareStateCode: string;
    WheelBasedSpeed: string;
}

export interface IScootersSearchModel {
    id?: number;
    code?: string;
    imei?: string;
    statusId?: number | null;
    manufacturerId?: number | null;
    isLowBattery?: boolean | null;
    areaId?: number;
    lookup?: boolean;
}

export interface ScooterConfig {
    timeSinceLastLog: number;
    minimumChargePercentageToStart: number;
    timeSinceLastRide: number;
    
}


export enum ScooterStatus {
    Active = 11,
    InRide = 21
}

