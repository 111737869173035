import React, {useCallback, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {createStyles, Theme} from '@material-ui/core/styles';
import {makeStyles} from "@material-ui/styles";
import IPagedResults, {IPagedSearch} from "Types/Paged";
import {useDispatch} from "react-redux";
import {SetIsLoading, ShowAlert} from "State/Actions/UiActions";
import {IAreaModel} from "Types/Areas";
import AreasClient from "Api/AreasClient";
import {useTranslation} from "react-i18next";
import AreaModal from "./Components/AreaModal";
import Button from "@material-ui/core/Button";
import dayjs from "dayjs";
import {MapOutlined} from "@material-ui/icons";
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationModal from "Components/Shared/modals/ConfirmationModal";
import Authorized from "Components/Shared/Authorized";
import {Admin} from "Types/JobRole";
import SharedTable from "Components/Shared/SharedTable";
import {Paper, Typography} from "@material-ui/core";
import ScootersModal from "Components/Views/Areas/Components/ScootersModal";
import {ScooterStatus} from "Types/Scooters";


const Component = () => {
    const classes = useStyles();

    const [search, setSearch] = useState<IPagedSearch<any>>({
        limit: 25,
        offset: 0
    });
    const [results, setResults] = useState<IPagedResults<IAreaModel>>();
    const [modalMode, setModalMode] = useState<"add" | "view" | "hidden" | "scooters" | "edit">("hidden")
    const [selectedArea, setSelectedArea] = useState<IAreaModel | null>(null)
    const [deleteAreaId, setDeleteAreaId] = useState(0)
    let client = new AreasClient();
    const dispatch = useDispatch();
    const [scootersModal, setScootersModal] = useState({areaId: 0, scooterStatus: 0})
    const refresh = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.SearchAsync(search);
        if (res.succeeded) {
            setResults(res.data);
        } else {

        }
        dispatch(SetIsLoading(false))
    }
    const deleteArea = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.DeleteArea({id: deleteAreaId});
        if (res.succeeded) {
            setDeleteAreaId(0)
            refresh().then()
        } else {
            dispatch(ShowAlert(t("somethingWentWrong"), "error"));

        }
        dispatch(SetIsLoading(false))
    }


    useEffect(() => {
        refresh();
    }, [search])


    const {t} = useTranslation("areas");

    const onCloseModal = useCallback(() => {
        setModalMode("hidden")
    }, [])

    const columns = [
        {
            field: 'id',
            headerName: t('idHeader'),
            width: 100,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('idHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'nameAr',
            headerName: t('nameArHeader'),
            width: 150,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('nameArHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'nameEn',
            headerName: t('nameEnHeader'),
            width: 150,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('nameEnHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'centerLatitude',
            headerName: t('latitudeHeader'),
            width: 150,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('latitudeHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'centerLongitude',
            headerName: t('longitudeHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('longitudeHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'radius',
            headerName: t('radiusHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('radiusHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'totalNumberOfScootersInArea',
            headerName: t('totalScooters'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('totalScooters')}
                    </Typography>
                )
            },
            renderCell: (params: any) => {
                return (
                    <>
                        <Typography>
                            {params.row.totalNumberOfScootersInArea}
                        </Typography>
                        {params?.row?.totalNumberOfScootersInArea > 0 &&
                            <Typography className={classes.seeMoreText} onClick={() => {
                                setScootersModal({areaId: params.row.id, scooterStatus: ScooterStatus.Active})
                            }}>
                                {t('seeMore')}
                            </Typography>}
                    </>
                )
            },


        },
        {
            field: 'numberOfInRideScootersInArea',
            headerName: t('inRideScooters'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('inRideScooters')}
                    </Typography>
                )
            },
            renderCell: (params: any) => {
                return (
                    <>
                        <Typography>
                            {params.row.numberOfInRideScootersInArea}
                        </Typography>
                        {params?.row?.numberOfInRideScootersInArea > 0 &&
                            <Typography className={classes.seeMoreText} onClick={() => {
                                setScootersModal({areaId: params.row.id, scooterStatus: ScooterStatus.InRide})
                            }}>
                                {t('seeMore')}
                            </Typography>}
                    </>
                )
            },

        },
        {
            field: 'createdOn',
            headerName: t('createdOnHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    dayjs(params.row.createdOn).format("DD-MM-YYYY HH:mm:ss")
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('createdOnHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'actions',
            headerName: t('actionsHeader', {ns: 'scooters'}),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    <Authorized allowedRoles={[Admin.value]}>
                        <div>
                            <IconButton onClick={() => {
                                setSelectedArea(results?.matches?.find(x => x.id === params.row.id) ?? null);
                                setModalMode("edit")
                            }}> <MapOutlined/></IconButton>
                            <IconButton onClick={() => {
                                setDeleteAreaId(params.row.id)
                            }}> <DeleteIcon color={"error"}/></IconButton>
                        </div>
                    </Authorized>
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('actionsHeader', {ns: 'scooters'})}
                    </Typography>
                )
            },
        },

    ];

    return (
        <>
            {modalMode !== "hidden" && <AreaModal data={selectedArea} mode={modalMode} onClose={() => {
                onCloseModal()
                refresh().then()
            }}/>}

            {scootersModal.areaId !== 0 && <ScootersModal open={scootersModal.areaId !== 0} onClose={() => {
                setScootersModal({areaId: 0, scooterStatus: 0})
            }} areaId={scootersModal.areaId} scooterStatus={scootersModal.scooterStatus}/>}

            {deleteAreaId !== 0 &&
                <ConfirmationModal
                    onClose={() => setDeleteAreaId(0)}
                    visible={deleteAreaId !== 0}
                    onSubmit={deleteArea}
                    title={t("deleteAreaTitle")}
                    message={t("deleteAreaMessage")}
                />
            }

            <Authorized allowedRoles={[Admin.value]}>
                <Grid container justifyContent={'flex-end'}>
                    <Grid item style={{marginBottom: 10}}>
                        <Button
                            onClick={() => setModalMode("add")}
                            variant="contained" color="primary" className={classes.addUser}>
                            {t("addButton")}
                        </Button>
                    </Grid>
                </Grid>
            </Authorized>

            <Paper elevation={2} style={{marginTop: '2rem', width: '100%', height: '70vh'}}>
                <SharedTable
                    columns={columns}
                    rows={results?.matches ?? []}
                    pageSize={search?.limit}
                    onPageChange={(i) => setSearch((s) => ({...s, offset: (i) * s.limit}))}
                    onPageSizeChange={(i) => setSearch((s) => ({...s, limit: i}))}
                    rowCount={results?.total}
                    page={Math.ceil(search?.offset / search?.limit)}
                    getRowId={(i: any) => {
                        return i.id
                    }}
                />
            </Paper>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        },
        seeMoreText: {
            fontWeight: 700,
            color: theme.palette.primary.main,
            fontSize: 15,
            textDecoration: 'underline',
            cursor: 'pointer',
            marginLeft: 5
        }
    }),
);

export default Component;
