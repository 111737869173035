import React from 'react';
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import {Redirect, Route, Switch} from "react-router";
import Login from "../Views/Auth/Login";
import {useSelector} from "react-redux";
import IAppStateModel from "../../Types/AppState";
import {useLocation} from "react-router-dom";
import CommonLayout from "./CommonLayout";

interface IProps {

}

const Layout = (props: IProps & any) => {
    const profile = useSelector((state: IAppStateModel) => state.profile);
    const location = useLocation();

    return (
        <>
            {profile?.id && <Redirect to={`${location.search.slice(11)}`}/>}
                <Switch>
                    <Route path="/">
                        <Login />
                    </Route>
                </Switch>
        </>
    )
}

export default Layout;
