import React, {useMemo} from "react";
import {MenuItem, Select, Grid, Typography, InputLabel, FormControl} from "@material-ui/core";

export type DropDownItem = {
    value: string | undefined | number | boolean;
    label: string | undefined
}

interface IProps {
    options: Array<DropDownItem>
    label: string
    value: string | undefined | boolean | number;
    onChange: (value: any) => void
    error?: string | any
    placeholder?: string
    required?: boolean
    disableClearable?: boolean;
}

const SharedDropdown = (props: IProps) => {
    const {options, label, value, onChange, error, placeholder, required = false, disableClearable = false} = props;

    let selectedOption = useMemo(() => {
        return options?.find((option) => option?.value === value);
    }, [options, value]);
    return (
        <Grid
            item
            container
            xs={12}>
            <Grid
                item
                xs={12}>
                <FormControl style={{minWidth: '100%'}}>
                <InputLabel style={{marginLeft: 10}}>{label}</InputLabel>
                <Select
                    variant={"outlined"}
                    style={{
                        width: "100%",
                    }}
                    fullWidth
                    value={value}
                    onChange={onChange}
                >
                    {options?.map(m => <MenuItem value={m.value as any}>{m.label}</MenuItem>)}
                </Select>
                </FormControl>
            </Grid>
            <Grid
                item
                xs={12}>
                <Typography
                    variant={"h6"}
                >
                    {error}
                </Typography>
            </Grid>
        </Grid>
    );
};
export default SharedDropdown;
