import React, {FunctionComponent, useEffect, useState} from "react";
import {Modal, Paper, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import AreasClient from "Api/AreasClient";
import IPagedResults, {IPagedSearch} from "Types/Paged";
import {GetScootersByAreaModel} from "Types/Areas";
import SharedTable from "Components/Shared/SharedTable";
import {IScooterModel} from "Types/Scooters";
import {SetIsLoading, ShowAlert} from "State/Actions/UiActions";
import {useDispatch} from "react-redux";
import {BatteryIcon} from "Components/Views/IotDevices/IotDevices";

interface IProps {
    open: boolean
    onClose: () => void;
    areaId: number;
    scooterStatus: number;
}

const ScootersModal: FunctionComponent<IProps> = ({open, onClose, areaId, scooterStatus}) => {
    const classes = useStyles();
    const client = new AreasClient();
    const [results, setResults] = useState<IPagedResults<IScooterModel>>();
    const dispatch = useDispatch()
    const [search, setSearch] = useState<IPagedSearch<GetScootersByAreaModel>>({
        limit: 25,
        offset: 0,
        search: {
            areaId: areaId,
            scooterStatus: scooterStatus
        }
    });

    const {t} = useTranslation("scooters")
    const refresh = async () => {
        dispatch(SetIsLoading(true))
        const response = await client.GetScootersByArea(search)
        if (response.succeeded) {
            setResults(response.data)
        } else {
            dispatch(ShowAlert(t("Something went wrong"), "error"));
        }
        dispatch(SetIsLoading(false))
    }

    useEffect(() => {
        refresh().then()
    }, [])


    const columns = [
        {field: 'id', headerName: 'ID', width: 90},
        {
            field: 'id',
            headerName: t('idHeader'),
            width: 150,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('idHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'code',
            headerName: t('codeHeader'),
            width: 150,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('codeHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'imei',
            headerName: t('imeiHeader'),
            width: 200,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('imeiHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'model',
            headerName: t('modelHeader'),
            width: 150,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('modelHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'status',
            headerName: t('status'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('status')}
                    </Typography>
                )
            },

        },
        {
            field: 'condition',
            headerName: t('scooterChargeHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    <><BatteryIcon
                        charge={params.row.condition.scooterCharge}/>{params.row.condition.scooterCharge}%</>)
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('scooterChargeHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'rate',
            headerName: t('perMinute'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    params.row.rate.perMinute
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('perMinute')}
                    </Typography>
                )
            },

        },
        {
            field: 'rate',
            headerName: t('initialCharge'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    params.row?.rate?.initialCharge
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('initialCharge')}
                    </Typography>
                )
            },

        },
        {
            field: 'isLowBattery',
            headerName: t('isLowBattery'),
            width: 100,
            sortable: false,
            disableColumnMenu: true,

            renderCell: (params: any) => {
                return (
                    params.row.isLowBattery ? t("low") : t("high")
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('isLowBattery')}
                    </Typography>
                )
            },

        },
        {
            field: 'iotCharge',
            headerName: t('iotChargeHeader'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    params.row?.condition?.iotCharge
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('iotChargeHeader')}
                    </Typography>
                )
            },

        },
    ];

    return (
        <div className={classes.main}>
            <Modal open={open} className={classes.modal} onClose={onClose}>
                <Paper elevation={2} style={{marginTop: '2rem', width: '80%', height: '45vh'}}>
                    <SharedTable
                        columns={columns}
                        rows={results?.matches ?? []}
                        pageSize={search?.limit}
                        onPageChange={(i) => setSearch((s) => ({...s, offset: (i) * s.limit}))}
                        onPageSizeChange={(i) => setSearch((s) => ({...s, limit: i}))}
                        rowCount={results?.total}
                        page={Math.ceil(search?.offset / search?.limit)}
                        getRowId={(i: any) => {
                            return i.id
                        }}
                    />
                </Paper>
            </Modal>
        </div>
    )
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: "scroll",
            padding: "3%",
            alignSelf: "center",
        },
        main: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 21
        },
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        }
    }),
);
export default ScootersModal;
