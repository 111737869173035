import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookiesManager from "../Utils/CookiesManager";
import {IPagedSearch} from "../Types/Paged";

class PaymentsInfoClient {
    client: IClient;
    locale: string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }


    async SearchAsync(
        search: IPagedSearch<any>
    ): Promise<ApiResponse> {
        let query = `PaymentInformation?limit=${search.limit}&offset=${search.offset}`
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }
}

export default PaymentsInfoClient;
