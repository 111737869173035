import React, {useEffect, useState} from 'react';
import {FormControl, InputLabel, MenuItem, Modal, Select, TextField} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {ICreateScooter} from "../../../../Types/Scooters";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import ScootersClient from "../../../../Api/ScootersClient";
import Typography from "@material-ui/core/Typography";
import {ILookupModel} from "../../../../Types/Lookup";
import AreasClient from "../../../../Api/AreasClient";


interface IProps {
    onClose: () => void;
}

const Component = (props: IProps) => {
    const classes = useStyles();

    const [info, setInfo] = useState<ICreateScooter>();
    const [errorMsg, setErrorMsg] = useState('');

    const client = new ScootersClient();
    const areaClient = new AreasClient();

    const onSubmit = async () => {
        if (info) {
            let res = await client.AddAsync(info!);
            if (res.succeeded) {
                props.onClose()
            } else {
                setErrorMsg(t('failedError'))
            }
        } else {
            setErrorMsg(t('requiredFieldsError'))
        }

    }
    const [manufacturers, setManufacturers] = useState<ILookupModel[]>([])
    const [areas, setAreas] = useState<ILookupModel[]>([])

    const getManufacturers = async () => {
        let res = await client.GetManufacturersLookup();
        if (res.succeeded) {
            setManufacturers(res.data);
        }
    }
    const getAreas = async () => {
        let res = await areaClient.GetLookupsAsync();
        if (res.succeeded) {
            setAreas(res.data);
        }
    }
    useEffect(() => {
        getManufacturers();
        getAreas();
    }, [])
    const {t} = useTranslation("scooters")
    return (
        <Modal
            open={true}
            onClose={props.onClose}
            className={classes.modal}
        >
            <div className={classes.paper}>
                <h2 id="simple-modal-title">{t("addTitle")}</h2>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label={t("codeHeader")}
                            fullWidth
                            value={info?.code}
                            onChange={(e) => setInfo({
                                ...info,
                                code: e.target.value
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label={t("imeiHeader")}
                            fullWidth
                            value={info?.imei}
                            onChange={(e) => setInfo({
                                ...info,
                                imei: e.target.value
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl style={{minWidth: '100%',}}>
                            <InputLabel>{t("manufacturerHeader")}</InputLabel>
                            <Select
                                fullWidth
                                value={info?.manufacturer}
                                onChange={(e) => setInfo({
                                    ...info,
                                    manufacturer: e.target.value as any
                                })}
                            >
                                {manufacturers.map(m => <MenuItem value={m.value}>{m.label}</MenuItem>)}
                            </Select>

                        </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl style={{minWidth: '100%',}}>
                            <InputLabel>{t("areaHeader")}</InputLabel>
                            <Select
                                fullWidth
                                value={info?.areaId}
                                onChange={(e) => setInfo({
                                    ...info,
                                    areaId: e.target.value as any
                                })}
                            >
                                {areas?.map(m => <MenuItem value={m.value}>{m.label}</MenuItem>)}
                            </Select>

                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color={'error'}>
                            {errorMsg}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                        >
                            {t("saveButton")}
                        </Button>
                    </Grid>
                </Grid>

            </div>

        </Modal>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,

            padding: theme.spacing(2, 4, 3),
        },
    }),
);

export default Component;
