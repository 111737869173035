import React, {useEffect, useState} from 'react';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import AnalyticsClient from "Api/AnalyticsClient";
import {SetIsLoading} from "State/Actions/UiActions";
import {useDispatch} from "react-redux";
import {unCamelCase} from "Utils/general";
import Grid from "@material-ui/core/Grid";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {RiFileExcel2Fill} from "react-icons/all";
import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core/styles";

ChartJS.register(ArcElement, Tooltip, Legend);

const ScooterByStatus = () => {
    const client = new AnalyticsClient()
    const dispatch = useDispatch();
    const classes = useStyles()
    const [results, setResults] = useState<any>([]);

    const data = {
        labels: Object.keys(results).map(x => unCamelCase(x)) ?? [],
        datasets: [
            {
                label: '# of Votes',
                data: Object.values(results) ?? [],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const refresh = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.GetScooterStatuses();
        if (res.succeeded) {
            setResults(res.data);
        } else {

        }
        dispatch(SetIsLoading(false))
    }

    const exportToExcel = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.GetScooterStatuses(true);
        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer")
        } else {

        }
        dispatch(SetIsLoading(false))
    }


    useEffect(() => {
        refresh().then();
    }, [])
    return (
        <Grid container xs={12}>
            <Box className={classes.container}>
                <RiFileExcel2Fill onClick={exportToExcel} className={classes.excelIcon} size={30}/>
                <Typography style={{fontSize: '24px', fontWeight: '600', paddingTop: '1rem'}}>Scooter By Status</Typography>
                <Doughnut data={data}/>
            </Box>
        </Grid>
    );
}

export default ScooterByStatus;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: 'white',
            width: '100%',
            height: '100%',
            borderRadius: '20px',
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            // maxHeight: 392,
            position: 'relative'
        },
        excelIcon: {
            color: theme.palette.primary.main,
            marginTop: 5,
            cursor: 'pointer',
            position: 'absolute',
            right: 20,
            top: 20
        },
    }),
);

