import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookiesManager from "../Utils/CookiesManager";
import {IPagedSearch} from "../Types/Paged";
import {GetScootersByAreaModel, ICreateAreaModel, IDeleteAreaModel} from "../Types/Areas";

class AreasClient {
    client: IClient;
    locale:string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }


    async SearchAsync(
        search: IPagedSearch<any>
    ): Promise<ApiResponse> {
        let query = `areas?limit=${search.limit}&offset=${search.offset}`
        if (search?.search?.lookup) {
            query += `&lookup=${search?.search?.lookup}`
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }
    async GetLookupsAsync(
    ): Promise<ApiResponse> {
        let query = `areas?lookup=true`
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async AddAsync(
        info: ICreateAreaModel
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync('areas',info);
        return rs;
    }

    async UpdateAreaModel(
        info: ICreateAreaModel
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync('areas/update',info);
        return rs;
    }


    async DeleteArea(
        info: IDeleteAreaModel
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync('areas/delete', info);
        return rs;
    }

    async GetScootersByArea(
        search: IPagedSearch<GetScootersByAreaModel>
    ): Promise<ApiResponse> {
        let query = `areas/GetScootersByArea?limit=${search.limit}&offset=${search.offset}`
        if (search?.search?.areaId) {
            query += `&areaId=${search?.search?.areaId}`
        }
        if (search?.search?.scooterStatus) {
            query += `&scooterStatus=${search?.search?.scooterStatus}`
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

}

export default AreasClient;
