import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import CookiesManager from "../Utils/CookiesManager";
import {ICreatePackages} from "../Types/Packages";

class PackagesClient {
    client: IClient;
    locale: string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }

    async SearchAsync(
        isDeactivated?: boolean
    ): Promise<ApiResponse> {
        let query = `packages/SearchAdminPackages`
        if(isDeactivated?.toString())
            query += `?isDeactivated=${isDeactivated}`
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }
    async CreateAsync(
        data: ICreatePackages
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync('packages', data);
        return rs;
    }

    async Deactivate(
        id: number
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync('packages/deactivate', {id});
        return rs;
    }
    async Activate(
        id: number
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync('packages/activate', {id});
        return rs;
    }


}

export default PackagesClient;
