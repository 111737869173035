import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookiesManager from "../Utils/CookiesManager";
import {IPagedSearch} from "Types/Paged";
import {
    ICreateScooter,
    IMaintenanceLogModel,
    IScootersSearchModel,
    IUpdateScooterModel,
    ScooterConfig
} from "Types/Scooters";
import {ILogsSearchModel} from "Types/Logs";

class ScootersClient {
    client: IClient;
    locale: string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }


    async SearchAsync(
        search: IPagedSearch<IScootersSearchModel>
    ): Promise<ApiResponse> {
        let query = `scooters?limit=${search.limit}&offset=${search.offset}`
        if (search.search?.code) {
            query += `&code=${search.search?.code}`
        }
        if (search.search?.imei) {
            query += `&imei=${search.search?.imei}`
        }
        if (search.search?.statusId) {
            query += `&statusId=${search.search?.statusId}`
        }
        if (search.search?.manufacturerId) {
            query += `&manufacturerId=${search.search?.manufacturerId}`
        }
        if (search.search?.isLowBattery) {
            query += `&isLowBattery=${search.search?.isLowBattery}`
        }
        if (search.search?.areaId) {
            query += `&areaId=${search.search?.areaId}`
        }
        if (search.search?.id) {
            query += `&id=${search.search?.id}`
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async SearchConfigAsync(): Promise<ApiResponse> {
        let query = `scooterConfig`
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async AddAsync(
        model: ICreateScooter
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync("scooters", model);
        return rs;
    }

    async AddScooterConfigAsync(
        config: ScooterConfig
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync(`scooterConfig`, config);
        return rs;
    }

    async UpdateAsync(
        id: string,
        model: IUpdateScooterModel
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync(`scooters/${id}`, model);
        return rs;
    }

    async GetManufacturersLookup(): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyGetAsync("scooters/manufacturers");
        return rs;
    }

    async GetLogs(search: IPagedSearch<ILogsSearchModel>): Promise<ApiResponse> {
        let query = `scooters/logs?limit=${search.limit}&offset=${search.offset}`
        if (search.search?.scooterId) query += `&scooterId=${search.search?.scooterId}`
        if (search.search?.from) {
            query += '&from=' + search.search.from
        }
        if (search.search?.to) {
            query += '&to=' + search.search.to
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async ExportLogs(search: IPagedSearch<ILogsSearchModel>): Promise<ApiResponse> {
        let query = `scooters/exportLogs?limit=${search.limit}&offset=${search.offset}`
        if (search.search?.scooterId) query += `&scooterId=${search.search?.scooterId}`
        if (search.search?.from) {
            query += '&from=' + search.search.from
        }
        if (search.search?.to) {
            query += '&to=' + search.search.to
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async ExportOtherLogs(search: IPagedSearch<ILogsSearchModel>): Promise<ApiResponse> {
        let query = `scooters/exportOtherLogs?limit=${search.limit}&offset=${search.offset}`
        if (search.search?.scooterId) query += `&scooterId=${search.search?.scooterId}`
        if (search.search?.from) {
            query += '&from=' + search.search.from
        }
        if (search.search?.to) {
            query += '&to=' + search.search.to
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }



    async GetHistory(search: IPagedSearch<{scooterIdOrCode: number | string}>): Promise<ApiResponse> {
        let query = `scooters/GetWithRideHistory?limit=${search.limit}&offset=${search.offset}&scooterId=${search.search?.scooterIdOrCode}`
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async CommandScooter(scooterId: string, command: 'lightOn' | 'lightOff' | 'buzzerOn' | 'buzzerOff' | 'alarmOn' | 'alarmOff' | 'updateStatus' | "start" | "stop"): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync(`scooters/${scooterId}/command`, {
            command
        });
        return rs;
    }

    async ExportToExcelAsync(
        search: IPagedSearch<any>
    ): Promise<ApiResponse> {
        let query = `scooters/export?limit=${search.limit}&offset=${search.offset}`
        if (search.search?.code) {
            query += `&code=${search.search?.code}`
        }
        if (search.search?.imei) {
            query += `&imei=${search.search?.imei}`
        }
        if (search.search?.statusId) {
            query += `&statusId=${search.search?.statusId}`
        }
        if (search.search?.manufacturerId) {
            query += `&manufacturerId=${search.search?.manufacturerId}`
        }
        if (search.search?.isLowBattery) {
            query += `&isLowBattery=${search.search?.isLowBattery}`
        }
        if (search.search?.areaId) {
            query += `&areaId=${search.search?.areaId}`
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async ChangeStatus(id: number, obj: {status: number, reasonId?: number, note: string}): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync(`scooters/${id}/changeStatus`, obj);
        return rs;
    }

    async GetOtherLogs(search: IPagedSearch<ILogsSearchModel>): Promise<ApiResponse> {
        let query = `scooters/OtherLogs?limit=${search.limit}&offset=${search.offset}`
        if (search.search?.from) {
            query += '&from=' + search.search.from
        }
        if (search.search?.to) {
            query += '&to=' + search.search.to
        }
        if (search.search?.code) {
            query += '&code=' + search.search.code
        }
        if (search.search?.imei) {
            query += '&imei=' + search.search.imei
        }
        if (search.search?.scooterId) query += `&scooterId=${search.search?.scooterId}`
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async ExportIOTDeviceHistory (search: IPagedSearch<{scooterIdOrCode: number | string}>): Promise<ApiResponse> {
        let query = `scooters/ExportRideHistory?scooterId=${search.search?.scooterIdOrCode}&&limit=${search.limit}&offset=${search.offset}`
        
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }
}

export default ScootersClient;
