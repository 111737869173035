import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import IAppStateModel from "../../Types/AppState";
import {useLocation} from "react-router-dom";
import CookiesManager from "../../Utils/CookiesManager";
import Loading from "../Shared/Loading";
import {SetProfile} from "../../State/Actions/ProfileActions";
import Home from '../Views/Home/Home';
import CommonLayout from "./CommonLayout";
import Rides from "../Views/Rides/Rides";
import Users from "../Views/Users/Users";
import IotDevices from "../Views/IotDevices/IotDevices";
import Logs from "../Views/Logs/Logs";
import Staff from "../Views/Staff/Staff";
import Areas from "../Views/Areas/Areas";
import Notifications from "../Views/Notifications/Notifications";
import Payments from "../Views/Payments/Payments";
import PromoCodes from "../Views/PromoCodes/PromoCodes";
import Packages from "../Views/Packages/Packages";
import PaymentInfo from "../Views/PaymentInfo/PaymentInfo";
import OtherLogs from "../Views/OtherLogs";
import StaffNotifications from "Components/Views/StaffNotifications";
import Analytics from "Components/Views/Analytics";
import MobilePopups from "Components/Views/MobilePopups";
import Vouchers from "Components/Views/Vouchers/Vouchers";
import Subsicriptions from 'Components/Views/subscriptions';


interface IProps {

}

const Layout = (props: IProps & any) => {
    const {profileId, isLoading} = useSelector((state: IAppStateModel) => {
        return {
            profileId: state.profile?.id,
            isLoading: state.isLoading
        }
    });

    const location = useLocation();
    const [authenticated, setAuthenticated] = useState(!!CookiesManager.getAccessToken());
    const dispatch = useDispatch();

    useEffect(() => {

        if (!profileId) {
            const jwt = CookiesManager.getAccessToken();
            if (jwt) {
                dispatch(SetProfile(jwt));
                setAuthenticated(true);
            } else setAuthenticated(false);
        } else {
            setAuthenticated(true)
        }
    }, [profileId])


    return (
        <>
            {!authenticated && <Redirect to={`/login?returnurl=${location.pathname}`}/>}
            {authenticated && location.pathname == "/" && <Redirect to={`/`}/>}
            {isLoading && <Loading/>}

            <CommonLayout>
                <Switch>

                    <Route path={"/rides"}>
                        <Rides/>
                    </Route>
                    <Route path={"/users"}>
                        <Users/>
                    </Route>
                    <Route path={"/Payments"}>
                        <Payments/>
                    </Route>
                    <Route path={"/PromoCodes"}>
                        <PromoCodes/>
                    </Route>
                    <Route path={"/Packages"}>
                        <Packages/>
                    </Route>
                    <Route path={"/iot"}>
                        <IotDevices/>
                    </Route>
                    <Route path={"/areas"}>
                        <Areas/>
                    </Route>
                    <Route path={"/iotLogs"}>
                        <Logs/>
                    </Route>
                    <Route path={"/otherLogs"}>
                        <OtherLogs/>
                    </Route>
                    {/*<Route path={"/mobiles"}>*/}
                    {/*    <MobileDevices/>*/}
                    {/*</Route>*/}
                    <Route path={"/staff"}>
                        <Staff/>
                    </Route>
                    <Route path={"/paymentsInfo"}>
                        <PaymentInfo/>
                    </Route>
                    <Route exact path={"/notifications"}>
                        <Notifications/>
                    </Route>
                    <Route exact path={"/staffNotifications"}>
                        <StaffNotifications/>
                    </Route>
                    <Route exact path={"/analytics"}>
                        <Analytics/>
                    </Route>
                    <Route exact path={"/mobilePopups"}>
                        <MobilePopups/>
                    </Route>
                    <Route exact path={"/vouchers"}>
                        <Vouchers/>
                    </Route>
                    <Route exact path={"/"}>
                        <Home/>
                    </Route>
                    <Route path={"/subsicriptions"}>
                        <Subsicriptions />
                    </Route>
                </Switch>
            </CommonLayout>
        </>
    )
}

export default Layout;
