import {SortType} from "Types/Users";

export interface IRide {
    id: any
    scooterStartedOn?: Date
    scooterStoppedOn?: Date
    Area: string;
    packagesIds?: number[] | null;
}

export interface IRideSearch {
    groupRideId?: string | null;
    id?: number | null;
    status?: string;
    user?: string;
    from?: Date | null;
    to?: Date | null;
    userId?: number;
    sortColumn?: {
        sortType: SortType,
        columnName: ColumnName
    } | null;
    userName?: string;
}

export enum ColumnName {
    Failed = 1,
    Started = 2,
    Canceled = 3,
    Stopped = 4,
    Created = 5,
    Finished = 6,
    Charge = 7
}

export interface IRideLogsSearchModel {
    scooterStartedOn?: Date
    scooterStoppedOn?: Date
}


export type IRideStatus = "Started" | "Failed" | "Ended" | "Finished" | "Canceled"
