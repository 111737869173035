import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Modal, Paper, TextField} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {IAreaModel, ICreateAreaModel} from "Types/Areas";
import AreasClient from "Api/AreasClient";
import GoogleMapReact from "google-map-react";
import Config from "config";
import ScootersClient from "Api/ScootersClient";
import {IScooterModel} from "Types/Scooters";
import ScooterMarker from "Components/Shared/ScooterMarker";
import {ShowAlert} from "State/Actions/UiActions";
import {useDispatch} from "react-redux";


interface IProps {
    onClose: () => void;
    mode:  "view" | "add" | "hidden" | "scooters" | "edit"
    data: IAreaModel | null
}

const Component = (props: IProps) => {
    const classes = useStyles();
    const [results, setResults] = useState<IScooterModel[]>([]);
    const dispatch = useDispatch()
    const [info, setInfo] = useState<ICreateAreaModel | undefined>({
        id: props.data?.id as number,
        centerLatitude: props.data?.centerLatitude ?? 24.5247,
        centerLongitude: props.data?.centerLongitude ?? 39.5692,
        radius: props.data?.radius ?? 1000,
        nameAr: props.data?.nameAr ?? "",
        nameEn: props.data?.nameEn ?? "",
    });
    const [errorMsg, setErrorMsg] = useState('');
    const ref = useRef<any>()
    const client = new AreasClient();
    const {t} = useTranslation("areas")

    const onSubmit = useCallback(async () => {
        if (info) {
            let res = await client.AddAsync(info!);
            if (res.succeeded) {
                props.onClose()
            } else {
                setErrorMsg(t('failedError'))
            }
        } else {
            setErrorMsg(t('requiredFieldsError'))
        }

    }, [info])

    const editArea = async () => {
        if (info) {
            let res = await client.UpdateAreaModel(info!);
            if (res.succeeded) {
                dispatch(ShowAlert(t("The area was updated successfully"), "success"));
                props.onClose()
            } else {
                dispatch(ShowAlert(t("Something went wrong"), "error"));
            }
        }
    }


    useEffect(() => {
        (async () => {
            const scootersClient = new ScootersClient();
            const resS = await scootersClient.SearchAsync({limit: 300, offset: 0});
            if (resS.succeeded) {
                setResults(resS.data.matches)
            }
        })()
    }, [])


    const apiIsLoaded = (google: any) => {
        let c = new google.maps.Circle({
            strokeColor: '#0353A4',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#0353A4',
            fillOpacity: 0.3,
            editable: true,
            draggable: true,
            radius_changed: () => handleCircleChange(),
            center_changed: () => handleCircleChange(),
            google,
            center: {
                lat: info?.centerLatitude,
                lng: info?.centerLongitude
            },
            radius: info?.radius,
        });
        ref.current = c
        c.setMap(google.map)
    };

    const handleCircleChange = () => {
        if ((props.mode === "add" || props.mode === "edit") && ref.current) {
            const lat = ref.current.getCenter().lat();
            const lng = ref.current.getCenter().lng();
            const radius = ref.current.getRadius()
            setInfo((f) => ({
                ...f,
                centerLongitude: lng,
                centerLatitude: lat,
                radius: radius
            }))
        }

    }
    return (
        <Modal
            open={true}
            onClose={props.onClose}
            className={classes.modal}
        >
            <div className={classes.paper}>

                <h2 id="simple-modal-title">{t("")}</h2>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            variant={"outlined"}

                            label={t("nameArHeader")}
                            fullWidth
                            value={info?.nameAr}
                            onChange={(e) => {
                                setInfo({
                                    ...info,
                                    nameAr: e.target.value
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant={"outlined"}
                            required
                            placeholder={t("nameEnHeader")}
                            label={t("nameEnHeader")}
                            fullWidth
                            value={info?.nameEn}
                            onChange={(e) => setInfo({
                                ...info,
                                nameEn: e.target.value
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box display={"flex"} px={10} justifyContent={"space-between"}>
                            <Box border={1} p={2} borderRadius={13} borderColor={"#777777"} minWidth={280}>
                                <Typography>{t("radiusHeader")}{':  '}{info?.radius}</Typography>
                            </Box>
                            <Box border={1} p={2} borderRadius={13} borderColor={"#777777"} minWidth={280}>
                                <Typography>{t("latitudeHeader")}{':  '}{info?.centerLatitude}</Typography>
                            </Box>
                            <Box border={1} p={2} borderRadius={13} borderColor={"#777777"} minWidth={280}>
                                <Typography>{t("longitudeHeader")}{':  '}{info?.centerLongitude}</Typography>
                            </Box>
                        </Box>

                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Paper>
                            <Box width={"100%"} alignSelf={"center"}
                                 height={420}>
                                <GoogleMapReact
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={(google) => apiIsLoaded(google)}
                                    defaultZoom={13}
                                    defaultCenter={{
                                        lat: info?.centerLatitude!,
                                        lng: info?.centerLongitude!
                                    }}

                                    bootstrapURLKeys={{
                                        key: Config.googleMapsApiKey,
                                        libraries: ['geometry', 'drawing', 'visualization']
                                    }}
                                >
                                    {results && results.length > 0 && results?.map(r => <ScooterMarker
                                        info={r}
                                        key={`${r.code}_code`}
                                        lng={r.location?.longitude}
                                        lat={r.location?.latitude}
                                    />)}

                                </GoogleMapReact>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color={'error'}>
                            {errorMsg}
                        </Typography>
                    </Grid>
                    {
                        props.mode === "add" && <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={onSubmit}
                            >
                                {t("addArea")}
                            </Button>
                        </Grid>
                    }
                    {
                        props.mode === "edit" && <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={editArea}
                            >
                                {t("editArea")}
                            </Button>
                        </Grid>
                    }

                </Grid>
            </div>
        </Modal>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 1200
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            minWidth: 1200,

            padding: theme.spacing(2, 4, 3),
        },
    }),
);

export default Component;
