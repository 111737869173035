import React, {useEffect, useState} from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import {CreatePackageInitialValuesType, packageTypeOptions} from 'Types/Packages';
import SharedDropdown from 'Components/Shared/Dropdown';
import {SetIsLoading, ShowAlert} from "State/Actions/UiActions";
import AreasClient from "Api/AreasClient";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            width: 500,
            height: 300
        },
        button: {
            color: 'white'
        }
    }),
);

interface IProps {
    onClose: () => void
    open: boolean;
    packagesValues: CreatePackageInitialValuesType;
    setPackageValues: (e: CreatePackageInitialValuesType) => void;
    onSubmit: () => Promise<void>;
    modalType?: 'ADD' | 'EDIT';
}

const CreatePackageModal = ({onClose, open, packagesValues, setPackageValues, onSubmit, modalType}: IProps) => {
    const classes = useStyles();
    const {t} = useTranslation("packages")
    let areaClient = new AreasClient();
    const dispatch = useDispatch();
    const [areas, setAreas] = useState<Array<{label: string, value: string}>>([]);
    const getAreas = async () => {
        dispatch(SetIsLoading(true))
        let res = await areaClient.SearchAsync({search: {lookup: true}, limit: 0, offset: 0});
        if (res.succeeded) {
            setAreas(res.data);
        } else {
            dispatch(ShowAlert(t("Could not fetch areas"), "error"));
        }
        dispatch(SetIsLoading(false))
    }

    useEffect(() => {
        getAreas().then()
    }, [])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">{modalType === 'ADD' ? t('createPackage') : t('updatePackage')}</h2>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    type={"number"}
                                    variant={"outlined"}
                                    label={t("balanceValue")}
                                    fullWidth
                                    value={packagesValues.balanceValue}
                                    onChange={(e) => {
                                        setPackageValues({
                                            ...packagesValues,
                                            balanceValue: Number(e.target.value)
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    type={"number"}
                                    variant={"outlined"}
                                    label={t("price")}
                                    fullWidth
                                    value={packagesValues.price}
                                    onChange={(e) => {
                                        setPackageValues({
                                            ...packagesValues,
                                            price: Number(e.target.value)
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item container xs={6}>
                                <SharedDropdown
                                    options={packageTypeOptions}
                                    label={t('type')}
                                    value={packagesValues?.type}
                                    onChange={(e: any) => {
                                        setPackageValues({
                                            ...packagesValues,
                                            type: e.target.value,
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item container xs={6}>
                                <SharedDropdown
                                    options={areas ?? []}
                                    label={t('area')}
                                    value={packagesValues.areaId ?? undefined}
                                    onChange={(e: any) => {
                                        setPackageValues({
                                            ...packagesValues,
                                            areaId: Number(e.target.value),
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid container item justifyContent={'flex-end'}>
                                <Button className={classes.button} onClick={onSubmit} variant="contained" color="primary">
                                    {t("submit")}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default CreatePackageModal;
