import React from "react";
import {FormControl, InputLabel, Typography, OutlinedInput} from "@material-ui/core";

interface TextInputProps {
    value: string | number
    onChange: (value:string, name?:any) => void
    focused?: boolean
    placeholder?: string
    disabled?: boolean
    size?: 'small' | 'regular'
    required?: boolean
    isShrink?: boolean
    className?: string
    label: string
    name?: string
    error?: string
    type?: string;
}

const SharedTextInput = (props: TextInputProps) => {
    const {
        value,
        onChange,
        label,
        name,
        error,
        placeholder,
        disabled = false,
        required,
        isShrink = false,
        className,
        type
    } = props;

    return (
        <FormControl
            className={className}
            style={{ width: "100%" }}
            variant="outlined"
        >
            {label !== "" && (
                <InputLabel
                    {...(isShrink
                        ? {
                            shrink: true,
                        }
                        : {})}
                    required={required ?? false}
                    htmlFor="outlined"
                >
                    {`${label.charAt(0).toUpperCase() + label.slice(1)}`}
                </InputLabel>
            )}
            <OutlinedInput
                type={type ?? 'text'}
                id="outlined"
                style={{ borderRadius: "10px" }}
                name={name}
                value={value}
                onChange={(e) => {
                    onChange(e.target.value, name);
                }}
                label={`${label.charAt(0).toUpperCase() + label.slice(1)}${
                    required ? "*" : ""
                }`}
                placeholder={placeholder}
                disabled={disabled}
                {...(isShrink
                    ? {
                        notched: true,
                    }
                    : {})}
            />
            <Typography>
                {error}
            </Typography>
        </FormControl>
    );
};

export default SharedTextInput;
