import React from 'react'
import {DataGrid, GridColumns, GridFeatureMode, GridRowData, GridRowIdGetter} from "@material-ui/data-grid";

interface IProps {
    columns:  GridColumns;
    rows: GridRowData[];
    pageSize?: number;
    paginationMode?:  GridFeatureMode | undefined
    page?: number;
    rowCount?: number;
    onPageChange?: (e: number) => void;
    onPageSizeChange?: (e: number) => void;
    getRowId?: GridRowIdGetter;
    rowsPerPageOptions?: number[];
}

const SharedTable = ({columns, page, onPageChange, onPageSizeChange, pageSize, rows, rowCount, paginationMode = "server", getRowId, rowsPerPageOptions}: IProps) => {
    return (
        <DataGrid
            columns={columns}
            rows={rows}
            loading={false}
            getRowId={getRowId}
            paginationMode={paginationMode}
            pageSize={pageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            rowCount={rowCount}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
        />

    )
}

export default SharedTable