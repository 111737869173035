import React from 'react'
import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import {useTranslation} from "react-i18next";
import {IPagedSearch} from "Types/Paged";
import {Paper, Typography} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {RiFileExcel2Fill} from "react-icons/all";
import SharedTextInput from "Components/Shared/TextInput";
import SharedButton from "Components/Shared/Button";
import {useDispatch} from "react-redux";
import {NotificationSearch} from "Types/Notifications";
import SharedDropdown from "Components/Shared/Dropdown";
import {SetIsLoading} from "State/Actions/UiActions";
import StaffClient from "Api/StaffClient";
import SharedDatePicker from "Components/Shared/DatePicker/datePicker";

interface IProps {
    search: IPagedSearch<NotificationSearch>;
    setSearch: (e: any) => void;
    onRefresh: () => Promise<void>
    onClear: () => void;
    isExportHidden?: boolean;
    onMarkAllAsRead: () => void;
}

const NotificationsSearch = ({
                                 search,
                                 setSearch,
                                 onRefresh,
                                 onClear,
                                 isExportHidden = true,
                                 onMarkAllAsRead
                             }: IProps) => {
    const classes = useStyles();
    const {t} = useTranslation('scooters')
    const dispatch = useDispatch()
    const client = new StaffClient()
    const onExport = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.ExportToExcelAsync(search);
        dispatch(SetIsLoading(false))
        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer")
        }
    }
    const codes = [
        {label: 'ScooterHasNoRide', value: 6},
        {label: 'ScooterHasNoLog', value: 7},
    ]
    return (
        <>
            <Paper className={classes.searchBox}>
                <Grid className={classes.searchBoxHeader} container item justifyContent={'space-between'}>
                    <Grid className={classes.searchBoxHeader} item>
                        <Typography className={classes.searchText}>
                            {t('Search')}
                        </Typography>
                        <SearchIcon className={classes.searchIcon} fontSize={'large'}/>
                    </Grid>
                    {!isExportHidden && <Grid item style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <RiFileExcel2Fill onClick={onExport} className={classes.excelIcon} size={30}/>
                    </Grid>}
                </Grid>
                <Grid container item justifyContent={'flex-start'}>
                    <Grid item md={2} xs={12} style={{margin: '1rem'}}>
                        <SharedTextInput
                            value={search.search?.imei ?? ''}
                            onChange={(e: any) => setSearch({
                                ...search,
                                search: {
                                    ...search.search,
                                    imei: e,
                                }
                            })}
                            label={t('imeiHeader')}
                        />
                    </Grid>
                    <Grid item md={2} xs={12} style={{margin: '1rem'}}>
                        <SharedDropdown
                            options={codes}
                            label={t('codeHeader')}
                            value={search.search?.code}
                            onChange={(e: any) => {
                                setSearch({
                                    ...search,
                                    search: {
                                        ...search.search,
                                        code: Number(e.target.value),
                                    }
                                })
                            }}
                        />
                    </Grid>
                    <Grid item md={2} xs={12} style={{margin: '1rem'}}>
                        <SharedTextInput
                            value={search.search?.scooterCode ?? ''}
                            onChange={(e: any) => setSearch({
                                ...search,
                                search: {
                                    ...search.search,
                                    scooterCode: e,
                                }
                            })}
                            label={t('Scooter Code')}
                        />
                    </Grid>
                    <Grid item md={2} xs={12} style={{margin: '1rem'}}>
                        <SharedDatePicker
                            value={search.search?.from}
                            handleChange={(e: any) => setSearch({
                                ...search,
                                search: {
                                    ...search.search,
                                    from: e,
                                }
                            })}
                            label="Created From"
                        />
                    </Grid>
                    <Grid item md={2} xs={12} style={{margin: '1rem'}}>
                        <SharedDatePicker
                            value={search.search?.to}
                            handleChange={(e: any) => setSearch({
                                ...search,
                                search: {
                                    ...search.search,
                                    to: e,
                                }
                            })}
                            label="Created To"
                        />
                    </Grid>

                </Grid>
                <Grid container item justifyContent={'flex-end'}>
                    <SharedButton
                        className={`${classes.Chip} ${classes.markAsRead}`}
                        title={t("markAllAsRead")}
                        onClick={onMarkAllAsRead}
                        variant={'contained'}
                    />
                    <SharedButton
                        className={` ${classes.redChip} ${classes.Chip}`}
                        title={t("clear")}
                        onClick={() => {
                            setSearch({
                                ...search, search:
                                    {
                                        code: undefined,
                                        imei: ''
                                    }
                            })
                        }}
                        variant={'contained'}
                    />
                    <SharedButton
                        className={`${classes.Chip} ${classes.greenChip}`}
                        title={t("search")}
                        onClick={onRefresh}
                        variant={'contained'}
                    />
                </Grid>
            </Paper>
        </>
    )
}

export default NotificationsSearch;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchBox: {
            padding: "1rem",
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginTop: '1rem'
        },
        searchBoxHeader: {
            display: 'flex', flexDirection: 'row'
        },
        searchText: {
            fontWeight: 'bold',
            fontSize: '2rem',
            color: theme.palette.primary.dark
        },
        Chip: {
            color: "white"
        },
        redChip: {
            backgroundColor: "red",
            marginRight: 5
        },
        greenChip: {
            backgroundColor: theme.palette.primary.main
        },
        searchBar: {
            backgroundColor: 'white',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
            marginTop: 15
        },
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        excelIcon: {
            color: theme.palette.primary.main,
            marginTop: 5,
            cursor: 'pointer'
        },
        searchIcon: {
            marginTop: '0.5rem', color: theme.palette.primary.dark, marginLeft: '0.5rem'
        },
        markAsRead: {
            marginInlineEnd: '1rem'
        },
    }),
);
