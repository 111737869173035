import React, {useState} from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import SharedDatePicker from "Components/Shared/DatePicker/datePicker";
import {DashboardFilter} from "Types/Dashboard";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            width: 500,
            height: 220
        },
        button: {
            color: 'white'
        }
    }),
);

interface IProps {
    value: DashboardFilter;
    onClose: () => void;
    open: boolean;
    onAdd: (e: DashboardFilter) => void;
    modalType?: 'ADD' | 'EDIT'
}

const DateFilter = ({onClose, open, onAdd, modalType, value}: IProps) => {
    const classes = useStyles();
    const [from, setFrom] = useState(value.from ?? new Date())
    const [to, setTo] = useState(value.from ?? new Date())
    const {t} = useTranslation("dashboard")

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">{modalType === 'ADD' ? t('addFilter') : t('editFilter')}</h2>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <SharedDatePicker
                                    value={from}
                                    handleChange={(e: any) => setFrom(e)}
                                    label={t("from")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SharedDatePicker
                                    value={to}
                                    handleChange={(e: any) => setTo(e)}
                                    label={t("to")}
                                />
                            </Grid>
                            <Grid container item justifyContent={'flex-end'}>
                                <Button className={classes.button} onClick={() => {
                                    onAdd({from, to, type: value.type})
                                    onClose()
                                }}
                                        variant="contained" color="primary">
                                    {modalType === 'ADD' ? t("add") : t("edit")}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default DateFilter;
