import React, {useState} from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import {IScooterModel} from "../../Types/Scooters";
import {Box, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import UnlockIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
import ScootersClient from "../../Api/ScootersClient";
import {parseDate} from "../../Utils/dateUtils";

interface IProps {

    info: IScooterModel
    lng: string
    lat: string
}

const client = new ScootersClient()

const Component = (props: IProps) => {

    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const getScooterColor = (status: string) => {
        switch (status) {
            case 'Active':
                return "#99C455"
            case 'UnderMaintenance':
                return "#fcba03"
            case 'Inactive':
                return "#FFCCCB"
            case 'InRide':
                return "#3021d1"
            default:
                return "#99C455"
        }
    }
    return (
        <Box key={props.info.code} position={"relative"} onMouseLeave={() => setInfoModalOpen(false)}
             onMouseOver={() => {
                 setInfoModalOpen(true)
             }}>

            {
                infoModalOpen &&
                <Box position={"absolute"} minWidth={271} boxShadow={"#rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"}
                     borderRadius={13}
                     top={-63} minHeight={50} bgcolor={"white"} padding={1}>
                    <Box>
                        <Typography>
                            {props.info.code}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography>
                            {props.info.status}
                        </Typography>
                    </Box>
                    <Box>
                        Area: {props.info.area?.nameEn ?? props.info.area?.nameEn}
                    </Box>
                    <Box>
                        Battry: {props.info.condition?.scooterCharge} %
                    </Box>
                    <Box>
                        Initial Charge: {props.info.rate?.initialCharge} SAR
                    </Box>
                    <Box>
                        Per Minute: {props.info.rate?.perMinute} SAR
                    </Box>
                    <Box>
                        <IconButton onClick={() => client.CommandScooter(props.info.id, "start")}>
                            <UnlockIcon color="inherit"/>
                        </IconButton>
                        <IconButton onClick={() => client.CommandScooter(props.info.id, "stop")}>
                            <LockIcon color="inherit"/>
                        </IconButton>
                    </Box>
                    <Box>
                        Last location update: {parseDate(props.info.location.timeStamp)}
                    </Box>
                </Box>
            }

            <Box>
                    <SvgIcon style={{color: getScooterColor(props.info.status)}} fontSize={"medium"}>
                        <svg width={28} height={32} viewBox="0 0 40 70" {...props}>
                            <g data-name="Group 34" transform="translate(-72.81 -270.771)">
                                <path
                                    data-name="Path 43"
                                    d="M92.623 330.123L75.49 300.447a19.784 19.784 0 0117.133-29.676 19.784 19.784 0 0117.133 29.676zm0-56.35a16.861 16.861 0 1016.861 16.861 16.861 16.861 0 00-16.861-16.862z"
                                    fill={getScooterColor(props.info.status)}
                                />
                                <circle
                                    data-name="Ellipse 4"
                                    cx={16.861}
                                    cy={16.861}
                                    r={16.861}
                                    transform="translate(75.763 273.772)"
                                    fill={getScooterColor(props.info.status)}
                                />
                                <g data-name="Group 13" transform="translate(81.747 279.785)">
                                    <path
                                        data-name="Path 35"
                                        d="M179.614 564.67a2.2 2.2 0 004.156 1.007h-1.288a1.208 1.208 0 11-.105-2.076h1.359a2.2 2.2 0 00-4.122 1.069z"
                                        transform="translate(-178.924 -545.19)"
                                        fill="#141313"
                                    />
                                    <g data-name="Group 18">
                                        <g data-name="Group 17">
                                            <path
                                                data-name="Path 37"
                                                d="M356.726 557.767a.232.232 0 00.232-.232 2.445 2.445 0 012.443-2.443 2.418 2.418 0 011.087.254.232.232 0 10.207-.416 2.878 2.878 0 00-1.294-.3 2.911 2.911 0 00-2.908 2.908.232.232 0 00.232.233z"
                                                transform="translate(16.634 16.572) translate(-356.494 -554.627)"
                                                fill={getScooterColor(props.info.status)}
                                            />
                                        </g>
                                    </g>
                                    <path
                                        data-name="Path 38"
                                        d="M364.24 564.963a2.211 2.211 0 102.593-2.174l.294 1.213a1.193 1.193 0 11-1.73.359l-.281-1.157a2.206 2.206 0 00-.876 1.759z"
                                        transform="translate(-346.908 -545.481)"
                                        fill="#141313"
                                    />
                                    <g data-name="Group 19">
                                        <path
                                            data-name="Path 39"
                                            d="M177.351 553.681l-.044-.128a2.651 2.651 0 00-4.821-.285.279.279 0 01-.5-.249l.006-.011a3.208 3.208 0 015.552-.216s.562.89 1.311.89z"
                                            transform="translate(0 16.271) translate(-171.959 -551.293)"
                                            fill="#8bb92f"
                                        />
                                    </g>
                                    <path
                                        data-name="Path 40"
                                        d="M203.624 386.631a1.089 1.089 0 00-1.088-1.089h-.092l-10.771.924a.164.164 0 000 .327l10.77.921a1.088 1.088 0 001.177-.992q.005-.044.004-.091z"
                                        transform="translate(-189.756 -384.211)"
                                        fill="#828288"
                                    />
                                    <path
                                        data-name="Path 41"
                                        d="M195.677 447.634v-.012a1.088 1.088 0 00-1.088-1.089h-.087l-11.561.928a.164.164 0 000 .327l11.565.928a1.088 1.088 0 001.168-1c.002-.027.003-.054.003-.082z"
                                        transform="translate(-181.811 -439.705)"
                                        fill="#828288"
                                    />
                                    <path
                                        data-name="Path 42"
                                        d="M185.884 508.638v-.026a1.089 1.089 0 00-1.089-1.088h-.081l-12.539.932a.164.164 0 000 .328l12.539.932a1.088 1.088 0 001.166-1q.004-.04.004-.078z"
                                        transform="translate(-172.016 -495.198)"
                                        fill="#828288"
                                    />
                                    <path
                                        data-name="Path 44"
                                        d="M213.841 399.926a.621.621 0 01-.8-.434l-.872-3.39-3.081 3.5a1.463 1.463 0 01-1.1.5h-10.913a.79.79 0 010-1.581h10.474a1.3 1.3 0 00.964-.426l3.075-3.359a.587.587 0 00.135-.543l-.5-1.918v-.027a.607.607 0 01.157-.461l-2.58-10.368a.389.389 0 01.263-.466l.775-.225.03.044a.777.777 0 00.475.556l-.394.107a.368.368 0 00-.255.442l2.418 9.753a.378.378 0 01.321.28l.081.309 1.729 6.967a.622.622 0 01-.402.74z"
                                        transform="translate(-194.091 -379.831)"
                                        fill="#141313"
                                    />
                                    <circle
                                        data-name="Ellipse 5"
                                        cx={0.695}
                                        cy={0.695}
                                        r={0.695}
                                        transform="translate(15.922)"
                                        fill="#8bbb29"
                                    />
                                </g>
                            </g>
                        </svg>
                    </SvgIcon>
            </Box>
        </Box>
    )
}

export default Component;
