import React, {FunctionComponent} from "react";
import {Modal, Paper, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import SharedTable from "Components/Shared/SharedTable";
import {useSelector} from "react-redux";
import {Operation} from "Types/JobRole";
import {parseDate} from "Utils/dateUtils";
import IAppStateModel from "Types/AppState";
import {statusBadgeHandler} from "Components/Views/Rides/Rides";
import IPagedResults, {IPagedSearch} from "Types/Paged";
import {IRide} from "Types/Rides";
import { RiFileExcel2Fill } from "react-icons/ri";

interface IProps {
    open: boolean
    onClose: () => void;
    modal?: boolean;
    search: IPagedSearch<any>;
    setSearch: React.Dispatch<React.SetStateAction<IPagedSearch<any>>>;
    results: IPagedResults<IRide> | undefined
    onExport?: () => void;
}

const SharedRides: FunctionComponent<IProps> = ({open, onClose, modal, results, search, setSearch, onExport}) => {
    const classes = useStyles();
    const role = useSelector((state: IAppStateModel) => state.profile?.role)

    const {t} = useTranslation("rides")
    console.log(results?.matches, 'matches')

    const columns = [
        {field: 'id', headerName: 'ID', width: 90},
        {
            field: 'id',
            headerName: t('idHeader'),
            width: 100,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('idHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'groupRideId',
            headerName: t('Group Ride Id'),
            width: 250,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('Group Ride Id')}
                    </Typography>
                )
            },
            renderCell: (params: any) => {
                return (
                    params.row.groupRideId ?? 'N/A'
                )
            },
        },
        {
            field: 'user',
            headerName: t('userNameHeader'),
            width: 150,
            editable: false,
            renderCell: (params: any) => {
                return (
                    params.row?.user?.name
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('userNameHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'mobile',
            headerName: t('mobile'),
            width: 150,
            editable: false,
            renderCell: (params: any) => {
                return (
                    params.row?.user?.mobile
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('mobile', {ns: 'users'})}
                    </Typography>
                )
            },
        },
        {
            field: 'status',
            headerName: t('statusHeader'),
            width: 150,
            renderCell: (params: any) => {
                return (
                    statusBadgeHandler(params.row?.status, classes)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('statusHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'charge',
            headerName: t('charge'),
            width: 150,
            sortable: false,
            hide: role === Operation.value,
            renderCell: (params: any) => {
                return (
                    `${(params.row.charge ?? 0) + ' SAR'}`
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('charge')}
                    </Typography>
                )
            },
        },
        {
            field: 'createdOn',
            headerName: t('createdOnHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row?.createdOn)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('createdOnHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'finishedOn',
            headerName: t('finishedOnHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row?.finishedOn)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('finishedOnHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'canceledOnHeader',
            headerName: t('canceledOnHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row?.canceledOn)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('canceledOnHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'scooterStartedOn',
            headerName: t('scooterStartedOnHeader'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row?.scooterStartedOn)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('scooterStartedOnHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'scooterEndedOn',
            headerName: t('scooterEndedOnHeader'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row?.scooterEndedOn)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('scooterEndedOnHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'scooterFailedOn',
            headerName: t('scooterFailedOnHeader'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row?.scooterFailedOn)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('scooterFailedOnHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'scooterCode',
            headerName: t('scooterCodeHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('scooterCodeHeader')}
                    </Typography>
                )
            },
            renderCell: (params: any) => {
                return (
                    <Typography className={classes.tableHeader}>
                        {params.row.scooter.code}
                    </Typography>
                )
            },
        },
        {
            field: 'rate',
            headerName: t('rate'),
            width: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    `${params.row.rate ? params.row.rate + '/5' : "-"}`
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('rate')}
                    </Typography>
                )
            },
        },
        {
            field: 'comment',
            headerName: t('comment'),
            width: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    params.row.comment
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('comment')}
                    </Typography>
                )
            },
        },
        {
            field: 'area',
            headerName: t('areaHeader'),
            width: 100,
            sortable: false,
            disableColumnMenu: true,

            renderCell: (params: any) => {
                return (
                    params.row.area
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('areaHeader')}
                    </Typography>
                )
            },
        }
    ];

    return (
        <div className={classes.main}>
            {modal ? (
                <Modal open={open} className={classes.modal} onClose={onClose}>
                    <Paper elevation={2} style={{ marginTop: '2rem', width: '80%', height: '45vh', overflowY: 'auto' }}>
                        {onExport && <div className={classes.excelIconContainer}>
                            <RiFileExcel2Fill onClick={onExport} className={classes.excelIcon} size={30}/>
                        </div>}
                        <SharedTable
                            columns={columns}
                            rows={results?.matches ?? []}
                            pageSize={search?.limit}
                            onPageChange={(i) => setSearch((s) => ({ ...s, offset: (i) * s.limit }))}
                            onPageSizeChange={(i) => setSearch((s) => ({ ...s, limit: i }))}
                            rowCount={results?.total}
                            page={Math.ceil(search?.offset / search?.limit)}
                            getRowId={(i: any) => {
                                return i.id
                            }}
                        />
                    </Paper>
                </Modal>
            ) : (
                <Paper elevation={2} style={{ marginTop: '2rem', width: '100%', height: '45vh' }}>
                    <SharedTable
                        columns={columns}
                        rows={results?.matches ?? []}
                        pageSize={search?.limit}
                        onPageChange={(i) => setSearch((s) => ({ ...s, offset: (i) * s.limit }))}
                        onPageSizeChange={(i) => setSearch((s) => ({ ...s, limit: i }))}
                        rowCount={results?.total}
                        page={Math.ceil(search?.offset / search?.limit)}
                        getRowId={(i: any) => {
                            return i.id
                        }}
                    />
                </Paper>
            )}
        </div>
    )
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: "scroll",
            padding: "3%",
            alignSelf: "center",
        },
        main: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 21
        },
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        },
        Chip: {
            color: "white"
        },
        redChip: {
            backgroundColor: "red"
        },
        blueChip: {
            backgroundColor: "blue"
        },
        excelIcon: {
            color: theme.palette.primary.main,
            marginTop: 5,
            cursor: 'pointer'
        },
        excelIconContainer: {
            display: 'flex',
            flexDirection: 'row-reverse',
            margin: 8
        }
    }),
);
export default SharedRides;
