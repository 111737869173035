import React, {useEffect, useState} from 'react';
import {createStyles, Theme} from '@material-ui/core/styles';
import {makeStyles} from "@material-ui/styles";
import IPagedResults, {IPagedSearch} from "Types/Paged";
import {useDispatch} from "react-redux";
import {SetIsLoading} from "State/Actions/UiActions";
import {useTranslation} from "react-i18next";
import {IScooterLog} from "Types/Scooters";
import ScootersClient from "Api/ScootersClient";
import dayjs from "dayjs";
import LogsSearch from "Components/Views/Logs/Components/LogsSearch";
import {ILogsSearchModel} from "Types/Logs";
import SharedTable from "Components/Shared/SharedTable";
import {Typography, Paper} from "@material-ui/core";
import {parseDate} from "Utils/dateUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        }
    }),
);


const Component = () => {
    const classes = useStyles();
    const {t} = useTranslation("areas")
    const [search, setSearch] = useState<IPagedSearch<ILogsSearchModel>>({
        limit: 25,
        offset: 0,
        search: {
            scooterId: null,
            from: null,
            to: null,
        }
    });
    const [results, setResults] = useState<IPagedResults<IScooterLog>>();
    const client = new ScootersClient();
    const dispatch = useDispatch();
    const refresh = async (info: IPagedSearch<ILogsSearchModel>) => {
        dispatch(SetIsLoading(true))
        let res = await client.GetLogs(info);
        if (res.succeeded) {
            setResults(res.data);
        } else {

        }
        dispatch(SetIsLoading(false))
    }
    useEffect(() => {
        refresh(search).then();
    }, [search.limit, search.offset])

    const columns = [
        {
            field: 'scooterId',
            headerName: t('idHeader', {ns: 'users'}),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('idHeader', {ns: 'users'})}
                    </Typography>
                )
            },
        },
        {
            field: 'timeStamp',
            headerName: t('timeStampHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row.timeStamp)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('timeStampHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'latitude',
            headerName: t('latitudeHeader'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('latitudeHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'longitude',
            headerName: t('longitudeHeader'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('longitudeHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'scooterBattery',
            headerName: t('scooterChargeHeader', {ns: 'scooters'}),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('scooterChargeHeader', {ns: 'scooters'})}
                    </Typography>
                )
            },

        },
        {
            field: 'iotBattery',
            headerName: t('scooterChargeHeader', {ns: 'scooters'}),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('scooterChargeHeader', {ns: 'scooters'})}
                    </Typography>
                )
            },

        },
        {
            field: 'speedLimit',
            headerName: t('speedLimitHeader', {ns: 'scooters'}),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('speedLimitHeader', {ns: 'scooters'})}
                    </Typography>
                )
            },
        },
    ];

    return (
        <>
            <LogsSearch
                isExportHidden={false}
                search={search}
                setSearch={(e) => setSearch(e)}
                onSearch={async () => await refresh(search)}
                onClear={async () => {
                    setSearch({...search, search: {scooterId: null, from: null, to: null}})
                    await refresh({...search, search: {scooterId: null, from: null, to: null}})
                }}
            />
            <Paper elevation={2} style={{marginTop: '2rem', width: '100%', height: '50vh'}}>
                <SharedTable
                    columns={columns}
                    rows={results?.matches ?? []}
                    pageSize={search?.limit}
                    onPageChange={(i) => setSearch((s) => ({...s, offset: (i) * s.limit}))}
                    onPageSizeChange={(i) => setSearch((s) => ({...s, limit: i}))}
                    rowCount={results?.total}
                    page={Math.ceil(search?.offset / search?.limit)}
                    getRowId={(i: any) => {
                        return i.id
                    }}
                />
            </Paper>
        </>
    );
}

export default Component;
