import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {createStyles, Theme} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import {makeStyles} from "@material-ui/styles";
import {IPagedSearch} from "../../../Types/Paged";
import {useDispatch} from "react-redux";
import {SetIsLoading} from "../../../State/Actions/UiActions";
import MUIDataTable from "mui-datatables";
import {useTranslation} from "react-i18next";
import {IPayment} from "../../../Types/Payments";
import PaymentsInfoClient from "../../../Api/PaymentInfoClient";


const Component = (props: any) => {
    const classes = useStyles();
    const {t} = useTranslation(["payments", "users"])
    let client = new PaymentsInfoClient();
    const dispatch = useDispatch();
    const [count, setCount] = useState<number>(0);
    const [results, setResults] = useState<IPayment[]>([]);
    const [search, setSearch] = useState<IPagedSearch<any>>({
        limit: 20,
        offset: 0
    });


    const refresh = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.SearchAsync(search);
        if (res.succeeded) {
            setResults(res.data?.matches);
            setCount(res.data?.total)
        }
        dispatch(SetIsLoading(false))
    }

    useEffect(() => {
        refresh().then();
    }, [search.limit, search.offset])

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <SearchIcon className={classes.block} color="inherit"/>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                placeholder="Search by email address, phone number, or user UID"
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.searchInput,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Tooltip title="Reload">
                                <IconButton onClick={refresh}>
                                    <RefreshIcon className={classes.block} color="inherit"/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.contentWrapper}>
                <MUIDataTable

                    title={t("Payment Information")}
                    data={results ?? []}
                    columns={[
                        {name: 'id', label: t("idHeader", {ns: "users"})},
                        {
                            name: "provider", label: t("provider"),
                            options: {
                                customBodyRender: (value) => <small>{value}</small>
                            }
                        },
                        {name: 'paymentBrand', label: t("paymentBrand")},
                        {name: 'packageId', label: t("packageId")},
                        {
                            name: 'user', label: t("name"), options: {
                                customBodyRender: (value) => value.name
                            }
                        },
                        {
                            name: 'mobile', label: t("mobileHeader", {ns: "users"}), options: {
                                customBodyRender: (value) => value?.mobile
                            }
                        },
                    ]}

                    options={{
                        print:false,
                        download: false,
                        filter: false,
                        serverSide: true,
                        filterType: 'dropdown',
                        searchProps: {
                            onKeyPress: (i) => {
                                if (i.key === "Enter")
                                    refresh().then()
                            }
                        },

                        searchText: search.search,
                        onSearchChange: (i) => setSearch((s) => ({...s, search: i})),
                        selectableRows: 'none',
                        responsive: 'standard',
                        rowsPerPage: search.limit,
                        count: count,
                        onChangeRowsPerPage: (i) => setSearch((s) => ({...s, limit: i})),
                        page: (search.offset / search.limit),
                        onChangePage: (i) => {
                            setSearch((s) => ({...s, offset: (i) * s.limit}))
                        },


                    }}
                />

            </div>
        </Paper>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: 1200,
            margin: 'auto',
            overflow: 'hidden',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        contentWrapper: {
            margin: '40px 16px',
        },
    }),
);


export default Component;
