import React from "react";
import {Button, CircularProgress} from "@material-ui/core";

export interface ButtonProps {
    title?: string
    icon?: React.ReactNode
    onClick: () => any
    variant?: "text" | "contained" | "outlined" | undefined
    disabled?: boolean
    size?: 'small' | 'regular'
    isLoading?: boolean;
    customStyle?: React.CSSProperties;
    className?: string;
}

const SharedButton = (props: ButtonProps) => {
    const { title, onClick, variant, disabled = false, icon, isLoading = false,customStyle, className } = props;

    return (
        <Button
            className={className}
            style={customStyle}
            variant={variant}
            onClick={() => onClick()}
            disabled={disabled || isLoading}
            startIcon={icon}
        >
            <>
                {isLoading && <CircularProgress size={15} />}
                {!isLoading && <span>{title}</span>}
            </>
        </Button>
    )
}

export default SharedButton;
