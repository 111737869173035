import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core/styles";
import {INotification} from "Types/Notifications";
import {Box, Typography} from "@material-ui/core";
import {parseDate} from "Utils/dateUtils";
import InfoIcon from '@material-ui/icons/Info';

interface IProps {
    notification: INotification
}

const InterviewCard = ({notification}: IProps) => {
    const classes = useStyles()
    return (
        <Box style={{backgroundColor: notification.isRead ? '#FFFFFF' : "#BA83810D"}} className={classes.interviewCard}>
            <Box className={classes.container}>
                <Box className={classes.titleContainer}>
                    <InfoIcon color={'disabled'}/>
                    <Typography className={classes.title}>
                        {notification.title}
                    </Typography>
                </Box>
                <Box style={{display: 'flex'}}>
                    <CalendarTodayIcon color="disabled"/>
                    <Typography className={classes.title}>
                        {parseDate(notification.createdOn)}
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.messageContainer}>
                <Typography className={classes.message}>
                    {notification.message}
                </Typography>
            </Box>
        </Box>
    )
}

export default InterviewCard;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        },
        interviewCard: {
            borderRadius: "8px",
            borderLeftWidth: "2px",
            borderLeftColor: theme.palette.primary.main,
            borderLeftStyle: "solid",
            padding: "1rem"
        },
        title: {
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "26px",
            letterSpacing: "0em",
            color: theme.palette.primary.dark,
            whiteSpace: 'break-spaces',
            margin: '0 5px'
        },
        titleContainer: {
            display: 'flex',
        },
        message: {
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "26px",
            letterSpacing: "0em",
            color: theme.palette.primary.dark,
            whiteSpace: 'break-spaces',
            margin: '0 5px'
        },
        messageContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            margin: '0 25px'
        },
    }),
);
