import React, {useCallback, useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {createStyles, Theme} from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import {makeStyles} from "@material-ui/styles";
import GoogleMapReact from 'google-map-react';
import {IPagedSearch} from "../../../Types/Paged";
import {IScooterModel} from "../../../Types/Scooters";
import ScootersClient from "../../../Api/ScootersClient";
import {useDispatch} from "react-redux";
import {SetIsLoading} from "../../../State/Actions/UiActions";
import ScooterMarker from "../../Shared/ScooterMarker";
import Config from '../../../config';
import DashboardClient from "../../../Api/DashboardClient";
import DataArrayFromObject from "../../Shared/DataArrayFromObject";
import {Box} from "@material-ui/core";
import SharedTextInput from "Components/Shared/TextInput";
import {useTranslation} from "react-i18next";
import {DashboardFilter, DashboardFilterType} from "Types/Dashboard";
import SharedButton from "Components/Shared/Button";


const Component = (props: any) => {
    const [search, setSearch] = useState<IPagedSearch<any>>({
        limit: 1000,
        offset: 0,
        search: {
            statuses: [11, 21, 51, 41],
            code: ''
        }
    });
    const {t} = useTranslation('dashboard')
    const [results, setResults] = useState<IScooterModel[]>([]);
    const [dashData, setDashData] = useState<Object>([])
    const [filters, setFilters] = useState<DashboardFilter[]>([])

    const addFilter = (filter: DashboardFilter) => {
        // Check if a filter with the same type already exists
        const existingFilterIndex = filters.findIndex((f) => f.type === filter.type);

        if (existingFilterIndex !== -1) {
            // If a filter with the same type exists, update it
            const updatedFilters = [...filters];
            updatedFilters[existingFilterIndex] = filter;
            setFilters(updatedFilters);
        } else {
            // If no filter with the same type exists, add the new filter
            setFilters([...filters, filter]);
        }
    };
    const removedFilter = (type: DashboardFilterType) => {
        setFilters(filters.filter(x => x.type !== type))
    }

    const classes = useStyles();
    const defaultProps = {
        center: {
            lng: 39.5692,
            lat: 24.5247
        },
        zoom: 6
    };


    const dispatch = useDispatch();
    const refresh = useCallback(async () => {
        dispatch(SetIsLoading(true))
        const scootersClient = new ScootersClient();
        const dashboardClient = new DashboardClient();
        const resS = scootersClient.SearchAsync(search);
        const resD = dashboardClient.GetDashboardData(filters);
        const [sco, dash] = await Promise.all([resS, resD])
        if (sco.succeeded) {
            setResults(sco.data?.matches);
        }
        if (dash.succeeded) {
            setDashData(dash.data);
        }
        dispatch(SetIsLoading(false))
    }, [dispatch, search, filters])

    useEffect(() => {
        refresh();
    }, [search, filters])

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item md={3} style={{padding: 3}}>
                            <SharedTextInput
                                label={t('Search by code')}
                                value={search.search?.code ?? ''}
                                onChange={(e: any) => {
                                    setSearch({
                                        ...search,
                                        search: {
                                            ...search.search,
                                            code: e,
                                        }
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs>

                        </Grid>
                        <Grid item>

                            <Tooltip title="Reload">
                                <IconButton onClick={refresh}>
                                    <RefreshIcon className={classes.block} color="inherit"/>
                                </IconButton>
                            </Tooltip>
                            <SharedButton
                                className={` ${classes.redChip} ${classes.Chip}`}
                                title={t("clearAllFilters")}
                                onClick={() => setFilters([])}
                                variant={'contained'}
                            />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div>
                <Box p={2}>
                    <Paper>
                        <Box p={2}>

                            <DataArrayFromObject filters={filters} onAdd={addFilter} onRemove={removedFilter}
                                                 data={dashData}/>
                        </Box>
                    </Paper>
                </Box>


            </div>
            <div className={classes.contentWrapper}>
                <Paper elevation={2} style={{height: '500px',}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: Config.googleMapsApiKey}}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                    >
                        {
                            results?.map(r => <ScooterMarker
                                lng={r.location?.longitude}
                                lat={r.location?.latitude}
                                info={r}
                            />)
                        }

                    </GoogleMapReact>
                </Paper>

            </div>
        </Paper>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: 1200,
            margin: 'auto',
            overflow: 'hidden',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            padding: 10
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        contentWrapper: {
            margin: '40px 16px',
        },
        Chip: {
            color: "white"
        },
        redChip: {
            backgroundColor: "red",
            marginRight: 5
        },
        greenChip: {
            backgroundColor: theme.palette.primary.main,
            marginRight: 5
        },
    }),
);

export default Component;
