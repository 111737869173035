export interface ICreatePackages {
    id: number;
    price: number;
    balanceValue: number;
    type: number;
}

export interface IPackage {
    id: number;
    price: number;
    minutes: number;
    balanceValue: number;
    createdOn: Date;
    deactivated: boolean;
}

export const packageTypeOptions = [
    {label: "None", value: 0},
    {label: "TenMinutes", value: 1},
    {label: "Weekly", value: 5},
    {label: "Monthly", value: 6},
]

export type CreatePackageInitialValuesType = {
    id: number;
    price: number;
    balanceValue: number;
    createdOn: Date;
    type: number;
    areaId: number | null;
};
