import React, {useState} from 'react';
import {Modal, TextField} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import NotificationsClient from "../../../../Api/NotificationsClient";
import {ICreateNotification} from "../../../../Types/Notifications";
import {ShowAlert} from "../../../../State/Actions/UiActions";
import {useDispatch} from "react-redux";


interface IProps {
    onClose: () => void;
}

const Component = (props: IProps) => {
    const classes = useStyles();
    const {t} = useTranslation("notifications")
    const [info, setInfo] = useState<Partial<ICreateNotification>>({
        toAll: true,
        title: '',
        message: '',
        titleEn: '',
        messageEn: ''
    });
    const client = new NotificationsClient();
    const dispatch = useDispatch()
    const onSubmit = async () => {
        if (info) {
            let res = await client.CreateAsync(info as ICreateNotification);
            if (res.succeeded) {
                dispatch(ShowAlert(t("The notification was sent successfully"), "success"));
                props.onClose()
            } else {
                dispatch(ShowAlert(t("Something went wrong"), "error"));
            }
        } else {
        }

    }

    return (
        <Modal
            open={true}
            onClose={props.onClose}
            className={classes.modal}
        >
            <div className={classes.paper}>
                <h2 id="simple-modal-title">{t("addTitle")}</h2>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label={t("titleAr")}
                            fullWidth
                            value={info?.title}
                            onChange={(e) => setInfo({
                                ...info,
                                title: e.target.value
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label={t("titleEn")}
                            fullWidth
                            value={info?.titleEn}
                            onChange={(e) => setInfo({
                                ...info,
                                titleEn: e.target.value
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            minRows={5}
                            maxRows={5}
                            required
                            label={t("messageAr")}
                            fullWidth
                            value={info?.message}
                            onChange={(e) => setInfo({
                                ...info,
                                message: e.target.value
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            minRows={5}
                            maxRows={5}
                            required
                            label={t("messageEn")}
                            fullWidth
                            value={info?.messageEn}
                            onChange={(e) => setInfo({
                                ...info,
                                messageEn: e.target.value
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                        >
                            {t("send")}
                        </Button>
                    </Grid>
                </Grid>

            </div>

        </Modal>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,

            padding: theme.spacing(2, 4, 3),
        },
    }),
);

export default Component;
