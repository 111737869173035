import React, {useEffect, useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {createStyles, Theme} from '@material-ui/core/styles';
import {makeStyles} from "@material-ui/styles";
import {IPagedSearch} from "Types/Paged";
import {useDispatch, useSelector} from "react-redux";
import {SetIsLoading, ShowAlert} from "State/Actions/UiActions";
import {useTranslation} from "react-i18next";
import {ColumnName, IRide, IRideSearch, IRideStatus} from "Types/Rides";
import RidesClient from "Api/RidesClient";
import {parseDate} from "Utils/dateUtils";
import JourneyIcon from "@material-ui/icons/Explore";
import ScooterModal from "../IotDevices/Components/ScooterModal";
import {Box, Chip, Paper, Typography} from "@material-ui/core";
import ScooterHistoryModal from "../../Shared/ScooterHistoryModal";
import Button from "@material-ui/core/Button";
import {Admin, Operation} from "Types/JobRole";
import IAppStateModel from "Types/AppState";
import RidesSearch from "Components/Views/Rides/Components/RidesSearch";
import SharedTable from "Components/Shared/SharedTable";
import {SortType} from "Types/Users";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import {ArrowDownward} from "@material-ui/icons";
import CustomerRidesModal from "Components/Views/Rides/Components/CustomerRidesModal";
import CommentModal from "Components/Views/Rides/Components/CommentModal";


export const statusBadgeHandler = (status: IRideStatus, classes: any): JSX.Element => {
    switch (status) {
        case "Started": {
            return <Chip size={"small"} className={` ${classes.Chip}`} label={status} color={"secondary"}/>
        }
        case "Failed": {
            return <Chip size={"small"} className={` ${classes.redChip} ${classes.Chip}`} label={status}/>
        }
        case "Ended": {
            return <Chip size={"small"} className={` ${classes.Chip} `} color={"primary"} label={status}/>
        }
        case "Finished": {
            return <Chip size={"small"} className={` ${classes.Chip} ${classes.blueChip}`} color={"primary"}
                         label={status}/>
        }
        case "Canceled": {
            return <Chip size={"small"} className={` ${classes.Chip} ${classes.blackChip}`} color={"primary"}
                         label={status}/>
        }
    }
    return <>{status}</>
}

const Component = () => {
    const {t} = useTranslation("rides")
    const classes = useStyles();
    const role = useSelector((state: IAppStateModel) => state.profile?.role)
    const [scooterModalFor, setScooterModalFor] = useState<number>();
    const [count, setCount] = useState<number>(0);
    const [userId, setUserId] = useState(0);
    const [rideModalOpen, setRideModalOpen] = useState<"none" | "map" | "history">("none")
    const [comment, setComment] = useState('')
    const [search, setSearch] = useState<IPagedSearch<IRideSearch>>({
        limit: 25,
        offset: 0,
        search: {
            groupRideId: undefined,
            status: '',
            user: '',
            id: undefined,
            from: null,
            to: null,
            sortColumn: null
        }
    });
    const [results, setResults] = useState<IRide[]>([]);
    let client = new RidesClient();
    const dispatch = useDispatch();
    const onCancel = async (id: string) => {
        let res = await client.CancelRideAsync(id);
        if (res.succeeded) {
            await refresh()
        }
    }
    const refresh = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.SearchAsync(search);
        if (res.succeeded) {
            setResults(res.data?.matches);
            setCount(res.data?.total)
        } else {

        }
        dispatch(SetIsLoading(false))
    }
    useEffect(() => {
        refresh().then();
    }, [search.limit, search.offset, search.search?.status, search.search?.sortColumn?.columnName, search.search?.sortColumn?.sortType])
    const onGetLogs = async () => {
        if (scooterModalFor) {
            const r: any = results.find((x: IRide) => x.id == scooterModalFor)
            const res = await client.GetAsync(scooterModalFor, {
                scooterStoppedOn: r?.scooterStoppedOn,
                scooterStartedOn: r?.scooterStartedOn
            })
            if (res.succeeded) {
                return res.data.journey

            } else {
                dispatch(ShowAlert("Could not load scooter journey"));
            }
        }
    }

    const handleSort = (columnName: ColumnName, sortType: SortType) => {
        setSearch({
            ...search,
            search: {
                ...search.search,
                sortColumn: {
                    columnName: columnName,
                    sortType: sortType,
                },
            },
        });
    };


    const columns = [
        {field: 'id', headerName: 'ID', width: 90},
        {
            field: 'id',
            headerName: t('idHeader'),
            width: 100,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('idHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'groupRideId',
            headerName: t('Group Ride Id'),
            width: 200,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('Group Ride Id')}
                    </Typography>
                )
            },
            renderCell: (params: any) => {
                return (
                    params.row.groupRideId ?? 'N/A'
                )
            },
        },

        {
            field: 'user',
            headerName: t('userNameHeader'),
            width: 150,
            editable: false,
            renderCell: (params: any) => {
                return (
                    <Typography className={classes.customerNameHeader}
                                onClick={() => setUserId(params.row?.user?.id)}>{params.row?.user?.name}</Typography>
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('userNameHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'rideStopReason',
            headerName: t('statusHeader'),
            width: 200,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('Ride Stop Reason')}
                    </Typography>
                )
            },
        },
        {
            field: 'status',
            headerName: t('statusHeader'),
            width: 150,
            renderCell: (params: any) => {
                return (
                    statusBadgeHandler(params.row?.status, classes)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('statusHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'charge',
            headerName: t('charge'),
            width: 150,
            sortable: false,
            hide: role === Operation.value,
            renderCell: (params: any) => {
                return (
                    `${(params.row.charge ?? 0) + ' SAR'}`
                )
            },
            renderHeader: (params: any) => {
                return (
                    <>
                        <Box className={classes.columnContainer}><Typography
                            className={classes.tableHeader}>{t("charge")}</Typography>
                            {(search.search?.sortColumn == null || search.search?.sortColumn.columnName !== ColumnName.Charge || (search.search?.sortColumn.columnName === ColumnName.Charge && search.search?.sortColumn.sortType === SortType.Asc)) &&
                                <ArrowUpwardIcon className={classes.icon} onClick={() => {
                                    handleSort(ColumnName.Charge, SortType.Desc)
                                }}/>}
                            {((search.search?.sortColumn?.columnName === ColumnName.Charge && search.search?.sortColumn.sortType === SortType.Desc)) &&
                                <ArrowDownward className={classes.icon} onClick={() => {
                                    handleSort(ColumnName.Charge, SortType.Asc)
                                }}/>}
                        </Box>
                    </>
                )
            },
        },
        {
            field: 'createdOn',
            headerName: t('createdOnHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,

            renderCell: (params: any) => {
                return (
                    parseDate(params.row?.createdOn)
                )
            },
            renderHeader: (params: any) => {
                return (
                    <>
                        <Box className={classes.columnContainer}><Typography
                            className={classes.tableHeader}>{t("createdOnHeader")}</Typography>
                            {(search.search?.sortColumn == null || search.search?.sortColumn.columnName !== ColumnName.Created || (search.search?.sortColumn.columnName === ColumnName.Created && search.search?.sortColumn.sortType === SortType.Asc)) &&
                                <ArrowUpwardIcon className={classes.icon} onClick={() => {
                                    handleSort(ColumnName.Created, SortType.Desc)
                                }}/>}
                            {((search.search?.sortColumn?.columnName === ColumnName.Created && search.search?.sortColumn.sortType === SortType.Desc)) &&
                                <ArrowDownward className={classes.icon} onClick={() => {
                                    handleSort(ColumnName.Created, SortType.Asc)
                                }}/>}
                        </Box>
                    </>
                )
            },
        },
        {
            field: 'finishedOn',
            headerName: t('finishedOnHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row?.finishedOn)
                )
            },
            renderHeader: (params: any) => {
                return (
                    <>
                        <Box className={classes.columnContainer}><Typography
                            className={classes.tableHeader}>{t("finishedOnHeader")}</Typography>
                            {(search.search?.sortColumn == null || search.search?.sortColumn.columnName !== ColumnName.Finished || (search.search?.sortColumn.columnName === ColumnName.Finished && search.search?.sortColumn.sortType === SortType.Asc)) &&
                                <ArrowUpwardIcon className={classes.icon} onClick={() => {
                                    handleSort(ColumnName.Finished, SortType.Desc)
                                }}/>}
                            {((search.search?.sortColumn?.columnName === ColumnName.Finished && search.search?.sortColumn.sortType === SortType.Desc)) &&
                                <ArrowDownward className={classes.icon} onClick={() => {
                                    handleSort(ColumnName.Finished, SortType.Asc)
                                }}/>}
                        </Box>
                    </>
                )
            },
        },
        {
            field: 'canceledOnHeader',
            headerName: t('canceledOnHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row?.canceledOn)
                )
            },
            renderHeader: (params: any) => {
                return (
                    <>
                        <Box className={classes.columnContainer}><Typography
                            className={classes.tableHeader}>{t("canceledOnHeader")}</Typography>
                            {(search.search?.sortColumn == null || search.search?.sortColumn.columnName !== ColumnName.Canceled || (search.search?.sortColumn.columnName === ColumnName.Canceled && search.search?.sortColumn.sortType === SortType.Asc)) &&
                                <ArrowUpwardIcon className={classes.icon} onClick={() => {
                                    handleSort(ColumnName.Canceled, SortType.Desc)
                                }}/>}
                            {((search.search?.sortColumn?.columnName === ColumnName.Canceled && search.search?.sortColumn.sortType === SortType.Desc)) &&
                                <ArrowDownward className={classes.icon} onClick={() => {
                                    handleSort(ColumnName.Canceled, SortType.Asc)
                                }}/>}
                        </Box>
                    </>
                )
            },
        },
        {
            field: 'scooterStartedOn',
            headerName: t('scooterStartedOnHeader'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row?.scooterStartedOn)
                )
            },
            renderHeader: (params: any) => {
                return (
                    <>
                        <Box className={classes.columnContainer}><Typography
                            className={classes.tableHeader}>{t("scooterStartedOnHeader")}</Typography>
                            {(search.search?.sortColumn == null || search.search?.sortColumn.columnName !== ColumnName.Started || (search.search?.sortColumn.columnName === ColumnName.Started && search.search?.sortColumn.sortType === SortType.Asc)) &&
                                <ArrowUpwardIcon className={classes.icon} onClick={() => {
                                    handleSort(ColumnName.Started, SortType.Desc)
                                }}/>}
                            {((search.search?.sortColumn?.columnName === ColumnName.Started && search.search?.sortColumn.sortType === SortType.Desc)) &&
                                <ArrowDownward className={classes.icon} onClick={() => {
                                    handleSort(ColumnName.Started, SortType.Asc)
                                }}/>}
                        </Box>
                    </>
                )
            },
        },
        {
            field: 'scooterEndedOn',
            headerName: t('scooterEndedOnHeader'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row?.scooterEndedOn)
                )
            },
            renderHeader: (params: any) => {
                return (
                    <>
                        <Box className={classes.columnContainer}><Typography
                            className={classes.tableHeader}>{t("scooterEndedOnHeader")}</Typography>
                            {(search.search?.sortColumn == null || search.search?.sortColumn.columnName !== ColumnName.Stopped || (search.search?.sortColumn.columnName === ColumnName.Stopped && search.search?.sortColumn.sortType === SortType.Asc)) &&
                                <ArrowUpwardIcon className={classes.icon} onClick={() => {
                                    handleSort(ColumnName.Stopped, SortType.Desc)
                                }}/>}
                            {((search.search?.sortColumn?.columnName === ColumnName.Stopped && search.search?.sortColumn.sortType === SortType.Desc)) &&
                                <ArrowDownward className={classes.icon} onClick={() => {
                                    handleSort(ColumnName.Stopped, SortType.Asc)
                                }}/>}
                        </Box>
                    </>
                )
            },
        },
        {
            field: 'scooterFailedOn',
            headerName: t('scooterFailedOnHeader'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row?.scooterFailedOn)
                )
            },
            renderHeader: (params: any) => {
                return (
                    <>
                        <Box className={classes.columnContainer}><Typography
                            className={classes.tableHeader}>{t("scooterFailedOnHeader")}</Typography>
                            {(search.search?.sortColumn == null || search.search?.sortColumn.columnName !== ColumnName.Failed || (search.search?.sortColumn.columnName === ColumnName.Failed && search.search?.sortColumn.sortType === SortType.Asc)) &&
                                <ArrowUpwardIcon className={classes.icon} onClick={() => {
                                    handleSort(ColumnName.Failed, SortType.Desc)
                                }}/>}
                            {((search.search?.sortColumn?.columnName === ColumnName.Failed && search.search?.sortColumn.sortType === SortType.Desc)) &&
                                <ArrowDownward className={classes.icon} onClick={() => {
                                    handleSort(ColumnName.Failed, SortType.Asc)
                                }}/>}
                        </Box>
                    </>
                )
            },
        },
        {
            field: 'scooterCode',
            headerName: t('scooterCodeHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    <Button color={"primary"} variant={"outlined"}
                            onClick={() => {
                                setScooterModalFor(params.row?.scooter?.id)
                                setRideModalOpen("history")
                            }}>{params.row.scooter.code}</Button>
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('scooterCodeHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'rate',
            headerName: t('rate'),
            width: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    `${params.row.rate ? params.row.rate + '/5' : "-"}`
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('rate')}
                    </Typography>
                )
            },
        },
        {
            field: 'comment',
            headerName: t('comment'),
            width: 300,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    <>
                        <Typography
                            onClick={() => setComment(params.row?.comment)}
                            className={classes.customerNameHeader}>{params.row.comment}</Typography>
                    </>
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('comment')}
                    </Typography>
                )
            },
        },
        {
            field: 'area',
            headerName: t('areaHeader'),
            width: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    params.row.area
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('areaHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'actions',
            headerName: t('actions'),
            width: 200,
            renderCell: (params: any) => {
                return <div>
                    <IconButton style={{marginLeft: 15}} onClick={() => {
                        setScooterModalFor(params.row.id)
                        setRideModalOpen("map")
                    }}>
                        <JourneyIcon className={classes.block} color="inherit"/>
                    </IconButton>

                    {((params.row.status == "Created" || params.row.status == "Started") && role === Admin.value) &&
                        <Button
                            variant="contained"
                            className={` ${classes.redChip} ${classes.Chip}`}
                            onClick={() => {
                                onCancel(params.row.id)
                            }}
                        >
                            {t("cancel")}
                        </Button>}
                </div>
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('actions')}
                    </Typography>
                )
            },
        }
    ];

    const [rideSearch, setRideSearch] = useState<IPagedSearch<IRideSearch>>({
        limit: 25,
        offset: 0,
        search: {
            userId: userId,
        }
    });
    return (
        <>
            {userId !== 0 && <CustomerRidesModal modal={true} open={userId !== 0} onClose={() => {
                setUserId(0)
            }} search={{...rideSearch, search: { userId: userId }}} setSearch={setRideSearch}/>}
            {comment !== '' && <CommentModal onClose={() => setComment('')} comment={comment} open={comment !== ''}/>}
            <RidesSearch search={search} setSearch={(e) => setSearch(e)} onSearch={() => {
                refresh().then()
            }}/>
            <Paper elevation={2} style={{marginTop: '2rem', width: '100%', height: '50vh'}}>
                <SharedTable
                    columns={columns}
                    rows={results ?? []}
                    pageSize={search?.limit}
                    onPageChange={(i) => setSearch((s) => ({...s, offset: (i) * s.limit}))}
                    onPageSizeChange={(i) => setSearch((s) => ({...s, limit: i}))}
                    rowCount={count}
                    rowsPerPageOptions={[25, 50, 100]}
                    page={Math.ceil(search?.offset / search?.limit)}
                    getRowId={(i: any) => {
                        return i.id
                    }}
                />
            </Paper>
            {scooterModalFor && rideModalOpen === "history" &&
                <ScooterHistoryModal
                    modal={true}
                    open={rideModalOpen === "history"}
                    onClose={() => {
                    setRideModalOpen("none")
                    setScooterModalFor(undefined)
                }}
                                     scooterId={scooterModalFor}/>}
            {scooterModalFor && rideModalOpen === "map" &&
                <ScooterModal open={rideModalOpen === "map"} onClose={() => {
                    setRideModalOpen("none")
                    setScooterModalFor(undefined)
                }}
                              scooterId={scooterModalFor}
                              getLogs={onGetLogs}/>}
        </>
    );
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        Chip: {
            color: "white"
        },
        redChip: {
            backgroundColor: "red"
        },
        blackChip: {
            backgroundColor: "black"
        },
        blueChip: {
            backgroundColor: "blue"
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
            marginTop: 15
        },
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        contentWrapper: {
            margin: '40px 16px',
        },
        tableWrapper: {
            maxWidth: '100%',
            overflowX: 'auto'
        },
        columnContainer: {display: 'flex', flexDirection: 'row'},
        columnHeader: {color: "#000", opacity: 0.87, fontSize: '14px', padding: 0},
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        },
        icon: {
            cursor: 'pointer'
        },
        customerNameHeader: {
            color: theme.palette.primary.dark,
            fontSize: 14,
            cursor: 'pointer'
        }
    }),
);

export default Component;
