import React, {useEffect, useState} from 'react';
import {createStyles, Theme} from '@material-ui/core/styles';
import {makeStyles} from "@material-ui/styles";
import IPagedResults, {IPagedSearch} from "Types/Paged";
import {useDispatch} from "react-redux";
import {SetIsLoading} from "State/Actions/UiActions";
import {useTranslation} from "react-i18next";
import {IScooterLog} from "Types/Scooters";
import ScootersClient from "Api/ScootersClient";
import dayjs from "dayjs";
import UsersClient from "Api/UsersClient";
import {ILookupModel} from "Types/Lookup";
import AreasClient from "Api/AreasClient";
import {ILogsSearchModel} from "Types/Logs";
import OtherLogsSearch from "Components/Views/OtherLogs/Components/OtherLogsSearch";
import SharedTable from "Components/Shared/SharedTable";
import {Paper, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        }
    }),
);


const Component = (props: any) => {
    const {t} = useTranslation("areas")
    const classes = useStyles();
    const [search, setSearch] = useState<IPagedSearch<ILogsSearchModel>>({
        limit: 25,
        offset: 0,
        search: {
            code: '',
            imei: ''
        }
    });
    const [results, setResults] = useState<IPagedResults<IScooterLog>>();
    const [allUsers, setAllUsers] = useState<{ accountId: string, fullName: string }[] | undefined>([])
    const client = new ScootersClient();
    const usersClient = new UsersClient();
    const [areas, setAreas] = useState<ILookupModel[]>([])
    const areaClient = new AreasClient();
    const getAreas = async () => {
        let res = await areaClient.GetLookupsAsync();
        if (res.succeeded) {
            setAreas(res.data);
        }
    }

    const dispatch = useDispatch();
    const getAllUsers = async () => {
        let res = await usersClient.GetAllUsers();
        if (res.succeeded) {
            setAllUsers(res.data);
        } else {

        }
    }
    const getUserName = (createdBy?: string) => {
        if (createdBy)
            return allUsers?.find(x => x.accountId === createdBy)?.fullName
        return ''
    }
    const refresh = async (info: IPagedSearch<ILogsSearchModel>) => {
        dispatch(SetIsLoading(true))
        let res = await client.GetOtherLogs(info);
        if (res.succeeded) {
            setResults(res.data);
        } else {

        }
        dispatch(SetIsLoading(false))
    }
    useEffect(() => {
        refresh(search).then();

    }, [search.limit, search.offset])

    useEffect(() => {
        getAllUsers().then()
        getAreas().then();
    }, [])

    const columns = [
        {
            field: 'scooterId',
            headerName: t('idHeader', {ns: 'users'}),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('idHeader', {ns: 'users'})}
                    </Typography>
                )
            },

        },
        {
            field: 'imei',
            headerName: t('imeiHeader', {ns: 'scooters'}),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('imeiHeader', {ns: 'scooters'})}
                    </Typography>
                )
            },

        },
        {
            field: 'code',
            headerName: t('code'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('code', {ns: 'rides'})}
                    </Typography>
                )
            },

        },
        {
            field: 'timeStamp',
            headerName: t('timeStampHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    dayjs(params.row.timeStamp).format("DD-MM-YYYY HH:mm:ss")
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('timeStampHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'createdBy',
            headerName: t('createdBy'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    getUserName(params.row?.createdBy)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('createdBy')}
                    </Typography>
                )
            },

        },
        {
            field: 'latitude',
            headerName: t('latitudeHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('latitudeHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'longitude',
            headerName: t('longitudeHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('longitudeHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'isAreaChange',
            headerName: t('isAreaChange'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    params.row?.isAreaChange ? "yes" : "no"
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('isAreaChange')}
                    </Typography>
                )
            },

        },
        {
            field: 'payloadData',
            headerName: t('newArea'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    areas?.find(x => x.value == params.row?.payloadData)?.label
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('newArea')}
                    </Typography>
                )
            },

        },
        {
            field: 'statusChange',
            headerName: t('status', {ns: 'scooters'}),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('status', {ns: 'scooters'})}
                    </Typography>
                )
            },

        },
        {
            field: 'scooterBattery',
            headerName: t('scooterChargeHeader', {ns: 'scooters'}),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('speedLimitHeader', {ns: 'scooters'})}
                    </Typography>
                )
            },

        },
    ];
    return (
        <>
            <OtherLogsSearch
                onSearch={async () => await refresh(search)}
                onClear={async () => {
                    const clearValue = {...search, search: {scooterId: null, from: null, to: null, code: '', imei: ''}}
                    setSearch(clearValue)
                    await refresh(clearValue)
                }}
                isExportHidden={false}
                search={search}
                setSearch={(e) => setSearch(e)}
            />
            <Paper elevation={2} style={{marginTop: '2rem', width: '100%', height: '50vh'}}>
                <SharedTable
                    columns={columns}
                    rows={results?.matches ?? []}
                    pageSize={search?.limit}
                    onPageChange={(i) => setSearch((s) => ({...s, offset: (i) * s.limit}))}
                    onPageSizeChange={(i) => setSearch((s) => ({...s, limit: i}))}
                    rowCount={results?.total}
                    page={Math.ceil(search?.offset / search?.limit)}
                    getRowId={(i: any) => {
                        return i.id
                    }}
                />
            </Paper>
        </>
    );
}

export default Component;
