import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookiesManager from "../Utils/CookiesManager";
import {IPagedSearch} from "../Types/Paged";
import {IRideLogsSearchModel, IRideSearch} from "../Types/Rides";

class RidesClient {
    client: IClient;
    locale: string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }


    async SearchAsync(
        search: IPagedSearch<IRideSearch>
    ): Promise<ApiResponse> {
        let query = `rides?limit=${search.limit}&offset=${search.offset}`
        if (search.search?.id) {
            query += '&id=' + search.search.id
        }
        if (search.search?.groupRideId) {
            query += '&groupRideId=' + search.search.groupRideId
        }
        if (search.search?.userId) {
            query += '&userId=' + search.search.userId
        }
        if (search.search?.status) {
            query += '&Status=' + search.search.status
        }
        if (search.search?.user) {
            query += '&UserName=' + search.search.user
        }
        if (search.search?.from) {
            query += '&from=' + search.search.from
        }
        if (search.search?.to) {
            query += '&to=' + search.search.to
        }
        if (search.search?.sortColumn) {
            const sortColumnString = JSON.stringify(search.search?.sortColumn);
            query += `&sortColumn=${encodeURIComponent(sortColumnString)}`;
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async ExportToExcelAsync(
        search: IPagedSearch<IRideSearch>
    ): Promise<ApiResponse> {
        let query = `rides/ExportToExcel?limit=${search.limit}&offset=${search.offset}`
        if (search.search?.id) {
            query += '&id=' + search.search.id
        }
        if (search.search?.from) {
            query += '&from=' + search.search.from
        }
        if (search.search?.groupRideId) {
            query += '&groupRideId=' + search.search.groupRideId
        }
        if (search.search?.to) {
            query += '&to=' + search.search.to
        }
        if (search.search?.status) {
            query += '&Status=' + search.search.status
        }
        if (search.search?.user) {
            query += '&UserName=' + search.search.user
        }
        if (search.search?.userId) {
            query += '&userId=' + search.search.userId
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async CancelRideAsync(
        id: string
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync(`rides/${id}/ForceCancelRide`, {});
        return rs;
    }

    async GetAsync(
        id: number,
        model?: IRideLogsSearchModel
    ): Promise<ApiResponse> {
        let query = `rides/${id}?scooterStoppedOn=${model?.scooterStoppedOn ?? ""}&scooterStartedOn=${model?.scooterStartedOn ?? ""}`
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }
}

export default RidesClient;
