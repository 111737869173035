import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const parseDate = (date: Date | string | undefined, withoutTime?: boolean): string => {

    if (!(date)) {
        return ""
    }
    dayjs.extend(timezone);
    dayjs.extend(utc);
    const djs = dayjs.utc(date)
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return djs.tz(tz ?? "Asia/Riyadh").format(withoutTime ? "YYYY-MM-DD" : "YYYY/MM/DD hh:mm A");
}
