import React, {useEffect, useState} from 'react';
import {createStyles, Theme} from '@material-ui/core/styles';
import {makeStyles} from "@material-ui/styles";
import {IPagedSearch} from "Types/Paged";
import {useDispatch, useSelector} from "react-redux";
import {SetIsLoading, ShowAlert} from "State/Actions/UiActions";
import {useTranslation} from "react-i18next";
import {INotification, NotificationSearch} from "Types/Notifications";
import StaffClient from "Api/StaffClient";
import {Box} from "@material-ui/core";
import IAppStateModel from "Types/AppState";
import Grid from "@material-ui/core/Grid";
import {SetNotifications} from "State/Actions/ProfileActions";
import Pagination from "Components/Shared/Pagination";
import InterviewCard from "Components/Views/StaffNotifications/components/interviewCard";
import NotificationsSearch from "Components/Views/StaffNotifications/components/notificationsSearch";
const StaffNotifications = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const role = useSelector((state: IAppStateModel) => state.profile?.role)
    const [results, setResults] = useState<INotification[]>([]);
    const client = new StaffClient();
    const {t} = useTranslation("notifications")
    const [count, setCount] = useState<number>(0);
    const [search, setSearch] = useState<IPagedSearch<NotificationSearch>>({
        limit: 10,
        offset: 0,
        search: {
            code: undefined,
            imei: '',
            scooterCode: '',
            from: null,
            to: null
        }
    });
    const refreshAsync = async (info:  IPagedSearch<NotificationSearch>) => {
        dispatch(SetIsLoading(true))
        let res = await client.SearchNotifications(info);
        if (res.succeeded) {
            setResults(res.data?.matches);
            setCount(res.data?.total)
            dispatch(SetNotifications(res.data?.unReadNotificationsCount as number))
        } else {

        }
        dispatch(SetIsLoading(false))
    }

    const markAsRead = async (id: number) => {
        dispatch(SetIsLoading(true))
        let res = await client.MarkAsRead(id);
        if (res.succeeded) {
            await refreshAsync(search);
        } else {
            dispatch(ShowAlert(t("somethingWentWrong"), "error"));
        }
        dispatch(SetIsLoading(false))
    }

    const markAllAsRead = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.MarkAllAsRead();
        if (res.succeeded) {
            await refreshAsync(search);
        } else {
            dispatch(ShowAlert(t("somethingWentWrong"), "error"));
        }
        dispatch(SetIsLoading(false))
    }

    useEffect(() => {
        refreshAsync(search).then();
    }, [])

    return (
        <>
            <NotificationsSearch search={search}
                            isExportHidden={false}
                            setSearch={(e) => setSearch(e)}
                            onRefresh={async () => await refreshAsync(search)}
                            onClear={async () => {
                                const clearState = {...search, search: {code: undefined, imei: '', scooterCode: '', from: null, to: null}};
                                setSearch(clearState)
                                await refreshAsync(clearState)
                            }}
                            onMarkAllAsRead={markAllAsRead}
            />
            {
                results.map((x, index) => {
                    return (
                        <Box key={index} width='100%' paddingX='0.25rem' onClick={ () => {}}>
                            <Grid>
                                <Grid container xs={12} className={classes.container}>
                                    <Grid item container md={10} xs={12}>
                                        <Grid item xs={12}>
                                            <InterviewCard
                                                notification={x}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                    )
                })
            }
            <Box sx={{ m: 5 }} />

            <Grid
                item
                container
                xs={12}
                justifyContent="center">
                <Pagination
                    limit={search.limit}
                    offset={search.offset}
                    total={count}
                    onChangePageNumber={async (limit: number, offset: number) => {
                        const newState = {...search, offset: offset}
                        setSearch(newState)
                        await refreshAsync(newState)
                    }}
                />
            </Grid>

        </>
    );
}

export default StaffNotifications;


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        },
        activateButton: {
            width: '100px',
            color: 'white',
            backgroundColor: '#99C455'
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        container: {
            marginTop: '1rem',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: 1
        }
    }),
);

