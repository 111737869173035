import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core/styles";
import {parseDate} from "Utils/dateUtils";
import React, {useEffect, useState} from "react";
import IPagedResults, {IPagedSearch} from "Types/Paged";
import {useDispatch} from "react-redux";
import {SetIsLoading, ShowAlert} from "State/Actions/UiActions";
import {useTranslation} from "react-i18next";
import SharedTable from "Components/Shared/SharedTable";
import {Button, Paper, Typography} from "@material-ui/core";
import VouchersClient from "Api/VouchersClient";
import {ICreateVoucherModal, IVouchers} from "Types/Vouchers";
import VouchersSearch from "Components/Views/Vouchers/Components/VouchersSearch";

const Vouchers = () => {
    const classes = useStyles();

    const [search, setSearch] = useState<IPagedSearch<{ code: string }>>({
        limit: 25,
        offset: 0,
        search: {
            code: ''
        }
    });
    const [results, setResults] = useState<IPagedResults<IVouchers>>();
    const {t} = useTranslation("promoCodes")
    let client = new VouchersClient();
    const dispatch = useDispatch();
    const [modal, setModal] = useState<{ type: "ADD" | "EDIT" | "NONE", values?: ICreateVoucherModal }>({
        type: "NONE",
        values: undefined
    })

    const refresh = async (info:  IPagedSearch<{code: string}>) => {
        dispatch(SetIsLoading(true))
        let res = await client.SearchAsync(info);
        if (res.succeeded) {
            setResults(res.data);
        } else {
            dispatch(ShowAlert(t("somethingWentWrong"), "error"));
        }
        dispatch(SetIsLoading(false))
    }

    useEffect(() => {
        refresh(search).then();
    }, [search.limit, search.offset])
    const columns = [
        {
            field: 'id',
            headerName: t('Id'),
            width: 150,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('Id')}
                    </Typography>
                )
            },
        },
        {
            field: 'code',
            headerName: t('code'),
            width: 150,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('code')}
                    </Typography>
                )
            },

        },
        {
            field: 'numberOfUsages',
            headerName: t('usageLimit'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('usageLimit')}
                    </Typography>
                )
            },

        },
        {
            field: 'totalUsage',
            headerName: t('totalUsage'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('totalUsage')}
                    </Typography>
                )
            },

        },
        {
            field: 'value',
            headerName: t('value'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    params.row.value + " SAR"
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('value', {ns: 'vouchers'})}
                    </Typography>
                )
            },

        },
        {
            field: 'expiryDate',
            headerName: t('expiredOn'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row.expiryDate, true)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('expiredOn')}
                    </Typography>
                )
            },

        },
        {
            field: 'createdOn',
            headerName: t('createdOn'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row.createdOn, true)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('createdOn')}
                    </Typography>
                )
            },
        },
        {
            field: 'actions',
            headerName: t('actions'),
            width: 300,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                            <Button onClick={() => {
                                setModal({
                                    type: 'EDIT', values: {
                                        code: params.row.code,
                                        value: params.row.value,
                                        usageLimit: params.row.numberOfUsages,
                                        expiryDate: params.row.expiryDate
                                    }
                                })
                            }} className={classes.editButton} variant="contained" color="primary">
                                {t("edit", {ns: 'scooters'})}
                            </Button>
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('actions')}
                    </Typography>
                )
            },
        }
    ];


    return (
        <>
            <VouchersSearch
                modal={modal}
                setModal={setModal}
                search={search}
                onRefresh={async () => await refresh(search)}
                setSearch={(e) => setSearch(e)}
                onClear={async () => {
                    setSearch({...search, search: {code: ''}})
                    await refresh({...search, search: {code: ''}})
                }}
            />
            <Paper elevation={2} style={{marginTop: '2rem', width: '100%', height: '50vh'}}>
                <SharedTable
                    columns={columns}
                    rows={results?.matches ?? []}
                    pageSize={search?.limit}
                    onPageChange={(i) => setSearch((s) => ({...s, offset: (i) * s.limit}))}
                    onPageSizeChange={(i) => setSearch((s) => ({...s, limit: i}))}
                    rowCount={results?.total}
                    page={Math.ceil(search?.offset / search?.limit)}
                    getRowId={(i: any) => {
                        return i.id
                    }}
                />
            </Paper>
        </>
    );
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        },
        editButton: {
            width: '50px',
            color: 'white',
            backgroundColor: '#99C455'
        },
    }),
);
export default Vouchers
