import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core/styles";
import {parseDate} from "Utils/dateUtils";
import React, {useEffect, useState} from "react";
import {IPagedSearch} from "../../../Types/Paged";
import {IRide} from "../../../Types/Rides";
import {useDispatch} from "react-redux";
import {SetIsLoading} from "../../../State/Actions/UiActions";
import {useTranslation} from "react-i18next";
import PromoCodesClient from "../../../Api/PromoCodesClient";
import ActionsModal from "./components/ActionsModal";
import PromoCodesSearch from "Components/Views/PromoCodes/components/PromoCodesSearch";
import SharedTable from "Components/Shared/SharedTable";
import {Paper, Typography} from "@material-ui/core";

const PromoCodes = () => {
    const classes = useStyles();

    const [search, setSearch] = useState<IPagedSearch<{ searchText?: string }>>({
        limit: 25,
        offset: 0
    });
    const [results, setResults] = useState<IRide[]>([]);
    const [count, setCount] = useState<number>(0);
    const {t} = useTranslation("promoCodes")
    const [actionModal, setActionModal] = useState(false)
    let client = new PromoCodesClient();
    const dispatch = useDispatch();
    const refresh = async (clear?: boolean) => {
        dispatch(SetIsLoading(true))
        let res = await client.SearchAsync(clear ? {
            ...search, search: {
                searchText: ""
            }
        } : search);
        if (res.succeeded) {
            setResults(res.data?.matches);
            setCount(res.data?.total)
        } else {

        }
        dispatch(SetIsLoading(false))

    }

    useEffect(() => {
        refresh().then();
    }, [search.limit, search.offset])

    const columns = [
        {
            field: 'id',
            headerName: t('Id'),
            width: 150,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('Id')}
                    </Typography>
                )
            },
        },
        {
            field: 'code',
            headerName: t('promoCode'),
            width: 150,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('promoCode')}
                    </Typography>
                )
            },

        },
        {
            field: 'usageLimit',
            headerName: t('usageLimit'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('usageLimit')}
                    </Typography>
                )
            },

        },
        {
            field: 'totalUsage',
            headerName: t('totalUsage'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('totalUsage')}
                    </Typography>
                )
            },

        },
        {
            field: 'discount',
            headerName: t('discount'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    params.row.discountPercentage + "%"
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('discount')}
                    </Typography>
                )
            },

        },
        {
            field: 'packageIds',
            headerName: t('Packages Ids'),
            width: 300,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    params.row.packagesIds?.toString()
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('Packages Ids')}
                    </Typography>
                )
            },
        },
        {
            field: 'expiryDate',
            headerName: t('expiredOn'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row.expiryDate, true)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('expiredOn')}
                    </Typography>
                )
            },

        },
        {
            field: 'createdOn',
            headerName: t('createdOn'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row.createdOn, true)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('createdOn')}
                    </Typography>
                )
            },
        }
    ];


    return (
        <>
            {actionModal && <ActionsModal onClose={() => {
                setActionModal(false)
                refresh()
            }}/>}
            <PromoCodesSearch
                search={search}
                setSearch={(e) => setSearch(e)}
                onRefresh={(e?: boolean) => refresh(e)}
                onClear={() => {
                    setSearch({...search, search: undefined})
                    refresh(true)
                }}
            />
            <Paper elevation={2} style={{marginTop: '2rem', width: '100%', height: '50vh'}}>
                <SharedTable
                    columns={columns}
                    rows={results ?? []}
                    pageSize={search?.limit}
                    onPageChange={(i) => setSearch((s) => ({...s, offset: (i) * s.limit}))}
                    onPageSizeChange={(i) => setSearch((s) => ({...s, limit: i}))}
                    rowCount={count}
                    page={Math.ceil(search?.offset / search?.limit)}
                    getRowId={(i: any) => {
                        return i.id
                    }}
                />
            </Paper>
        </>
    );
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        }
    }),
);
export default PromoCodes
