import React from "react";
import Grid from "@material-ui/core/Grid";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { IPagedSearch } from "Types/Paged";
import SearchIcon from "@material-ui/icons/Search";
import SharedTextInput from "Components/Shared/TextInput";
import SharedDatePicker from "Components/Shared/DatePicker/datePicker";
import SharedButton from "Components/Shared/Button";
import { SubsicriptionSearchType } from "Types/SubsicriptionType";

interface IProps {
  search: IPagedSearch<SubsicriptionSearchType>;
  setSearch: (e: any) => void;
  onClear: () => void;
  onSearch: () => void;
  isExportHidden?: boolean;
}

const SubsicriptionSearch = ({
  search,
  setSearch,
  onSearch,
  onClear,
  isExportHidden,
}: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation("subsicription");

  return (
    <Paper className={classes.searchBox}>
      <Grid
        className={classes.searchBoxHeader}
        container
        item
        justifyContent={"space-between"}
      >
        <Grid className={classes.searchBoxHeader} item>
          <Typography className={classes.searchText}>{t("Search")}</Typography>
          <SearchIcon className={classes.searchIcon} fontSize={"large"} />
        </Grid>
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <Grid item md={2} xs={12} style={{ margin: "1rem" }}>
          <SharedTextInput
            value={search.search?.mobile ?? ""}
            onChange={(e: any) =>
              setSearch({
                ...search,
                search: {
                  ...search.search,
                  mobile: e,
                },
              })
            }
            label={t("mobile")}
          />
        </Grid>
        <Grid item md={2} xs={12} style={{ margin: "1rem" }}>
          <SharedTextInput
            value={search.search?.packageId ?? ""}
            onChange={(e: any) =>
              setSearch({
                ...search,
                search: {
                  ...search.search,
                  packageId: e,
                },
              })
            }
            label={t("packageId")}
          />
        </Grid>

        <Grid item md={2} xs={12} style={{ margin: "1rem" }}>
          <SharedDatePicker
            value={search.search?.from}
            handleChange={(e: any) =>
              setSearch({
                ...search,
                search: {
                  ...search.search,
                  from: e,
                },
              })
            }
            label="Created From"
          />
        </Grid>
        <Grid item md={2} xs={12} style={{ margin: "1rem" }}>
          <SharedDatePicker
            value={search.search?.to}
            handleChange={(e: any) =>
              setSearch({
                ...search,
                search: {
                  ...search.search,
                  to: e,
                },
              })
            }
            label="Created To"
          />
        </Grid>
      </Grid>
      <Grid container item justifyContent={"flex-end"}>
        <SharedButton
          className={` ${classes.redChip} ${classes.Chip}`}
          title={t("clear")}
          onClick={onClear}
          variant={"contained"}
        />
        <SharedButton
          className={`${classes.Chip} ${classes.greenChip}`}
          title={t("search")}
          onClick={onSearch}
          variant={"contained"}
        />
      </Grid>
    </Paper>
  );
};

export default SubsicriptionSearch;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBox: {
      padding: "1rem",
      display: "flex",
      width: "100%",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginTop: "1rem",
    },
    searchBoxHeader: {
      display: "flex",
      flexDirection: "row",
    },
    searchText: {
      fontWeight: "bold",
      fontSize: "2rem",
      color: theme.palette.primary.dark,
    },
    Chip: {
      color: "white",
    },
    redChip: {
      backgroundColor: "red",
      marginRight: 5,
    },
    greenChip: {
      backgroundColor: theme.palette.primary.main,
    },
    searchBar: {
      backgroundColor: "white",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
      marginTop: 15,
    },
    block: {
      display: "block",
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    excelIcon: {
      color: theme.palette.primary.main,
      marginTop: 5,
      cursor: "pointer",
    },
    searchIcon: {
      marginTop: "0.5rem",
      color: theme.palette.primary.dark,
      marginLeft: "0.5rem",
    },
    paper: {
      maxWidth: 1200,
      margin: "auto",
      overflow: "hidden",
    },
    icon: {
      cursor: "pointer",
    },
    currentBalanceContainer: { display: "flex", flexDirection: "row" },
    currentBalanceHeader: {
      color: "#000",
      opacity: 0.87,
      fontSize: "14px",
      padding: 0,
    },
  })
);
