import React, {Dispatch, FunctionComponent, SetStateAction, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import IPagedResults, {IPagedSearch} from "Types/Paged";
import {SetIsLoading, ShowAlert} from "State/Actions/UiActions";
import {useDispatch} from "react-redux";
import {IRide, IRideSearch} from "Types/Rides";
import RidesClient from "Api/RidesClient";
import SharedRides from "Components/Shared/SharedRides";

interface IProps {
    open: boolean
    onClose: () => void;
    modal?: boolean;
    search: IPagedSearch<IRideSearch>;
    setSearch: Dispatch<SetStateAction<IPagedSearch<IRideSearch>>>;
}

const CustomerRidesModal: FunctionComponent<IProps> = ({open, onClose, modal, search, setSearch}) => {
    const client = new RidesClient();
    const [results, setResults] = useState<IPagedResults<IRide>>();
    const dispatch = useDispatch();

    const {t} = useTranslation("rides")
    const refresh = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.SearchAsync(search);
        if (res.succeeded) {
            setResults(res.data);
        } else {
            dispatch(ShowAlert(t("somethingWentWrong"), "error"));
            onClose()
        }
        dispatch(SetIsLoading(false))
    }
    useEffect(() => {
        refresh().then();
    }, [search.limit, search.offset])


    return (
        <SharedRides
            modal={modal}
            open={open}
            onClose={onClose}
            search={search}
            setSearch={setSearch}
            results={results}
        />
    )
}
export default CustomerRidesModal;
