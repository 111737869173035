import React, { FunctionComponent, useCallback, useState } from "react";
import { Box, Modal, TextField } from "@material-ui/core";
import { IUpdateUserModel } from "Types/Users";
import UsersClient from "Api/UsersClient";
import { useDispatch } from "react-redux";
import { SetIsLoading, ShowAlert } from "State/Actions/UiActions";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import PaymentTransactions from "Components/Views/Users/Components/PaymentTransactions";
import SharedButton from "Components/Shared/Button";
import CustomerRidesModal from "Components/Views/Rides/Components/CustomerRidesModal";
import Palette from "Theme/palette";
import RidesClient from "Api/RidesClient";
import { IPagedSearch } from "Types/Paged";
import { IRideSearch } from "Types/Rides";
import { RiFileExcel2Fill } from "react-icons/all";

interface IProps {
  userId: number;
  open: boolean;
  onClose: () => void;
  name: string;
  mobile: string;
  balance: number;
}

const UserInfoModal: FunctionComponent<IProps> = ({
  userId,
  open,
  onClose,
  name,
  mobile,
  balance,
}) => {
  const { t } = useTranslation(["users", "rides"]);
  const classes = useStyles();
  const [table, setTable] = useState<"Payments" | "Rides">("Rides");
  const [updatedData, setUpdatedData] = useState<IUpdateUserModel>({
    balance: balance,
    discount: 0,
  });
  const [rideSearch, setRideSearch] = useState<IPagedSearch<IRideSearch>>({
    limit: 25,
    offset: 0,
    search: {
      userId: userId,
    },
  });

  const [paymentSearch, setPaymentSearch] = useState<
    IPagedSearch<{ userId: number }>
  >({
    limit: 25,
    offset: 0,
    search: {
      userId: userId,
    },
  });

  const dispatch = useDispatch();
  let client = new UsersClient();
  let ridesClient = new RidesClient();

  const onUpdate = useCallback(async () => {
    dispatch(SetIsLoading(true));

    let res = await client.UpdateAsync(updatedData, userId);
    if (res.succeeded) {
      onClose();
    }
    dispatch(SetIsLoading(false));
  }, [dispatch, userId, updatedData]);

  const onExport = async () => {
    dispatch(SetIsLoading(true));
    let res =
      table === "Payments"
        ? await client.ExportPayments(paymentSearch)
        : await ridesClient.ExportToExcelAsync(rideSearch);
    dispatch(SetIsLoading(false));
    if (res.succeeded) {
      window.open(res.data.location, "_blank", "noreferrer");
    }
  };

  return (
    <div className={classes.main}>
      <Modal open={open} className={classes.modal} onClose={onClose}>
        <div className={classes.paper}>
          <Box>
            <h2>{name}</h2>
          </Box>
          <Box>
            <h2>{mobile}</h2>
          </Box>

          <Box my={5} width={"100%"} display={"flex"}>
            <TextField
              style={{ marginInline: 5 }}
              required
              type={"number"}
              label={t("balance")}
              fullWidth
              value={updatedData.balance ?? 0}
              onChange={(e) =>
                setUpdatedData({
                  ...updatedData,
                  balance: Number(e.target.value),
                })
              }
            />
            <TextField
              style={{ marginInline: 5 }}
              required
              type={"number"}
              label={t("discount")}
              fullWidth
              value={updatedData.discount ?? 0}
              onChange={(e) =>
                setUpdatedData({
                  ...updatedData,
                  discount: Number(e.target.value),
                })
              }
            />
          </Box>
          <Box className={classes.buttonsContainer}>
            <Box>
              <SharedButton
                className={`${classes.Chip}`}
                title={t("Rides")}
                customStyle={{
                  backgroundColor:
                    table === "Rides"
                      ? Palette.primary.main
                      : Palette.primary.extraLight,
                }}
                onClick={() => {
                  setTable("Rides");
                }}
                variant={"contained"}
              />
              <SharedButton
                className={`${classes.Chip}`}
                customStyle={{
                  backgroundColor:
                    table === "Payments"
                      ? Palette.primary.main
                      : Palette.primary.extraLight,
                }}
                title={t("Payments")}
                onClick={() => {
                  setTable("Payments");
                }}
                variant={"contained"}
              />
            </Box>

            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <RiFileExcel2Fill
                onClick={onExport}
                className={classes.excelIcon}
                size={30}
              />
            </Box>
          </Box>
          {table === "Rides" && (
            <CustomerRidesModal
              open={table === "Rides"}
              onClose={() => {}}
              search={rideSearch}
              setSearch={setRideSearch}
            />
          )}
          {table === "Payments" && (
            <PaymentTransactions
              open={table === "Payments"}
              onClose={() => {}}
              userId={userId}
              setPaymentSearch={(value, key) => setPaymentSearch({ ...paymentSearch, [key]: value })}
            />
          )}

          <Box
            width={"100%"}
            mt={2}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <Button
              onClick={onUpdate}
              variant="contained"
              color="primary"
              className={classes.addUser}
            >
              {t("save")}
            </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
      padding: "3%",
      alignSelf: "center",
    },
    main: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 21,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      minWidth: "1000px",
      overflow: "scroll",
      padding: theme.spacing(2, 4, 3),
      maxHeight: 800,
    },
    Chip: {
      color: "white",
      margin: "0 5px",
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    excelIcon: {
      color: theme.palette.primary.main,
      marginTop: 5,
      cursor: "pointer",
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  })
);
export default React.memo(UserInfoModal);
