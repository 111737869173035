import React, {useEffect, useState} from 'react';
import {createStyles, Theme} from '@material-ui/core/styles';
import {makeStyles} from "@material-ui/styles";
import {IPagedSearch} from "Types/Paged";
import UsersClient from "../../../Api/UsersClient";
import {useDispatch, useSelector} from "react-redux";
import {SetIsLoading} from "State/Actions/UiActions";
import {IUserModel, IUserSearchModel, SortType} from "Types/Users";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {Avatar, Box, Button, Paper, Typography} from "@material-ui/core";
import UserInfoModal from "./UserInfoModal";
import {ArrowDownward, Money} from "@material-ui/icons";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Authorized from "Components/Shared/Authorized";
import {Admin, CustomerService, Operation} from "Types/JobRole";
import UsersSearch from "Components/Views/Users/Components/UsersSearch";
import IAppStateModel from "Types/AppState";
import SharedTable from "Components/Shared/SharedTable";


const Component = () => {
    let client = new UsersClient();
    const role = useSelector((state: IAppStateModel) => state.profile?.role)
    const classes = useStyles();
    const {t} = useTranslation('users')
    const [user, setUser] = useState({name: '', mobile: '', id: 0, balance: 0})
    const [openModal, setOpenModal] = useState<"none" | "transactions">("none")
    const [search, setSearch] = useState<IPagedSearch<IUserSearchModel>>({
        limit: 25,
        offset: 0,
        search: {
            userName: "",
            mobile: "",
            from: null,
            to: null,
            sortType: null
        }
    });

    const [count, setCount] = useState<number>(0);
    const [results, setResults] = useState<IUserModel[]>([]);
    const dispatch = useDispatch();
    const refresh = async (obj?: IPagedSearch<IUserSearchModel>) => {
        dispatch(SetIsLoading(true))
        let res = await client.SearchAsync(obj ?? search);
        if (res.succeeded) {
            setResults(res.data?.matches);
            setCount(res.data?.total)
        }
        dispatch(SetIsLoading(false))
    }

    useEffect(() => {
        refresh().then();
    }, [search.limit, search.offset, search.search?.sortType])
    const columns = [
        {
            field: 'profilePictureUrl',
            headerName: t('profilePicture'),
            width: 100,
            sortable: false,
            renderCell: (params: any) => {
                return (
                    <>
                        {/*<img src={params.row?.profilePictureUrl} alt={"pfp"} width={50} style={{borderRadius: 100}}/>*/}
                        <Avatar style={{width: 46, height: 46}}/>
                    </>
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('profilePicture')}
                    </Typography>
                )
            },
        },
        {
            field: 'id',
            headerName: t('idHeader'),
            width: 100,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('idHeader')}
                    </Typography>
                )
            },
        },

        {
            field: 'name',
            headerName: t('nameHeader'),
            width: 150,
            sortable: false,
            renderCell: (params: any) => {
                return (
                    params.row?.name
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('nameHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'currentBalance',
            headerName: t('currentBalance'),
            width: 250,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: (params: any) => {
                return (
                    <>
                        <Box className={classes.currentBalanceContainer}><Typography
                            className={classes.tableHeader}>{t("currentBalance")}</Typography>
                            {(search.search?.sortType == null || search.search?.sortType === SortType.Asc) &&
                                <ArrowUpwardIcon className={classes.icon} onClick={() => {
                                    setSearch({
                                        ...search,
                                        search: {
                                            ...search,
                                            sortType: SortType.Desc,
                                            userName: search.search?.userName ?? '',
                                            mobile: search.search?.mobile ?? ''
                                        }
                                    })
                                }}/>}
                            {(search.search?.sortType === SortType.Desc) &&
                                <ArrowDownward className={classes.icon} onClick={() => {
                                    setSearch({
                                        ...search,
                                        search: {
                                            ...search,
                                            sortType: SortType.Asc,
                                            userName: search.search?.userName ?? '',
                                            mobile: search.search?.mobile ?? ''
                                        }
                                    })
                                }}/>}
                        </Box>
                    </>
                )
            },
        },
        {
            field: 'discount',
            headerName: t('discount'),
            width: 150,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('discount')}
                    </Typography>
                )
            },
        },
        {
            field: 'mobile',
            headerName: t('mobileHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('mobileHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'numberOfPackages',
            headerName: t('numberOfPackages'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('numberOfPackages')}
                    </Typography>
                )
            },

        },
        {
            field: 'createdOn',
            headerName: t('createdOn'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    dayjs(params.row.createdOn).format("DD-MM-YYYY HH:mm:ss")
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('createdOnHeader', {ns: 'rides'})}
                    </Typography>
                )
            },

        },
        {
            field: 'actions',
            headerName: t('actions'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            hide: role === Operation.value,
            renderCell: (params: any) => {
                return (
                    <Authorized allowedRoles={[Admin.value, CustomerService.value]}>
                        <Button onClick={() => {
                            setUser({
                                id: params.row.id,
                                name: params.row.name,
                                mobile: params.row.mobile,
                                balance: params.row.currentBalance
                            })
                            setOpenModal("transactions")
                        }}> <Money
                            className={classes.block}
                            color="inherit"/>

                        </Button>
                    </Authorized>

                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('actions', {ns: 'rides'})}
                    </Typography>
                )
            },
        },
    ];

    return (
        <>
            {
                openModal === "transactions" &&
                <UserInfoModal
                    onClose={() => {
                        setOpenModal("none")
                        refresh().then();
                    }} open={openModal === "transactions"}
                    name={user.name}
                    balance={user.balance}
                    mobile={user.mobile}
                    userId={user.id}/>

            }
            <UsersSearch onSearch={() => refresh()} search={search} setSearch={(e) => setSearch(e)} onClear={() => {
                const obj = {
                    ...search, search: {
                        userName: "",
                        mobile: "",
                        from: null,
                        to: null,
                        sortType: null
                    }
                }
                setSearch(obj)
                refresh(obj).then()
            }}/>
            <Paper elevation={2} style={{marginTop: '2rem', width: '100%', height: '50vh', padding: "1rem"}}>
                <SharedTable
                    columns={columns}
                    rows={results ?? []}
                    pageSize={search?.limit}
                    onPageChange={(i) => setSearch((s) => ({...s, offset: (i) * s.limit}))}
                    onPageSizeChange={(i) => setSearch((s) => ({...s, limit: i}))}
                    rowCount={count}
                    page={Math.ceil(search?.offset / search?.limit)}
                    getRowId={(i: any) => {
                        return i.id
                    }}
                />
            </Paper>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: 1200,
            margin: 'auto',
            overflow: 'hidden',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        Chip: {
            color: "white"
        },
        redChip: {
            backgroundColor: "red"
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        contentWrapper: {
            margin: '40px 16px',
        },
        icon: {
            cursor: 'pointer'
        },
        currentBalanceContainer: {display: 'flex', flexDirection: 'row'},
        currentBalanceHeader: {color: "#000", opacity: 0.87, fontSize: '14px', padding: 0},
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        }
    }),
);


export default Component;
