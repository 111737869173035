import React from 'react'
import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import {useTranslation} from "react-i18next";
import {IPagedSearch} from "Types/Paged";
import {ILogsSearchModel} from "Types/Logs";
import {Paper, Typography} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {RiFileExcel2Fill} from "react-icons/all";
import SharedTextInput from "Components/Shared/TextInput";
import SharedButton from "Components/Shared/Button";
import SharedDatePicker from "Components/Shared/DatePicker/datePicker";
import {SetIsLoading} from "State/Actions/UiActions";
import ScootersClient from "Api/ScootersClient";
import {useDispatch} from "react-redux";

interface IProps {
    search: IPagedSearch<ILogsSearchModel>;
    setSearch: (e: any) => void;
    isExportHidden?: boolean;
    onSearch: () => Promise<void>
    onClear: () => void;
}

const LogsSearch = ({search, setSearch, onSearch, onClear, isExportHidden = true}: IProps) => {
    const classes = useStyles();
    const {t} = useTranslation('rides')
    const client = new ScootersClient();
    const dispatch = useDispatch();

    const exportToExcel = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.ExportLogs(search);
        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer")
        } else {

        }
        dispatch(SetIsLoading(false))
    }

    return (
        <Paper className={classes.searchBox}>
            <Grid className={classes.searchBoxHeader} container item justifyContent={'space-between'}>
                <Grid className={classes.searchBoxHeader} item>
                    <Typography className={classes.searchText}>
                        {t('Search')}
                    </Typography>
                    <SearchIcon className={classes.searchIcon} fontSize={'large'}/>
                </Grid>
                {!isExportHidden && <Grid item style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <RiFileExcel2Fill
                        onClick={exportToExcel}
                        className={classes.excelIcon} size={30}/>
                </Grid>}
            </Grid>
            <Grid container item justifyContent={'flex-start'}>
                <Grid item md={3} xs={12} style={{margin: '1rem'}}>
                    <SharedTextInput
                        value={search.search?.scooterId ?? ''}
                        onChange={(e: any) => setSearch({
                            ...search,
                            search: {
                                ...search.search,
                                scooterId: e,
                            }
                        })}
                        label={t('search')}
                    />
                </Grid>
                <Grid item md={2} xs={12} style={{margin: '1rem'}}>
                    <SharedDatePicker
                        value={search.search?.from}
                        handleChange={(e: any) => setSearch({
                            ...search,
                            search: {
                                ...search.search,
                                from: e,
                            }
                        })}
                        label="Created From"
                    />
                </Grid>
                <Grid item md={2} xs={12} style={{margin: '1rem'}}>
                    <SharedDatePicker
                        value={search.search?.to}
                        handleChange={(e: any) => setSearch({
                            ...search,
                            search: {
                                ...search.search,
                                to: e,
                            }
                        })}
                        label="Created To"
                    />
                </Grid>
            </Grid>
            <Grid container item justifyContent={'flex-end'}>
                <SharedButton
                    className={` ${classes.redChip} ${classes.Chip}`}
                    title={t("clear")}
                    onClick={onClear}
                    variant={'contained'}
                />
                <SharedButton
                    className={`${classes.Chip} ${classes.greenChip}`}
                    title={t("search")}
                    onClick={onSearch}
                    variant={'contained'}
                />
            </Grid>
        </Paper>
    )
}

export default LogsSearch;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchBox: {
            padding: "1rem",
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginTop: '1rem'
        },
        searchBoxHeader: {
            display: 'flex', flexDirection: 'row'
        },
        searchText: {
            fontWeight: 'bold',
            fontSize: '2rem',
            color: theme.palette.primary.dark
        },
        Chip: {
            color: "white"
        },
        redChip: {
            backgroundColor: "red",
            marginRight: 5
        },
        greenChip: {
            backgroundColor: theme.palette.primary.main
        },
        searchBar: {
            backgroundColor: 'white',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
            marginTop: 15
        },
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        excelIcon: {
            color: theme.palette.primary.main,
            marginTop: 5,
            cursor: 'pointer'
        },
        searchIcon: {
            marginTop: '0.5rem', color: theme.palette.primary.dark, marginLeft: '0.5rem'
        }
    }),
);
