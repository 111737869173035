import {Pagination} from "@material-ui/lab";

interface PaginationProps {
    limit: number;
    total: number;
    pageSize?: number;
    offset: number;
    onChangePageNumber: (limit: number, offset: number) => void;
    onChangePageSize?: (event: React.ChangeEvent<unknown>, value: number) => void;
}


const SharedPagination = ({ total, onChangePageNumber, limit, offset }: PaginationProps) => {
    return (
        <Pagination
            color="primary"
            count={Math.ceil(total / limit)}
            page={Math.ceil(offset / limit)+1}
            variant="outlined"
            onChange={(_: unknown, page: number) => {
                onChangePageNumber(limit, (page-1)*limit )}}
        />
    );
};

export default SharedPagination;
