import React, {useEffect, useState} from 'react';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import AnalyticsClient from "Api/AnalyticsClient";
import {SetIsLoading} from "State/Actions/UiActions";
import {useDispatch} from "react-redux";
import {unCamelCase} from "Utils/general";
import Grid from "@material-ui/core/Grid";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core/styles";
import SharedDatePicker from "Components/Shared/DatePicker/datePicker";
import SharedButton from "Components/Shared/Button";
import {useTranslation} from "react-i18next";
import {RiFileExcel2Fill} from "react-icons/all";

ChartJS.register(ArcElement, Tooltip, Legend);

const UsersActivity = () => {
    const client = new AnalyticsClient()
    const dispatch = useDispatch();
    const classes = useStyles();
    const [results, setResults] = useState<any>([]);
    const [date, setDate] = useState(null)
    const {t} = useTranslation('scooters')
    const data = {
        labels: Object.keys(results)?.map(x => unCamelCase(x)) ?? [],
        datasets: [
            {
                label: '# of Votes',
                data: Object.values(results) ?? [],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const refresh = async (date: string | null) => {
        dispatch(SetIsLoading(true))
        let res = await client.UsersActivity(date);
        if (res.succeeded) {
            setResults(res.data);
        } else {

        }
        dispatch(SetIsLoading(false))

    }

    const exportToExcel = async (date: string | null) => {
        dispatch(SetIsLoading(true))
        let res = await client.UsersActivity(date, true);
        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer")
        }
        dispatch(SetIsLoading(false))
    }

    useEffect(() => {
        refresh(date).then();
    }, [])
    return (

        <Grid container xs={12}>
            <Box className={classes.container}>
                <RiFileExcel2Fill onClick={() => exportToExcel(date)} className={classes.excelIcon} size={30}/>
                <Box className={classes.doughnutFilter}>
                    <Typography style={{fontSize: '24px', fontWeight: '600', paddingTop: '1rem'}}>Users
                        Activity</Typography>
                    <Doughnut data={data}/>
                </Box>

                <Grid container justifyContent={'center'} alignItems={'center'} style={{flexDirection: 'column'}}>
                    <Grid item style={{marginBottom: 10}}>
                        <SharedDatePicker
                            value={date}
                            handleChange={(e: any) => setDate(e)}
                            label="Date"
                        />
                    </Grid>

                    <Grid container item justifyContent={'center'} alignItems={'center'} style={{marginBottom: 10}}>
                        <SharedButton
                            className={` ${classes.redChip} ${classes.Chip}`}
                            title={t("clear")}
                            onClick={async () => {
                                setDate(null)
                                await refresh(null)
                            }}
                            variant={'contained'}
                        />
                        <SharedButton
                            className={`${classes.Chip} ${classes.greenChip}`}
                            title={t("search")}
                            onClick={async () => {
                                await refresh(date)
                            }}
                            variant={'contained'}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
}

export default UsersActivity;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: 'white',
            width: '100%',
            height: '100%',
            borderRadius: '20px',
            padding: '10px',
            display: 'flex',
            position: 'relative'
        },
        doughnutFilter: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
        },
        Chip: {
            color: "white"
        },
        redChip: {
            backgroundColor: "red",
            marginRight: 5
        },
        greenChip: {
            backgroundColor: theme.palette.primary.main,
            marginRight: 5
        },
        excelIcon: {
            color: theme.palette.primary.main,
            marginTop: 5,
            cursor: 'pointer',
            position: 'absolute',
            right: 20,
            top: 20
        },
    }),
);
