import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import {Button} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            width: '400px'
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        button: {
            marginRight: '2px',
            marginLeft: '2px',
        },
    }),
);

interface IProps {
    title: string;
    message: string;
    visible: boolean;
    onSubmit: () => void;
    onClose: () => void;
}


const ConfirmationModal: React.FC<IProps> = ({title, message, onSubmit, visible, onClose}) =>  {
    const classes = useStyles();
    const {t} = useTranslation("confirmationModal");


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={visible}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">{title}</h2>
                        <p id="transition-modal-description">{message}</p>
                        <div className={classes.buttonContainer}>
                            <Button onClick={onClose} variant="contained">{t("cancel")}</Button>
                            <Button onClick={onSubmit} className={classes.button} variant="contained" color="primary">
                                {t("submit")}
                            </Button>

                        </div>
                    </div>
            </Modal>
        </div>
    );
}

export default ConfirmationModal;
