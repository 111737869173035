import { red } from "@material-ui/core/colors";

const typography = {
    h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
    },

};

export default typography;
