import React, {useEffect, useState} from 'react'
import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import {Paper, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {IPagedSearch} from "Types/Paged";
import {IScootersSearchModel, ScooterConfig} from "Types/Scooters";
import ScootersClient from "Api/ScootersClient";
import {SetIsLoading, ShowAlert} from "State/Actions/UiActions";
import {useDispatch} from "react-redux";
import AddIotDevice from "Components/Views/IotDevices/Components/AddIotDevice";
import ScooterConfigModal from "Components/Views/IotDevices/Components/ScooterConfig";
import SearchIcon from "@material-ui/icons/Search";
import {RiFileExcel2Fill} from "react-icons/all";
import SharedTextInput from "Components/Shared/TextInput";
import SharedDropdown from "Components/Shared/Dropdown";
import SharedButton from "Components/Shared/Button";
import AreasClient from "Api/AreasClient";

interface IProps {
    search: IPagedSearch<IScootersSearchModel>;
    setSearch: (e: any) => void;
    onRefresh: () => Promise<void>;
    isExportHidden?: boolean;
}

const IotDevicesSearch = ({search, setSearch, onRefresh, isExportHidden}: IProps) => {
    const classes = useStyles();
    const {t} = useTranslation('scooters')
    let client = new ScootersClient();
    let areaClient = new AreasClient();
    const dispatch = useDispatch();
    const [addOn, setAddOn] = useState(false);
    const [config, setConfig] = useState<ScooterConfig>({minimumChargePercentageToStart: 0, timeSinceLastRide: 24, timeSinceLastLog: 24})
    const [scooterConfig, setScooterConfig] = useState(false)
    const [areas, setAreas] = useState<Array<{label: string, value: string}>>([]);

    const onExport = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.ExportToExcelAsync(search);
        dispatch(SetIsLoading(false))
        if (res.succeeded) {
            window.open(res.data.location, "_blank", "noreferrer")
        }
    }

    const getAreas = async () => {
        dispatch(SetIsLoading(true))
        let res = await areaClient.SearchAsync(search);
        if (res.succeeded) {
            setAreas(res.data);
        } else {
            dispatch(ShowAlert(t("Could not fetch areas"), "error"));
        }
        dispatch(SetIsLoading(false))
    }


    const getConfig = async () => {
        dispatch(SetIsLoading(true))
        const res = await client.SearchConfigAsync()
        if (res.succeeded) {
            setConfig(res.data)
        }
        dispatch(SetIsLoading(false))
    }
    useEffect(() => {
        getConfig().then()
    }, [])

    useEffect(() => {
        getAreas().then()
    }, [])

    const statuses = [
        {
            label: 'Active',
            value: 11
        },
        {
            label: 'InRide',
            value: 21
        },
        {
            label: 'Inactive',
            value: 31
        },
        {
            label: 'UnderMaintenance',
            value: 41
        },
    ]

    const manufacturers = [
        {
            label: 'Kuickwheel',
            value: 1
        },
        {
            label: 'Hopthink',
            value: 2
        },
    ]

    const BatteryCharge = [
        {
            label: 'High',
            value: false
        },
        {
            label: 'Low',
            value: true
        },
    ]

    return (
        <>
            {addOn && <AddIotDevice onClose={() => setAddOn(false)}/>}
            {scooterConfig && <ScooterConfigModal initialValue={config} onClose={() => {
                setScooterConfig(false)
                getConfig().then()
            }}/>}
            <Paper className={classes.searchBox}>
                <Grid className={classes.searchBoxHeader} container item justifyContent={'space-between'}>
                    <Grid className={classes.searchBoxHeader} item>
                        <Typography className={classes.searchText}>
                            {t('Search')}
                        </Typography>
                        <SearchIcon className={classes.searchIcon} fontSize={'large'}/>
                    </Grid>
                    {!isExportHidden && <Grid item style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <RiFileExcel2Fill onClick={onExport} className={classes.excelIcon} size={30}/>
                    </Grid>}
                </Grid>
                <Grid container item justifyContent={'flex-start'}>
                    <Grid item md={2} xs={12} style={{margin: '1rem'}}>
                        <SharedTextInput
                            value={search.search?.id ?? ''}
                            onChange={(e: any) => {
                                setSearch({
                                    ...search,
                                    search: {
                                        ...search.search,
                                        id: e,
                                    }
                                })
                            }}
                            label={t('Id')}
                        />
                    </Grid>
                    <Grid item md={2} xs={12} style={{margin: '1rem'}}>
                        <SharedTextInput
                            value={search.search?.imei ?? ''}
                            onChange={(e: any) => {
                                setSearch({
                                    ...search,
                                    search: {
                                        ...search.search,
                                        imei: e,
                                    }
                                })
                            }}
                            label={t('Search by imei')}
                        />
                    </Grid>
                    <Grid item md={2} xs={12} style={{margin: '1rem'}}>
                        <SharedTextInput
                            label={t('Search by code')}
                            value={search.search?.code ?? ''}
                            onChange={(e: any) => {
                                setSearch({
                                    ...search,
                                    search: {
                                        ...search.search,
                                        code: e,
                                    }
                                })
                            }}
                        />
                    </Grid>
                    <Grid item md={2} xs={12} style={{margin: '1rem'}}>
                        <SharedDropdown
                            options={statuses}
                            label={t('status')}
                            value={search.search?.statusId ?? undefined}
                            onChange={(e: any) => {
                                setSearch({
                                    ...search,
                                    search: {
                                        ...search.search,
                                        statusId: e.target.value,
                                    }
                                })
                            }}
                        />
                    </Grid>
                    <Grid item md={2} xs={12} style={{margin: '1rem'}}>
                        <SharedDropdown
                            options={manufacturers}
                            label={t('manufacturer')}
                            value={search.search?.manufacturerId ?? undefined}
                            onChange={(e: any) => {
                                setSearch({
                                    ...search,
                                    search: {
                                        ...search.search,
                                        manufacturerId: e.target.value,
                                    }
                                })
                            }}
                        />
                    </Grid>
                    <Grid item md={2} xs={12} style={{margin: '1rem'}}>
                        <SharedDropdown
                            options={BatteryCharge}
                            label={t('batteryCharge')}
                            value={search.search?.isLowBattery ?? undefined}
                            onChange={(e: any) => {
                                setSearch({
                                    ...search,
                                    search: {
                                        ...search.search,
                                        isLowBattery: e.target.value,
                                    }
                                })
                            }}
                        />
                    </Grid>
                    <Grid item md={2} xs={12} style={{margin: '1rem'}}>
                        <SharedDropdown
                            options={areas ?? []}
                            label={t('area')}
                            value={search.search?.areaId ?? undefined}
                            onChange={(e: any) => {
                                setSearch({
                                    ...search,
                                    search: {
                                        ...search.search,
                                        areaId: Number(e.target.value),
                                    }
                                })
                            }}
                        />
                    </Grid>

                </Grid>
                <Grid container item justifyContent={'flex-end'}>
                    <SharedButton
                        className={` ${classes.redChip} ${classes.Chip}`}
                        title={t("clear")}
                        onClick={() => {
                            setSearch({
                                ...search, search:
                                    {
                                        manufacturerId: null,
                                        isLowBattery: undefined,
                                        statusId: undefined,
                                        code: '',
                                        imei: '',
                                        areaId: undefined
                                    }
                            })
                        }}
                        variant={'contained'}
                    />
                    <SharedButton
                        className={`${classes.Chip} ${classes.greenChip}`}
                        title={t("addTitle")}
                        onClick={() => setAddOn(true)}
                        variant={'contained'}
                    />
                    <SharedButton
                        className={`${classes.Chip} ${classes.scooterConfigChip}`}
                        title={t("scooterConfig")}
                        onClick={() => setScooterConfig(true)}
                        variant={'contained'}
                    />
                </Grid>
            </Paper>

        </>
    )
}

export default IotDevicesSearch;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchBox: {
            padding: "1rem",
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginTop: '1rem'
        },
        searchBoxHeader: {
            display: 'flex', flexDirection: 'row'
        },
        searchText: {
            fontWeight: 'bold',
            fontSize: '2rem',
            color: theme.palette.primary.dark
        },
        Chip: {
            color: "white"
        },
        redChip: {
            backgroundColor: "red",
            marginRight: 5
        },
        greenChip: {
            backgroundColor: theme.palette.primary.main,
            marginRight: 5
        },
        searchBar: {
            backgroundColor: 'white',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
            marginTop: 15
        },
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        excelIcon: {
            color: theme.palette.primary.main,
            marginTop: 5,
            cursor: 'pointer'
        },
        searchIcon: {
            marginTop: '0.5rem', color: theme.palette.primary.dark, marginLeft: '0.5rem'
        },
        scooterConfigChip: {
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.down("md")]: {
                marginTop: 5,
            }
        }
    }),
);
