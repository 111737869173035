import { makeStyles } from "@material-ui/styles";
import { createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { parseDate } from "Utils/dateUtils";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SetIsLoading, ShowAlert } from "State/Actions/UiActions";
import { useTranslation } from "react-i18next";
import PackagesClient from "Api/PackagesClient";
import { CreatePackageInitialValuesType, IPackage } from "Types/Packages";
import CreatePackageModal from "./modals/CreatePackageModal";
import { Button, Typography, Paper } from "@material-ui/core";
import SharedTable from "Components/Shared/SharedTable";
import SharedDropdown from "Components/Shared/Dropdown";

type modalTypes = 'CONFIRM' | 'ADD' | 'NONE'
const statuses = [
    { label: 'All', value: undefined },
    { label: 'Active', value: false },
    { label: 'Inactive', value: true }
]

const Packages = () => {
    const classes = useStyles();
    const [results, setResults] = useState<IPackage[]>();
    const { t } = useTranslation("packages")
    const [modal, setModal] = useState<modalTypes>('NONE');
    const [isDeactivated, setIsDeactivated] = useState<boolean | undefined>()
    const initialValues = {
        id: 0,
        price: 0,
        balanceValue: 0,
        createdOn: new Date(),
        type: 0,
        areaId: null
    }
    const [packageValues, setPackageValues] = useState<CreatePackageInitialValuesType>(initialValues);
    let client = new PackagesClient();
    const dispatch = useDispatch();

    const createPackage = async () => {
        dispatch(SetIsLoading(true))
        if (packageValues.type !== 0 && packageValues.areaId == null) {
            dispatch(ShowAlert("Please choose an area", "error"));
        }
        else {
            let res = await client.CreateAsync(packageValues);
            if (res.succeeded) {
                setModal('NONE')
                dispatch(ShowAlert(t("The package was created successfully"), "success"));
                refresh().then()
            } else {
                dispatch(ShowAlert(t("Something went wrong"), "error"));
            }
        }
        dispatch(SetIsLoading(false))
    }

    const activatePackages = async (id: number) => {
        dispatch(SetIsLoading(true))
        let res = await client.Activate(id);
        if (res.succeeded) {
            setModal('NONE')
            dispatch(ShowAlert(t("The package was activated successfully"), "success"));
            refresh().then()
        } else {
            dispatch(ShowAlert(t("Something went wrong"), "error"));
        }
        dispatch(SetIsLoading(false))
    }

    const deactivatePackages = async (id: number) => {
        dispatch(SetIsLoading(true))
        let res = await client.Deactivate(id);
        if (res.succeeded) {
            setModal('NONE')
            dispatch(ShowAlert(t("The package was deactivated successfully"), "success"));
            refresh().then()
        } else {
            dispatch(ShowAlert(t("Something went wrong"), "error"));
        }
        dispatch(SetIsLoading(false))
    }

    const refresh = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.SearchAsync(isDeactivated);
        if (res.succeeded) {
            setResults(res.data);
        } else {
            dispatch(ShowAlert(t("Something went wrong"), "error"));
        }
        dispatch(SetIsLoading(false))
    }
    useEffect(() => {
        refresh().then()
    }, [isDeactivated])

    const columns = [
        {
            field: 'id',
            headerName: t('Id'),
            width: 100,
            editable: false,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('Id')}
                    </Typography>
                )
            },
        },
        {
            field: 'price',
            headerName: t('price'),
            width: 200,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('price')}
                    </Typography>
                )
            },
        },
        {
            field: 'balanceValue',
            headerName: t('balanceValue'),
            width: 200,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('balanceValue')}
                    </Typography>
                )
            },
        },
        {
            field: 'minutes',
            headerName: t('minutes'),
            width: 200,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('minutes')}
                    </Typography>
                )
            },
        },
        {
            field: 'type',
            headerName: t('type'),
            width: 250,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('type')}
                    </Typography>
                )
            },
        },
        {
            field: 'createdOn',
            headerName: t('createdOn'),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row.createdOn, true)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('createdOn')}
                    </Typography>
                )
            },
        },
        {
            field: 'actions',
            headerName: t('actions'),
            width: 300,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                const isDeactivated = results?.find(x => x.id === params.row.id)?.deactivated
                return (
                    <div className={classes.buttonContainer}>
                        {isDeactivated ?
                            <Button onClick={() => {
                                activatePackages(params.row.id).then()
                            }} className={classes.activateButton} variant="contained" color="primary">
                                {t("activate")}
                            </Button>
                            :
                            <Button className={classes.deactivateButton} onClick={() => {
                                deactivatePackages(params.row.id).then()
                            }} variant="contained">{t("deactivate")}</Button>
                        }
                    </div>
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('actions')}
                    </Typography>
                )
            },
        }
    ];

    return (
        <>
            {modal === 'ADD' &&
                <CreatePackageModal
                    modalType={'ADD'}
                    onSubmit={createPackage}
                    packagesValues={packageValues}
                    onClose={() => setModal('NONE')}
                    setPackageValues={(p => setPackageValues(p))}
                    open={modal === 'ADD'}
                />
            }
            <Grid container justifyContent={'space-between'} alignItems='center'>
                <Grid item style={{ marginBottom: 10 }}>
                    <Button
                        onClick={() => setModal('ADD')}
                        variant="contained" color="primary" className={classes.addUser}>
                        {t("Add Package")}
                    </Button>
                </Grid>
                <Grid item xs={8} md={3}>
                    <SharedDropdown
                        options={statuses}
                        label={('Status')}
                        value={isDeactivated ?? undefined}
                        onChange={(e: any) => {
                            setIsDeactivated(e.target.value)
                        }}
                    />
                </Grid>
            </Grid>

            <Paper elevation={2} style={{ marginTop: '2rem', width: '100%', height: '70vh' }}>
                <SharedTable
                    columns={columns}
                    rows={results ?? []}
                    // pageSize={search?.limit}
                    // onPageChange={(i) => setSearch((s) => ({...s, offset: (i) * s.limit}))}
                    // onPageSizeChange={(i) => setSearch((s) => ({...s, limit: i}))}
                    rowCount={results?.length}
                    // page={Math.ceil(search?.offset / search?.limit)}
                    getRowId={(i: any) => {
                        return i.id
                    }}
                />
            </Paper>

        </>
    );
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: 1400,
            margin: 'auto',
            overflow: 'hidden',
        },
        Chip: {
            color: "white"
        },
        redChip: {
            backgroundColor: "red"
        },
        blueChip: {
            backgroundColor: "blue"
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        contentWrapper: {
            margin: '40px 16px',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        deactivateButton: {
            width: '100px',
            color: 'white',
            backgroundColor: 'red'
        },
        activateButton: {
            width: '100px',
            color: 'white',
            backgroundColor: '#99C455'
        },
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        }
    }),
);
export default Packages
