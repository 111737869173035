import React, {FunctionComponent, useEffect, useState} from "react";
import {Modal, Paper, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import IPagedResults, {IPagedSearch} from "Types/Paged";
import SharedTable from "Components/Shared/SharedTable";
import {SetIsLoading, ShowAlert} from "State/Actions/UiActions";
import {useDispatch, useSelector} from "react-redux";
import {IRide} from "Types/Rides";
import {Operation} from "Types/JobRole";
import IAppStateModel from "Types/AppState";
import {FcMinus, FcPlus} from "react-icons/all";
import UsersClient from "Api/UsersClient";
import {IGetUserSearchModel} from "Types/Users";
import {parseDate} from "Utils/dateUtils";

interface IProps {
    open: boolean
    onClose: () => void;
    userId: number;
    modal?: boolean;
    setPaymentSearch: (value: string | number, name: string) => void;
}

const PaymentTransactions: FunctionComponent<IProps> = ({open, onClose, userId, modal, setPaymentSearch}) => {
    const classes = useStyles();
    const role = useSelector((state: IAppStateModel) => state.profile?.role)
    const client = new UsersClient();
    const [results, setResults] = useState<IPagedResults<IRide>>();
    const dispatch = useDispatch()
    const [search, setSearch] = useState<IPagedSearch<IGetUserSearchModel>>({
        limit: 25,
        offset: 0,
        search: {
            id: userId,
        }
    });
    const {t} = useTranslation("rides")
    const refresh = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.GetUserPayments(search);
        if (res.succeeded) {
            setResults(res.data);
        } else {
            dispatch(ShowAlert(t("somethingWentWrong"), "error"));
            onClose()
        }
        dispatch(SetIsLoading(false))
    }
    useEffect(() => {
        refresh().then();
    }, [search.limit, search.offset])

    const columns = [
        {
            field: 'id',
            headerName: t('idHeader'),
            width: 100,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('idHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'type',
            headerName: t('transactions'),
            width: 150,
            editable: false,
            renderCell: (params: any) => {
                return (
                    params.row.type === "Payment" ? <FcPlus size={25}/> :
                        <FcMinus size={25}/>
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('userNameHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'type',
            headerName: t('type'),
            width: 150,
            renderHeader: () => {
                return (

                    <Typography className={classes.tableHeader}>
                        {t('type')}
                    </Typography>
                )
            },
        },
        {
            field: 'paymentMethod',
            headerName: t('type'),
            width: 200,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('Payment Method')}
                    </Typography>
                )
            },
        },
        {
            field: 'value',
            headerName: t('value'),
            width: 150,
            sortable: false,
            hide: role === Operation.value,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('charge')}
                    </Typography>
                )
            },
        },
        {
            field: 'createdOn',
            headerName: t('createdOnHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    parseDate(params.row.createdOn)
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('createdOnHeader')}
                    </Typography>
                )
            },
        },
    ];

    return (
        <div className={classes.main}>
            {modal ? (
                <Modal open={open} className={classes.modal} onClose={onClose}>
                    <Paper elevation={2} style={{ marginTop: '2rem', width: '80%', height: '45vh' }}>
                        <SharedTable
                            columns={columns}
                            rows={results?.matches ?? []}
                            pageSize={search?.limit}
                            onPageChange={(i) => {
                                let offset = (i) * search.limit
                                setSearch((s) => ({ ...s, offset }))
                                setPaymentSearch(offset, 'offset')
                            }}
                            onPageSizeChange={(i) => {
                                setSearch((s) => ({ ...s, limit: i }))
                                setPaymentSearch(i, 'limit')
                            }}
                            rowCount={results?.total}
                            page={Math.ceil(search?.offset / search?.limit)}
                            getRowId={(i: any) => {
                                return i.id
                            }}
                        />
                    </Paper>
                </Modal>
            ) : (
                <Paper elevation={2} style={{ marginTop: '2rem', width: '100%', height: '45vh' }}>
                    <SharedTable
                        columns={columns}
                        rows={results?.matches ?? []}
                        pageSize={search?.limit}
                        onPageChange={(i) => {
                            let offset = (i) * search.limit
                            setSearch((s) => ({ ...s, offset }))
                            setPaymentSearch(offset, 'offset')
                        }}
                        onPageSizeChange={(i) => {
                            setSearch((s) => ({ ...s, limit: i }))
                            setPaymentSearch(i, 'limit')
                        }}
                        rowCount={results?.total}
                        page={Math.ceil(search?.offset / search?.limit)}
                        getRowId={(i: any) => {
                            return i.id
                        }}
                    />
                </Paper>
            )}
        </div>
    )
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: "scroll",
            padding: "3%",
            alignSelf: "center",
        },
        main: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 21
        },
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        },
        Chip: {
            color: "white"
        },
        redChip: {
            backgroundColor: "red"
        },
        blueChip: {
            backgroundColor: "blue"
        }
    }),
);
export default PaymentTransactions;
