import React, {useEffect} from 'react';
import clsx from 'clsx';
import {createStyles, Theme} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import TimerIcon from '@material-ui/icons/Timer';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import {makeStyles} from "@material-ui/styles";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {CiDiscount1, MdAttachMoney, TfiPackage} from "react-icons/all";
import {Admin, CustomerService, Operation} from "Types/JobRole";
import Authorized from "Components/Shared/Authorized";
import TimelineIcon from '@material-ui/icons/Timeline';
import RedeemIcon from '@material-ui/icons/Redeem';
import StaffClient from "Api/StaffClient";
import {Box} from "@material-ui/core";
import {SetNotifications} from "State/Actions/ProfileActions";
import {useDispatch, useSelector} from "react-redux";
import IAppStateModel from "Types/AppState";
import {MdPhonelinkRing} from "react-icons/all";
import { Subscriptions } from '@material-ui/icons';

const categories = [
    {
        id: 'dataSection',
        children: [
            {id: 'analytics', icon: <TimelineIcon/>, route: "/analytics", allowedRoles: [Admin.value]},
            {
                id: 'rides',
                icon: <DnsRoundedIcon/>,
                route: "/rides",
                allowedRoles: [Admin.value, Operation.value, CustomerService.value]
            },
            {id: 'users', icon: <PeopleIcon/>, route: "/users", allowedRoles: [Admin.value, CustomerService.value]},
            {
                id: 'notifications',
                icon: <NotificationsIcon/>,
                route: "/notifications",
                allowedRoles: [Admin.value, Operation.value, CustomerService.value]
            },
            {id: 'payments', icon: <MdAttachMoney/>, route: "/payments", allowedRoles: [Admin.value]},
            {id: 'promoCodes', icon: <CiDiscount1/>, route: "/PromoCodes", allowedRoles: [Admin.value]},
            {id: 'vouchers', icon: <RedeemIcon/>, route: "/Vouchers", allowedRoles: [Admin.value]},
            {id: 'packages', icon: <TfiPackage/>, route: "/Packages", allowedRoles: [Admin.value]},
            {id: 'mobilePopups', icon: <MdPhonelinkRing/>, route: "/mobilePopups", allowedRoles: [Admin.value]},
            {id: 'subsicriptions', icon: <Subscriptions />, route: "/subsicriptions", allowedRoles: [Admin.value]},
            
        ],
    },
    {
        id: 'scootersSection',
        children: [
            {id: 'iotDevices', icon: <SettingsIcon/>, route: "/iot", allowedRoles: [Admin.value, Operation.value]},
            {
                id: 'areas',
                icon: <SettingsIcon/>,
                route: "/areas",
                allowedRoles: [Admin.value, Operation.value, CustomerService.value]
            },
            {id: 'iotLogs', icon: <TimerIcon/>, route: "/iotLogs", allowedRoles: [Admin.value, Operation.value]},
            {id: 'otherLogs', icon: <EditIcon/>, route: "/otherLogs", allowedRoles: [Admin.value, Operation.value]},
        ],
    },
    {
        id: 'settingsSection',
        children: [
            {id: 'staff', icon: <PeopleIcon/>, route: "/staff", allowedRoles: [Admin.value]},
            {id: 'staffNotifications', icon: <NotificationsIcon/>, route: "/staffNotifications", allowedRoles: [Admin.value]},
        ],
    },
];


const Navigator = (props: any) => {
    const {...other} = props;
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const numberOfNotifications = useSelector((state: IAppStateModel) => state.profile?.numberOfNotifications)
    const refreshAsync = async () => {
        const client = new StaffClient();
        let res = await client.SearchNotifications({
            limit: 0,
            offset: 0
        });
        if (res.succeeded) {
            dispatch(SetNotifications(res.data?.unReadNotificationsCount as number))
        } else {
        }
    }
    useEffect(() => {
        refreshAsync().then()
    }, [])

    const {t} = useTranslation("navigation");
    return (
        <Drawer variant="permanent" {...other}>
            <List disablePadding>
                <ListItem onClick={() => {
                    props.open && props.onClose()
                    history.push("/")
                }}
                          className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
                    <img alt={"logo"} width={"221px"} src={"/Logo.png"}/>
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        props.open && props.onClose()
                        history.push("/")
                    }}
                    className={clsx(classes.item, classes.itemCategory, location.pathname === "/" && classes.itemActiveItem)}>
                    <ListItemIcon className={classes.itemIcon}>
                        <HomeIcon/>
                    </ListItemIcon>
                    <ListItemText
                        classes={{
                            primary: classes.itemPrimary,
                        }}
                    >
                        {t("dashboard")}
                    </ListItemText>
                </ListItem>
                {categories.map(({id, children}) => (
                    <React.Fragment key={id}>
                        <ListItem className={classes.categoryHeader}>
                            <ListItemText
                                classes={{
                                    primary: classes.categoryHeaderPrimary,
                                }}
                            >
                                {t(id)}
                            </ListItemText>
                        </ListItem>
                        {children.map(({id: childId, icon, route, allowedRoles}) => (
                            <Authorized allowedRoles={allowedRoles}>
                                <ListItem
                                    key={childId}
                                    button
                                    onClick={() => {
                                        props.open && props.onClose()
                                        history.push(route)
                                    }}
                                    className={clsx(classes.item, location.pathname === route && classes.itemActiveItem)}
                                >
                                    <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                                    <ListItemText
                                        classes={{
                                            primary: classes.itemPrimary,
                                        }}
                                    >
                                        {t(childId)}
                                    </ListItemText>
                                    {childId === 'staffNotifications' && (numberOfNotifications ?? 0) > 0 &&
                                        <Box className={classes.iconContainer}>
                                            {numberOfNotifications}
                                        </Box>}
                                </ListItem>
                            </Authorized>
                        ))}
                        <Divider className={classes.divider}/>
                    </React.Fragment>
                ))}
            </List>
        </Drawer>
    );
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        categoryHeader: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        categoryHeaderPrimary: {
            color: theme.palette.common.white,
        },
        item: {
            paddingTop: 1,
            paddingBottom: 1,
            color: 'rgba(255, 255, 255, 0.7)',
            '&:hover,&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
            },
        },
        itemCategory: {
            backgroundColor: '#232f3e',
            boxShadow: '0 -1px 0 #404854 inset',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        firebase: {
            fontSize: 24,
            color: theme.palette.common.white,
        },
        itemActiveItem: {
            color: '#4fc3f7',
        },
        itemPrimary: {
            fontSize: 'inherit',
        },
        itemIcon: {
            minWidth: 'auto',
            marginRight: theme.spacing(2),
        },
        divider: {
            marginTop: theme.spacing(2),
        },
        iconContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.main,
            minWidth: 50,
            width: 'fit-content',
            height: 50,
            borderRadius: 25,
            padding: 5,
            fontWeight: 'bold',
            color: 'white'
        }
    }),
);

export default Navigator;
