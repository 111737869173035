import React, {useState} from 'react'
import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import {useTranslation} from "react-i18next";
import {IPagedSearch} from "Types/Paged";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ActionsModal from "Components/Views/PromoCodes/components/ActionsModal";
import {Paper, Typography} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {RiFileExcel2Fill} from "react-icons/all";
import SharedTextInput from "Components/Shared/TextInput";
import SharedButton from "Components/Shared/Button";

interface IProps {
    search: IPagedSearch<{ searchText?: string }>;
    setSearch: (e: any) => void;
    onRefresh: (e?: boolean) => Promise<void>
    onClear: () => void;
    isExportHidden?: boolean;
}

const PromoCodesSearch = ({search, setSearch, onRefresh, onClear, isExportHidden = true}: IProps) => {
    const classes = useStyles();
    const {t} = useTranslation('rides')
    const [actionModal, setActionModal] = useState(false)


    return (
        <>
            {actionModal && <ActionsModal onClose={() => {
                setActionModal(false)
                onRefresh().then()
            }}/>}

            <Paper className={classes.searchBox}>
                <Grid className={classes.searchBoxHeader} container item justifyContent={'space-between'}>
                    <Grid className={classes.searchBoxHeader} item>
                        <Typography className={classes.searchText}>
                            {t('Search')}
                        </Typography>
                        <SearchIcon className={classes.searchIcon} fontSize={'large'}/>
                    </Grid>
                    {!isExportHidden && <Grid item style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <RiFileExcel2Fill onClick={() => {}} className={classes.excelIcon} size={30}/>
                    </Grid>}
                </Grid>
                <Grid container item justifyContent={'flex-start'}>
                    <Grid item md={3} xs={12} style={{margin: '1rem'}}>
                        <SharedTextInput
                            value={search.search?.searchText ?? ''}
                            onChange={(e: any) => setSearch({
                                ...search,
                                search: {
                                    ...search.search,
                                    searchText: e,
                                }
                            })}
                            label={t('Search by promo code, discount')}
                        />
                    </Grid>

                </Grid>
                <Grid container item justifyContent={'flex-end'}>
                    <Tooltip title="Create">
                        <IconButton
                            onClick={() => setActionModal(true)}
                        >
                            <AddIcon className={classes.block} color="inherit"/>
                        </IconButton>
                    </Tooltip>

                    <SharedButton
                        className={` ${classes.redChip} ${classes.Chip}`}
                        title={t("clear")}
                        onClick={onClear}
                        variant={'contained'}
                    />
                    <SharedButton
                        className={`${classes.Chip} ${classes.greenChip}`}
                        title={t("search")}
                        onClick={onRefresh}
                        variant={'contained'}
                    />

                </Grid>
            </Paper>
        </>
    )
}

export default PromoCodesSearch;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchBox: {
            padding: "1rem",
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginTop: '1rem'
        },
        searchBoxHeader: {
            display: 'flex', flexDirection: 'row'
        },
        searchText: {
            fontWeight: 'bold',
            fontSize: '2rem',
            color: theme.palette.primary.dark
        },
        Chip: {
            color: "white"
        },
        redChip: {
            backgroundColor: "red",
            marginRight: 5
        },
        greenChip: {
            backgroundColor: theme.palette.primary.main
        },
        searchBar: {
            backgroundColor: 'white',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
            marginTop: 15
        },
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        excelIcon: {
            color: theme.palette.primary.main,
            marginTop: 5,
            cursor: 'pointer'
        },
        searchIcon: {
            marginTop: '0.5rem', color: theme.palette.primary.dark, marginLeft: '0.5rem'
        }
    }),
);
