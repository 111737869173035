import React, {useState} from 'react';
import {FormControl, InputLabel, MenuItem, Modal, Select} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import StaffClient from "Api/StaffClient";
import {ShowAlert} from "State/Actions/UiActions";
import {useDispatch} from "react-redux";
import {SetJobRole} from "State/Actions/ProfileActions";


interface IProps {
    onClose: () => void;
    role: number;
    id: string;
}

const UpdateRoleModal = (props: IProps) => {
    const classes = useStyles();
    const roles = [
        {
            label: 'Admin',
            value: 101
        },
        {
            label: 'Operation',
            value: 102
        },
        {
            label: 'Customer Service',
            value: 103
        },
    ]


    const [role, setRole] = useState<number>(props.role);
    const dispatch = useDispatch()
    const client = new StaffClient();
    const onSubmit = async () => {
        let res = await client.UpdateRole({jobRole: role, id: props.id});
        if (res.succeeded && role !== 0) {
            dispatch(SetJobRole(role.toString()))
            dispatch(ShowAlert(t("The role was changed successfully"), "success"));
            props.onClose()
        } else {
            dispatch(ShowAlert(t("Something went wrong"), "error"));
        }
    }

    const {t} = useTranslation("staff")
    return (
        <Modal
            open={true}
            onClose={props.onClose}
            className={classes.modal}
        >
            <div className={classes.paper}>
                <h2 id="simple-modal-title">{t("addTitle")}</h2>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl style={{minWidth: '100%',}}>
                            <InputLabel>{t("Job Role")}</InputLabel>
                            <Select
                                fullWidth
                                value={role ?? ''}
                                onChange={(e) => {
                                    setRole(e.target.value as number)
                                }}
                            >
                                {roles?.map((m: { label: string, value: number }) => <MenuItem
                                    value={m.value}>{m.label}</MenuItem>)}
                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                        >
                            {t("editRole")}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            minWidth: 500,
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2, 4, 3),
        },
    }),
);

export default UpdateRoleModal;
