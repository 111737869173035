import React, {useEffect, useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {createStyles, Theme} from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import AlarmIcon from '@material-ui/icons/Alarm';
import LightIcon from '@material-ui/icons/Highlight';
import BuzzerIcon from '@material-ui/icons/NotificationsActive';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import UnlockIcon from '@material-ui/icons/LockOpen';
import {makeStyles} from "@material-ui/styles";
import IPagedResults, {IPagedSearch} from "Types/Paged";
import {useDispatch, useSelector} from "react-redux";
import {SetIsLoading, ShowAlert} from "State/Actions/UiActions";
import {useTranslation} from "react-i18next";
import ScootersClient from "../../../Api/ScootersClient";
import {IScooterModel, IScootersSearchModel} from "Types/Scooters";
import Battery20 from '@material-ui/icons/Battery20';
import Battery30 from '@material-ui/icons/Battery30';
import Battery50 from '@material-ui/icons/Battery50';
import Battery80 from '@material-ui/icons/Battery80';
import BatteryFull from '@material-ui/icons/BatteryFull';
import ScooterModal from "./Components/ScooterModal";
import UpdateIotDevice from "./Components/UpdateIotDevice";
import ScooterHistoryModal from "../../Shared/ScooterHistoryModal";
import ChangeStatusModal from "./Components/ChangeStatusModal";
import {Admin} from "Types/JobRole";
import IotDevicesSearch from "Components/Views/IotDevices/Components/IotDevicesSearch";
import SettingsIcon from "@material-ui/icons/Settings";
import JourneyIcon from "@material-ui/icons/Explore";
import TimerIcon from "@material-ui/icons/Timer";
import IAppStateModel from "Types/AppState";
import SharedTable from "Components/Shared/SharedTable";
import {Paper, Typography} from "@material-ui/core";

interface IBatteryProps {
    charge: number
}

export const BatteryIcon = (props: IBatteryProps) => {
    const {charge} = props;
    if (charge < 30) return <Battery20/>;
    if (charge < 50) return <Battery30/>
    if (charge < 80) return <Battery50/>
    if (charge < 100) return <Battery80/>
    return <BatteryFull/>
}

const Component = () => {
    const classes = useStyles();
    const [updateOn, setUpdateOn] = useState(false);
    const role = useSelector((state: IAppStateModel) => state.profile?.role)
    const [changeStatusId, setChangeStatusId] = useState(0)
    const [forUpdate, setForUpdate] = useState();
    const [rideModalOpen, setRideModalOpen] = useState<"none" | "map" | "history">("none")

    const [scooterModalFor, setScooterModalFor] = useState<number | undefined>();

    const [search, setSearch] = useState<IPagedSearch<IScootersSearchModel>>({
        limit: 25,
        offset: 0,
        search: {
            code: '',
            imei: '',
            statusId: undefined,
            manufacturerId: undefined,
            isLowBattery: undefined,
            lookup: true,
            id: undefined
        }
    });
    const [results, setResults] = useState<IPagedResults<IScooterModel>>();
    let client = new ScootersClient();
    const dispatch = useDispatch();
    const refresh = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.SearchAsync(search);
        if (res.succeeded) {
            setResults(res.data);
        } else {

        }
        dispatch(SetIsLoading(false))
    }

    useEffect(() => {
        refresh().then();
    }, [search])
    const {t} = useTranslation("scooters")

    const onGetLogs = async () => {
        let res = await client.GetLogs({
            limit: 20, offset: 0, search: {
                scooterId: scooterModalFor
            }
        })
        if (res.succeeded) {
            return res.data.matches

        } else {
            dispatch(ShowAlert("Could not load scooter journey"));
        }
    }

    const columns = [
        {field: 'id', headerName: 'ID', width: 90},
        {
            field: 'id',
            headerName: t('idHeader'),
            width: 150,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('idHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'code',
            headerName: t('codeHeader'),
            width: 150,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('codeHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'imei',
            headerName: t('imeiHeader'),
            width: 200,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('imeiHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'model',
            headerName: t('modelHeader'),
            width: 150,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('modelHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'status',
            headerName: t('status'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('status')}
                    </Typography>
                )
            },

        },
        {
            field: 'condition',
            headerName: t('scooterChargeHeader'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    <><BatteryIcon
                        charge={params.row.condition.scooterCharge}/>{params.row.condition.scooterCharge}%</>)
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('scooterChargeHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'rate',
            headerName: t('perMinute'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    params.row.rate.perMinute
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('perMinute')}
                    </Typography>
                )
            },

        },
        {
            field: 'rate',
            headerName: t('initialCharge'),
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    params.row?.rate?.initialCharge
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('initialCharge')}
                    </Typography>
                )
            },

        },
        {
            field: 'isLowBattery',
            headerName: t('isLowBattery'),
            width: 100,
            sortable: false,
            disableColumnMenu: true,

            renderCell: (params: any) => {
                return (
                    params.row.isLowBattery ? t("low") : t("high")
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('isLowBattery')}
                    </Typography>
                )
            },
        },
        {
            field: 'iotCharge',
            headerName: t('iotChargeHeader'),
            width: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    params.row?.condition?.iotCharge
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('iotChargeHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'area',
            headerName: t('areaHeader'),
            width: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    params.row?.area?.nameEn ?? "-"
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('areaHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'actions',
            headerName: t('actionsHeader'),
            width: 450,
            sortable: false,
            disableColumnMenu: true,
            hide: role !== Admin.value,
            renderCell: (params: any) => {
                return (
                    <>
                        <IconButton title={t('changeStatus')}
                                    onClick={() => setChangeStatusId(params.row.id)}>
                            <SettingsIcon className={classes.block} color="inherit"/>
                        </IconButton>
                        <IconButton title={t('map')} onClick={() => {
                            setScooterModalFor(params.row.id)
                            setRideModalOpen("map")
                        }}>
                            <JourneyIcon className={classes.block} color="inherit"/>
                        </IconButton>
                        <IconButton title={t('history')} onClick={() => {
                            setScooterModalFor(params.row.id)
                            setRideModalOpen("history")
                        }}>
                            <TimerIcon className={classes.block} color="inherit"/>
                        </IconButton>
                        <IconButton title={t('start')}
                                    onClick={() => client.CommandScooter(params.row.id, "start")}>
                            <UnlockIcon className={classes.block} color="inherit"/>
                        </IconButton>
                        <IconButton title={t('stop')}
                                    onClick={() => client.CommandScooter(params.row.id, "stop")}>
                            <LockIcon className={classes.block} color="inherit"/>
                        </IconButton>
                        <IconButton title={t('alarmOn')}
                                    onClick={() => client.CommandScooter(params.row.id, "alarmOn")}>
                            <AlarmIcon className={classes.block} color="inherit"/>
                        </IconButton>
                        <IconButton title={t('lightOn')}
                                    onClick={() => client.CommandScooter(params.row.id, "lightOn")}>
                            <LightIcon className={classes.block} color="inherit"/>
                        </IconButton>
                        <IconButton title={t('buzzerOn')}
                                    onClick={() => client.CommandScooter(params.row.id, "buzzerOn")}>
                            <BuzzerIcon className={classes.block} color="inherit"/>
                        </IconButton>

                        <IconButton title={t('updateStatus')}
                                    onClick={() => client.CommandScooter(params.row.id, "updateStatus")}>
                            <RefreshIcon className={classes.block} color="inherit"/>
                        </IconButton>
                        <IconButton title={t('edit')} onClick={() => {
                            setForUpdate(params.row.id);
                            setUpdateOn(true)
                        }}>
                            <EditIcon className={classes.block} color="inherit"/>
                        </IconButton>
                    </>
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('actionsHeader')}
                    </Typography>
                )
            },
        },
    ];


    return (
        <>
            <IotDevicesSearch search={search} setSearch={(e) => setSearch(e)} onRefresh={refresh}/>
            <Paper elevation={2} style={{marginTop: '2rem', width: '100%', height: '45vh'}}>
                <SharedTable
                    columns={columns}
                    rows={results?.matches ?? []}
                    pageSize={search?.limit}
                    onPageChange={(i) => setSearch((s) => ({...s, offset: (i) * s.limit}))}
                    onPageSizeChange={(i) => setSearch((s) => ({...s, limit: i}))}
                    rowCount={results?.total}
                    page={Math.ceil(search?.offset / search?.limit)}
                    getRowId={(i: any) => {
                        return i.id
                    }}
                />
            </Paper>

            {updateOn &&
                <UpdateIotDevice id={forUpdate} data={results?.matches?.find(x => x.id === forUpdate)}
                                          onClose={() => {
                                              setUpdateOn(false)
                                              refresh()
                                          }}/>}
            {changeStatusId !== 0 &&
                <ChangeStatusModal
                    id={changeStatusId}
                    onClose={() => {
                        setChangeStatusId(0)
                        refresh()
                    }}
                />
            }
            {scooterModalFor && rideModalOpen === "history" &&
                <ScooterHistoryModal
                    modal={true}
                    open={rideModalOpen === "history"}
                    onClose={() => {
                    setRideModalOpen("none")
                    setScooterModalFor(undefined)
                }}
                                     scooterId={scooterModalFor}/>}
            {scooterModalFor && rideModalOpen === "map" &&
                <ScooterModal open={rideModalOpen === "map"} onClose={() => {
                    setRideModalOpen("none")
                    setScooterModalFor(undefined)
                }}
                              areLinesHidden={true}
                              scooterId={scooterModalFor}
                              getLogs={onGetLogs}/>}
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: 1600,
            margin: 'auto',
            overflow: 'hidden',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        contentWrapper: {
            margin: '40px 16px',
        },
        excelIcon: {
            cursor: 'pointer'
        },
        redChip: {
            color: "white",
            backgroundColor: "red",
            width: '30px',
            marginLeft: '5px'
        },
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        }

    }),
);

export default Component;
