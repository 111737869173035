import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookiesManager from "../Utils/CookiesManager";
import {IPagedSearch} from "../Types/Paged";
import {IGetUserSearchModel, IUpdateUserModel, IUserSearchModel} from "../Types/Users";

class UsersClient {
    client: IClient;
    locale:string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }


    async SearchAsync(
        search: IPagedSearch<IUserSearchModel>): Promise<ApiResponse> {
        let query = `users?limit=${search.limit}&offset=${search.offset}`
        if(search.search?.userName){
            query+=`&userName=${search.search?.userName}`
        }
        if(search.search?.mobile){
            query+=`&mobile=${search.search?.mobile}`
        }
        if (search.search?.from) {
            query += '&from=' + search.search.from
        }
        if (search.search?.to) {
            query += '&to=' + search.search.to
        }
        if(search.search?.sortType){
            query+=`&sortType=${search.search?.sortType}`
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async ExportToExcelAsync(
        search: IPagedSearch<IUserSearchModel>
    ): Promise<ApiResponse> {
        let query = `users/export?search=${search.search??""}`
        if(search.search?.userName){
            query+=`&userName=${search.search?.userName}`
        }
        if(search.search?.mobile){
            query+=`&mobile=${search.search?.mobile}`
        }
        if (search.search?.from) {
            query += '&from=' + search.search.from
        }
        if (search.search?.to) {
            query += '&to=' + search.search.to
        }
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async GetUserPayments(search: IPagedSearch<IGetUserSearchModel>): Promise<ApiResponse> {
        let query = `users/GetUserPayments?limit=${search.limit}&offset=${search.offset}&id=${search?.search?.id}`
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }
    async ExportPayments(search: IPagedSearch<{userId: number}>): Promise<ApiResponse> {
        let query = `users/ExportPayment?id=${search.search?.userId}&limit=${search.limit}&offset=${search.offset}`
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }
    async UpdateAsync(
        model:IUpdateUserModel,
        id:number
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync(`users/${id}`,model);
        return rs;
    }

    async GetAllUsers(): Promise<ApiResponse> {
        let query = `users/GetAllUsers`
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

}

export default UsersClient;
