export function unCamelCase (str: string){
    return str
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // space before last upper in a sequence followed by lower
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
        // uppercase the first character
        .replace(/^./, function(str){ return str.toUpperCase(); })
}

export function generateRandomColor() {
    // Generate random values for red, green, and blue
    let red = Math.floor(Math.random() * 256);
    let green = Math.floor(Math.random() * 256);
    let blue = Math.floor(Math.random() * 256);
    // Create the RGB color string
    let color = 'rgb(' + red + ', ' + green + ', ' + blue + ')';
    return color;
}

export const years = [
    {
        label: '2030',
        value: 2030
    },
    {
        label: '2029',
        value: 2029
    },
    {
        label: '2028',
        value: 2028
    },
    {
        label: '2027',
        value: 2027
    },
    {
        label: '2026',
        value: 2026
    },
    {
        label: '2025',
        value: 2025
    },
    {
        label: '2024',
        value: 2024
    },
    {
        label: '2023',
        value: 2023
    },
    {
        label: '2022',
        value: 2022
    },
    {
        label: '2021',
        value: 2021
    },
    {
        label: '2020',
        value: 2020
    },
    {
        label: '2019',
        value: 2019
    },
    {
        label: '2018',
        value: 2018
    }
];


