import React from 'react'
import RidesCount from "Components/Views/Analytics/Components/RidesCount";
import RidesPerUser from "Components/Views/Analytics/Components/RidesPerUser";
import ScooterByStatus from "Components/Views/Analytics/Components/ScooterByStatus";
import ScootersPerArea from "Components/Views/Analytics/Components/ScootersPerArea";
import PackagesCount from "Components/Views/Analytics/Components/PackagesCount";
import Grid from "@material-ui/core/Grid";
import RidesPerArea from "Components/Views/Analytics/Components/RidesPerArea";
import UsersActivity from "Components/Views/Analytics/Components/UsersActivity";


const Analytics = () => {
    return (<>
        <Grid container justifyContent={'space-between'} style={{marginBottom: 50}} spacing={2}>
            <Grid item md={11} xs={12}>
                <PackagesCount/>
            </Grid>
            <Grid item md={11} xs={12}>
                <RidesCount/>
            </Grid>
        
            <Grid item md={11} xs={12}>
                <RidesPerArea/>
            </Grid>
            <Grid item md={11} xs={12}>
                <RidesPerUser/>
            </Grid>
        
            <Grid item md={11} xs={12}>
                <UsersActivity/>
            </Grid>
            <Grid item md={5} xs={12}>
                <ScooterByStatus/>
            </Grid>
            <Grid item md={5} xs={12}>
                <ScootersPerArea/>
            </Grid>
        </Grid>
    </>)
}

export default Analytics;