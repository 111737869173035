const dev = {
    baseUrl: "https://localhost:5001/api/",
}

const stg = {
    baseUrl: "https://api-stg.funridescooters.com/api/",
}

const prod = {
    baseUrl: "https://api.funridescooters.com/api/",
}

const config = (env: any) => {
    switch (env) {
        case 'dev':
            return dev;
        case 'stg':
            return stg;
        case 'prod':
            return prod;
    }
}

export default {
    // baseUrl: "http://localhost:5001/api/",
    baseUrl: "https://api-stg.funridescooters.com/api/",
    // baseUrl: "https://api.funridescooters.com/api/",
    googleMapsApiKey: 'AIzaSyBVpqgwvUDtznbA74DbRjM4t3tQhqQkZ8g',
    // Add common config values here
    ...config(process.env.REACT_APP_STAGE)
};
