import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookiesManager from "../Utils/CookiesManager";
import {Base64UploadRequest} from "Types/Blobs";

class BlobsClient {
    client: IClient;
    locale:string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }


    async UploadImage(blob: Base64UploadRequest): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync("blobs/base", blob);
        return rs;
    }
}

export default BlobsClient;
