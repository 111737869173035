import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {createStyles, Theme} from '@material-ui/core/styles';
import {makeStyles} from "@material-ui/styles";
import {IPagedSearch} from "Types/Paged";
import {useDispatch} from "react-redux";
import {SetIsLoading} from "State/Actions/UiActions";
import {IUserModel} from "Types/Users";
import StaffClient from "Api/StaffClient";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import AddStaffMember from "./Components/AddStaffMember";
import dayjs from "dayjs";
import SharedTable from "Components/Shared/SharedTable";
import {Typography, Paper} from "@material-ui/core";
import UpdateRoleModal from "Components/Views/Staff/Components/UpdateRoleModal";


const Component = () => {
    const classes = useStyles();

    const [search, setSearch] = useState<IPagedSearch<any>>({
        limit: 25,
        offset: 0
    });
    const [results, setResults] = useState<IUserModel[]>([]);
    const [addOn, setAddOn] = useState(false);
    const [updateRoleModal, setUpdateRoleModal] = useState({role: 0, id: ''})
    let client = new StaffClient();
    const dispatch = useDispatch();
    const refresh = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.SearchAsync(search);

        if (res.succeeded) {
            setResults(res.data.result);
        } else {

        }
        dispatch(SetIsLoading(false))
    }
    useEffect(() => {
        refresh();
    }, [search])

    const {t} = useTranslation("staff");

    const columns = [
        {
            field: 'id',
            headerName: t('idHeader'),
            width: 350,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('idHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'displayName',
            headerName: t('nameHeader'),
            width: 150,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('nameHeader')}
                    </Typography>
                )
            },
        },
        {
            field: 'email',
            headerName: t('emailHeader'),
            width: 300,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('emailHeader')}
                    </Typography>
                )
            },

        },
        {
            field: 'createdOn',
            headerName: t('createdOnHeader', {ns: 'rides'}),
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    dayjs(params.row.createdOn).format("DD-MM-YYYY HH:mm:ss")
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('createdOnHeader', {ns: 'rides'})}
                    </Typography>
                )
            },
        },
        {
            field: 'actions',
            headerName: t('actions'),
            width: 300,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('actions')}
                    </Typography>
                )
            },
            renderCell: (params: any) => {
                return (
                    <>
                            <Button onClick={() => {
                                setUpdateRoleModal({
                                    role: Number(params.row?.profileData?.[0]?.value),
                                    id: params.row.id
                                })
                            }} variant="contained" color="primary">
                                {t("editRole")}
                            </Button>
                    </>
                )
            },
        }
    ];

    return (
        <>
            {addOn && <AddStaffMember onClose={() => setAddOn(false)}/>}
            {updateRoleModal.id !== '' &&
                <UpdateRoleModal id={updateRoleModal.id} role={updateRoleModal.role} onClose={() => {
                    setUpdateRoleModal({id: '', role: 0})
                    refresh().then();
                }}/>}
            <Grid container justifyContent={'flex-end'}>
                <Grid item style={{marginBottom: 10}}>
                    <Button
                        onClick={() => setAddOn(true)}
                        variant="contained" color="primary" className={classes.addUser}>
                        {t("addButton")}
                    </Button>
                </Grid>
            </Grid>
            <Paper elevation={2} style={{marginTop: '2rem', width: '100%', height: '70vh'}}>
                <SharedTable
                    columns={columns}
                    rows={results ?? []}
                    pageSize={search?.limit}
                    onPageChange={(i) => setSearch((s) => ({...s, offset: (i) * s.limit}))}
                    onPageSizeChange={(i) => setSearch((s) => ({...s, limit: i}))}
                    rowCount={results.length}
                    page={Math.ceil(search?.offset / search?.limit)}
                    getRowId={(i: any) => {
                        return i.id
                    }}
                />
            </Paper>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: 1200,
            margin: 'auto',
            overflow: 'hidden',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        }
    }),
);


export default Component;
