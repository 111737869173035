import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookiesManager from "../Utils/CookiesManager";
import {IPagedSearch} from "../Types/Paged";
import {ICreateNotification} from "../Types/Notifications";

class NotificationsClient {
    client: IClient;
    locale: string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }


    async SearchAsync(
        search: IPagedSearch<any>
    ): Promise<ApiResponse> {
        let query = `Notifications?limit=${search.limit}&offset=${search.offset}`
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }
    async CreateAsync(
        model: ICreateNotification
    ): Promise<ApiResponse> {
        let query = `Notifications/pushNotification`
        const rs: ApiResponse = this.client.SimplyPostAsync(query, model);
        return rs;
    }
}

export default NotificationsClient;
