import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookiesManager from "../Utils/CookiesManager";
import {IPagedSearch} from "Types/Paged";
import {ICreateStaffMember, UpdateRoleModel} from "Types/Staff";
import {NotificationSearch} from "Types/Notifications";

class StaffClient {
    client: IClient;
    locale:string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }


    async SearchAsync(
        search: IPagedSearch<any>
    ): Promise<ApiResponse> {
        let query = `staff?limit=${search.limit}&offset=${search.offset}`
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async AddAsync(
        info: ICreateStaffMember
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync(`staff`,info);
        return rs;
    }

    async UpdateRole(
        info: UpdateRoleModel
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync(`staff/updateRole`, info);
        return rs;
    }

    async SearchNotifications(
        search: IPagedSearch<NotificationSearch>
    ): Promise<ApiResponse> {
        let query = `staff/notifications?limit=${search.limit}&offset=${search.offset}`
        if (search.search?.code) query += `&code=${search.search.code}`;
        if (search.search?.imei) query += `&imei=${search.search.imei}`;
        if (search.search?.scooterCode) query += `&scooterCode=${search.search.scooterCode}`;
        if (search.search?.from) query += `&from=${search.search.from}`;
        if (search.search?.to) query += `&to=${search.search.to}`;
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async MarkAsRead(id: number): Promise<ApiResponse> {
        let query = `staff/markAsRead`
        const rs: ApiResponse = this.client.SimplyPostAsync(query, {id});
        return rs;
    }

    async MarkAllAsRead(): Promise<ApiResponse> {
        let query = `staff/markAllAsRead`
        const rs: ApiResponse = this.client.SimplyPostAsync(query, {});
        return rs;
    }

    async ExportToExcelAsync(
        search: IPagedSearch<NotificationSearch>
    ): Promise<ApiResponse> {
        let query = `staff/ExportNotifications`
        if (search.search?.code) query += `?code=${search.search.code}`;
        if (search.search?.imei) query += `&imei=${search.search.imei}`;
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }
}

export default StaffClient;
