import {IRide} from "./Rides";


export interface IUserModel {
    ridesTotal: number;
    userTransactionsTotal: number;
    id: number;
    accountId: string;
    name: string;
    mobile: string;
    currentBalance: number | null;
    createdOn: string | null;
    profilePictureUrl: string
    userTransactions: IUserTransactionModel[];
    rides: IRide[]
    numberOfPackages : number;
}

export interface IUserTransactionModel {
    type: string;
    value: number;
    createdOn: string;
}

export interface IUpdateUserModel {
    balance?: number,
    discount?: number
}

export interface IUserSearchModel {
    userName: string;
    mobile: string;
    from?: Date | null;
    to?: Date | null;
    sortType?: SortType | null
}

export enum SortType {
    Desc = 1,
    Asc = 2,
}

export interface IGetUserSearchModel {
    id: number;
}

