import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {createStyles, Theme} from '@material-ui/core/styles';
import {makeStyles} from "@material-ui/styles";
import IPagedResults, {IPagedSearch} from "Types/Paged";
import {useDispatch} from "react-redux";
import {SetIsLoading} from "State/Actions/UiActions";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {INotification} from "Types/Notifications";
import NotificationsClient from "Api/NotificationsClient";
import Button from "@material-ui/core/Button";
import SendNotification from "./Components/SendNotification";
import {Admin} from "Types/JobRole";
import Authorized from "Components/Shared/Authorized";
import SharedTable from "Components/Shared/SharedTable";
import {Paper, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        }
    }),
);


const Notifications = (props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [results, setResults] = useState< IPagedResults<INotification>>();
    const [addOn, setAddOn] = useState<boolean>(false)
    const client = new NotificationsClient();
    const {t} = useTranslation("notifications")

    const [search, setSearch] = useState<IPagedSearch<any>>({
        limit: 25,
        offset: 0
    });

    const refreshAsync = async () => {
        dispatch(SetIsLoading(true))
        let res = await client.SearchAsync(search);
        if (res.succeeded) {
            setResults(res.data);
        } else {

        }
        dispatch(SetIsLoading(false))
    }

    useEffect(() => {
        refreshAsync();
    }, [search])

    const columns = [
        {
            field: 'title',
            headerName: t('title'),
            width: 300,
            editable: false,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('title')}
                    </Typography>
                )
            },

        },
        {
            field: 'message',
            headerName: t('message'),
            width: 500,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('message')}
                    </Typography>
                )
            },

        },
        {
            field: 'hidden',
            headerName: t('hidden'),
            width: 200,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('hidden')}
                    </Typography>
                )
            },

        },
        {
            field: 'discount',
            headerName: t('discount'),
            width: 200,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('discount', {ns: 'promoCodes'})}
                    </Typography>
                )
            },

        },
        {
            field: 'createdOn',
            headerName: t('sentOn'),
            width: 300,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    dayjs(params.row.createdOn).format("DD-MM-YYYY HH:mm:ss")
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('sentOn')}
                    </Typography>
                )
            },

        }
    ];

    return (
        <>
            {addOn && <SendNotification onClose={() => setAddOn(false)}/>}
            <Authorized allowedRoles={[Admin.value]}>
                <Grid container justifyContent={'flex-end'}>
                    <Grid item style={{marginBottom: 10}}>
                        <Button
                            onClick={() => setAddOn(true)}
                            variant="contained" color="primary" className={classes.addUser}>
                            {t("addTitle")}
                        </Button>
                    </Grid>
                </Grid>
            </Authorized>

            <Paper elevation={2} style={{marginTop: '2rem', width: '100%', height: '70vh'}}>
                <SharedTable
                    columns={columns}
                    rows={results?.matches ?? []}
                    pageSize={search?.limit}
                    onPageChange={(i) => setSearch((s) => ({...s, offset: (i) * s.limit}))}
                    onPageSizeChange={(i) => setSearch((s) => ({...s, limit: i}))}
                    rowCount={results?.total}
                    page={Math.ceil(search?.offset / search?.limit)}
                    getRowId={(i: any) => {
                        return i.id
                    }}
                />
            </Paper>
        </>
    );
}

export default Notifications;
