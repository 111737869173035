import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookiesManager from "../Utils/CookiesManager";

class AuthClient {
    client: IClient;
    locale:string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }


    async LoginAsync(
        email: string,
        password: string,
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync("staff/signin", {
            email,
            password,
        });
        return rs;
    }
}

export default AuthClient;
