import React, {useEffect, useState} from 'react';
import {Box, Modal} from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import Config from '../../../../config';
import {IScooterLog} from "Types/Scooters";
import {SetIsLoading} from "State/Actions/UiActions";
import {useDispatch} from "react-redux";

interface IProps {
    onClose: () => void
    open: boolean
    scooterId: number
    getLogs: () => Promise<IScooterLog[]>
    areLinesHidden?: boolean;
}

const Component = (props: IProps) => {
    const [logs, setLogs] = useState<IScooterLog[]>([]);
    const dispatch = useDispatch()

    const getDefaultCenter = () => {
        const firstMatch: IScooterLog = logs[0]
        const defaultProps = {
            lat: Number(firstMatch.latitude ?? 39.5692),
            lng: Number(firstMatch.longitude ?? 24.5247)
        }
        return defaultProps
    }

    const getJourney = async () => {
        dispatch(SetIsLoading(true))
        const data = await props.getLogs()
        setLogs(data)
        dispatch(SetIsLoading(false))
    }
    useEffect(() => {
        getJourney().then();
    }, [])


    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            title={"Iot"}
            style={{justifyContent: "center", alignItems: "center", display: "flex"}}

        >

            <Box width={"80%"} alignSelf={"center"}
                 height={821}>
                {
                    logs && logs.length > 0 &&
                        <GoogleMapReact
                        yesIWantToUseGoogleMapApiInternals
                        bootstrapURLKeys={{key: Config.googleMapsApiKey}}
                        defaultCenter={getDefaultCenter()}
                        defaultZoom={21}
                        onGoogleApiLoaded={(google) => {
                            if (!props.areLinesHidden) {
                                getLines(google, logs);
                            }
                        }}


                    >
                        {/*{<LocationInRouteMarker*/}
                        {/*    color={"blue"}*/}
                        {/*    lng={logs[0].longitude}*/}
                        {/*    size={10}*/}

                        {/*    lat={logs[0].latitude}*/}
                        {/*/>}*/}

                        {<LocationInRouteMarker
                            color={"red"}
                            size={10}

                            lng={logs.reverse()[0].longitude}
                            lat={logs.reverse()[0].latitude}
                        />}

                        {logs.map((x => <LocationInRouteMarker
                            onClick={(x: IScooterLog) => alert((x.timeStamp))}
                            size={3}
                            color={"pink"}
                            lng={x.longitude}
                            lat={x.latitude}
                        />))}
                    </GoogleMapReact>
                }


            </Box>
        </Modal>
    )
}
const getLines = (google: any, logs: IScooterLog[]) => {
    const path = new google.maps.Polyline({
        path: logs.map(l => {
            return {lat: l.latitude, lng: l.longitude}
        }),
        geodesic: true,
        strokeColor: "#99C455",
        strokeOpacity: 1,
        strokeWeight: 5
    });
    path.setMap(google.map)
    return path
}
const LocationInRouteMarker = (props: any) => <div
    onClick={props.onClick}
    style={{borderRadius: props.size, backgroundColor: props.color, height: props.size, width: props.size,}}/>

export default Component;
