
export interface DashboardFilter {
    from?: Date;
    to?: Date;
    type: DashboardFilterType;
}

export enum DashboardFilterType {
    NumberOfUsersWithRides = 1,
    NumberOfUsers = 2,
    NumberOfRides = 3,
    NumberOfFailedRides = 4,
    NumberOfSuccessfulRides = 5,
    UsersCombinedPositiveBalance = 6,
    UsersCombinedNegativeBalance = 7
}