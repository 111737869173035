import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookiesManager from "../Utils/CookiesManager";
import {DashboardFilter} from "Types/Dashboard";

class DashboardClient {
    client: IClient;
    locale: string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }


    async GetDashboardData(filters: DashboardFilter[]): Promise<ApiResponse> {
        let query = 'Dashboard/';
        if (filters.length > 0) {
            // Convert the filters array to a JSON string
            const filtersJson = JSON.stringify(filters);
            query += `?filters=${encodeURIComponent(filtersJson)}`;
        }
        const rs: ApiResponse = await this.client.SimplyGetAsync(query);
        return rs;
    }
}

export default DashboardClient;
