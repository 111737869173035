import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookiesManager from "../Utils/CookiesManager";
import {IPagedSearch} from "Types/Paged";
import {CreatePopupModel} from "Types/MobilePopups";

class MobilePopupsClient {
    client: IClient;
    locale:string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }
    async SearchAsync(
        search: IPagedSearch<any>
    ): Promise<ApiResponse> {
        let query = `mobilePopups?limit=${search.limit}&offset=${search.offset}`
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }

    async CreatePopup(
        info: CreatePopupModel
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync(`mobilePopups`,info);
        return rs;
    }

    async UpdatePopup(
        info: CreatePopupModel
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync(`mobilePopups/update`,info);
        return rs;
    }

}

export default MobilePopupsClient;
