import React, {useEffect, useState} from 'react';
import {createStyles, Theme} from '@material-ui/core/styles';
import {makeStyles} from "@material-ui/styles";
import {IPagedSearch} from "Types/Paged";
import {useDispatch} from "react-redux";
import {SetIsLoading} from "State/Actions/UiActions";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import PaymentsClient from "Api/PaymentsCleint";
import {IPayment} from "Types/Payments";
import SharedTable from "Components/Shared/SharedTable";
import {Typography, Paper} from "@material-ui/core";
import PaymentsSearch from "Components/Views/Payments/components/PaymentsSearch";


const Component = () => {
    const classes = useStyles();
    const {t} = useTranslation("payments")
    let client = new PaymentsClient();
    const dispatch = useDispatch();
    const [count, setCount] = useState<number>(0);
    const [results, setResults] = useState<IPayment[]>([]);
    const [search, setSearch] = useState<IPagedSearch<{mobile: string}>>({
        limit: 25,
        offset: 0,
        search: {
            mobile: ''
        }
    });

    const refresh = async (info:  IPagedSearch<{mobile: string}>) => {
        dispatch(SetIsLoading(true))
        let res = await client.SearchAsync(info);
        if (res.succeeded) {
            setResults(res.data?.matches);
            setCount(res.data?.total)
        }
        dispatch(SetIsLoading(false))
    }

    useEffect(() => {
        refresh(search).then();
    }, [search.limit, search.offset])

    const columns = [
        {
            field: 'checkoutId',
            headerName: t('checkoutId'),
            width: 400,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('checkoutId')}
                    </Typography>
                )
            },
        },
        {
            field: 'value',
            headerName: t('value'),
            width: 200,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('paymentValue')}
                    </Typography>
                )
            },
        },
        {
            field: 'paymentMethod',
            headerName: t('paymentMethod'),
            width: 200,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('paymentMethod')}
                    </Typography>
                )
            },
        },
        {
            field: 'voucherId',
            headerName: t('voucherId'),
            width: 200,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('voucherId')}
                    </Typography>
                )
            },
        },
        {
            field: 'promoCode',
            headerName: t('promoCode'),
            width: 200,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('promoCode', {ns: 'promoCodes'})}
                    </Typography>
                )
            },
        },
        {
            field: 'packageId',
            headerName: t('packageId'),
            width: 200,
            sortable: false,
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('packageId')}
                    </Typography>
                )
            },
        },

        {
            field: 'user',
            headerName: t('name'),
            width: 250,
            renderCell: (params: any) => {
                return (
                    params.row?.user?.name
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('name')}
                    </Typography>
                )
            },
        },
        {
            field: 'mobile',
            headerName: t('mobile'),
            width: 250,
            renderCell: (params: any) => {
                return (
                    params.row?.user?.mobile
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('mobileHeader', {ns: 'users'})}
                    </Typography>
                )
            },

        },
        {
            field: 'createdOn',
            headerName: t('createdOn'),
            width: 250,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    dayjs(params.row.createdOn).format("DD-MM-YYYY HH:mm:ss")
                )
            },
            renderHeader: () => {
                return (
                    <Typography className={classes.tableHeader}>
                        {t('createdOnHeader', {ns: 'rides'})}
                    </Typography>
                )
            },

        }
    ];

    return (
        <>
            <PaymentsSearch search={search}
                            isExportHidden={false}
                            setSearch={(e) => setSearch(e)}
                            onRefresh={async () => await refresh(search)}
                            onClear={async () => {
                                setSearch({...search, search: {mobile: ''}})
                                await refresh({...search, search: {mobile: ''}})
                            }}

            />
            {/*<Grid container justifyContent={'flex-end'}>*/}
            {/*    <Grid item style={{marginBottom: 10}}>*/}
            {/*        <Button*/}
            {/*            onClick={onExport}*/}
            {/*            variant="contained" color="primary" className={classes.addUser}>*/}
            {/*            {t("Export Excel")}*/}
            {/*        </Button>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
            <Paper elevation={2} style={{marginTop: '2rem', width: '100%', height: '50vh'}}>
                <SharedTable
                    columns={columns}
                    rows={results ?? []}
                    pageSize={search?.limit}
                    onPageChange={(i) => setSearch((s) => ({...s, offset: (i) * s.limit}))}
                    onPageSizeChange={(i) => setSearch((s) => ({...s, limit: i}))}
                    rowCount={count}
                    page={Math.ceil(search?.offset / search?.limit)}
                    getRowId={(i: any) => {
                        return i.id
                    }}
                />
            </Paper>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
        },
        tableHeader: {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: 15
        }
    }),
);


export default Component;
