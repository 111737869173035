import {Grid, TextField, Typography} from "@material-ui/core";
import {format, isValid, parseISO} from "date-fns";


interface IProps {
    label: string
    inputFormat?: string
    handleChange: (value: string) => any
    value: Date | undefined | string | null
    error?: string
    disabled?: boolean
    required?: boolean
    min?: Date
    max?: Date
}


const SharedDatePicker = (props: IProps) => {
    const {label, error, value, handleChange, disabled, required = false, min, max} = props;

    let formattedValue = "";
    if (value instanceof Date) {
        formattedValue = format(value, "yyyy-MM-dd");
    } else if (typeof value === "string" && isValid(parseISO(value))) {
        formattedValue = format(parseISO(value), "yyyy-MM-dd");
    }

    return (
        <Grid item container>
            <TextField variant={'outlined'}
                       id="date"
                       fullWidth
                       label={`${label}${required ? '*' : ''}`}
                       type="date"
                       value={formattedValue}
                       style={{borderRadius: '10px', width: '100%'}}
                       onChange={e => handleChange(e?.target?.value)}
                       InputLabelProps={{shrink: true}}
                       disabled={disabled}
                       inputProps={{min: min?.toISOString()?.split('T')[0], max: max?.toISOString()?.split('T')[0]}}
            />
            <Typography style={{visibility: error ? 'visible' : 'hidden'}}>
                {error}
            </Typography>
        </Grid>
    );
}

export default SharedDatePicker;
