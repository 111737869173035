import {Box, Modal, TextField} from "@material-ui/core";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {parseDate} from "Utils/dateUtils";
import Button from "@material-ui/core/Button";
import {ICreateVoucherModal} from "Types/Vouchers";

interface IProps {
    onClose: () => void
    onSubmit: (values: ICreateVoucherModal) => Promise<void>;
    type: "ADD" | "EDIT";
    initialValues?: ICreateVoucherModal;
}

const init = {
    code: '',
    value: 0,
    expiryDate: new Date(),
    usageLimit: 10
}
const CreateVoucherModal = ({onClose, initialValues, onSubmit, type}: IProps) => {
    const classes = useStyles();
    const {t} = useTranslation("promoCodes")
    const [createdData, setCreatedData] = useState<ICreateVoucherModal>(initialValues ?? init)

    return (
        <div className={classes.main}>
            <Modal open={true} className={classes.modal} onClose={onClose}>
                <div className={classes.paper}>
                    <Box>
                        <h2>{type === 'ADD' ? t('createVoucher',{ns: 'vouchers'}) : t('editVoucher', {ns: 'vouchers'})}</h2>
                    </Box>
                    <Box my={5} width={"100%"} display={"flex"}>
                        {type === 'ADD' && <TextField
                            style={{marginInline: 5}}
                            required
                            label={t("code", {ns: 'vouchers'})}
                            fullWidth
                            value={createdData.code}
                            onChange={(e) => setCreatedData({
                                ...createdData,
                                code: e.target.value
                            })}
                        />}
                        {type === 'ADD' && <TextField
                            style={{marginInline: 5}}
                            required
                            label={t("value", {ns: 'vouchers'})}
                            fullWidth
                            value={createdData.value}
                            onChange={(e) => setCreatedData({
                                ...createdData,
                                value: Number(e.target.value)
                            })}
                        />}
                        <TextField
                            style={{marginInline: 5}}
                            required
                            type={"date"}
                            label={t("expiredOn")}
                            fullWidth
                            value={parseDate(createdData.expiryDate || undefined, true)}
                            onChange={(e) => {
                                setCreatedData({
                                    ...createdData,
                                    expiryDate: new Date(e.target.value)
                                })
                            }
                            }
                        />
                        <TextField
                            style={{marginInline: 5}}
                            required
                            type={"number"}
                            label={t("UsageLimit")}
                            fullWidth
                            value={createdData.usageLimit}
                            onChange={(e) => setCreatedData({
                                ...createdData,
                                usageLimit: Number(e.target.value)
                            })}
                        />
                    </Box>
                    <Box width={"100%"} mt={2} display={"flex"} justifyContent={"flex-end"}>
                        <Button
                            disabled={(createdData.code.length < 3)}
                            onClick={() => {
                                onSubmit(createdData).then()
                            }}
                            variant="contained" color="primary"
                            className={classes.addUser}>
                            {t("save")}
                        </Button>
                    </Box>
                </div>
            </Modal>
        </div>
    )
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 1300,
            overflow: "scroll",
            padding: "3%",
            alignSelf: "center"
        },
        main: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 21

        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            minWidth: 1200,
            overflow: "scroll",

            padding: theme.spacing(2, 4, 3),
        },
        Chip: {
            color: "white"
        },
        redChip: {
            backgroundColor: "red"
        },
        blueChip: {
            backgroundColor: "blue"
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
            color: "white"

        },
        contentWrapper: {
            margin: '40px 16px',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: '32%',
            maxWidth: '100%',
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }),
);
export default CreateVoucherModal
