import {Box, Chip, FormControl, Input, InputLabel, MenuItem, Modal, Select, TextField} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {SetIsLoading, ShowAlert} from "../../../../State/Actions/UiActions";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ICreatePromoCode} from "../../../../Types/PromoCodes";
import PromoCodesClient from "../../../../Api/PromoCodesClient";
import {parseDate} from "../../../../Utils/dateUtils";
import Button from "@material-ui/core/Button";
import PackagesClient from "../../../../Api/PackagesClient";

interface IProps {
    onClose: () => void
}

const ActionsModal = ({onClose}: IProps) => {
    const classes = useStyles();
    const {t} = useTranslation("promoCodes")
    const [results, setResults] = useState<number[]>([]);
    const [createdData, setCreatedData] = useState<ICreatePromoCode>({
        code: '',
        discountPercentage: 0,
        ExpiryDate: new Date(),
        packagesIds: [],
        UsageLimit: 1
    })
    const dispatch = useDispatch();
    let client = new PromoCodesClient();

    const refresh = async () => {
        let packagesClient = new PackagesClient();
        dispatch(SetIsLoading(true))
        let res = await packagesClient.SearchAsync();
        if (res.succeeded) {
            setResults(res.data.map((x: any) => x.id));
        } else {
            dispatch(ShowAlert(t("Something went wrong"), "error"));
        }
        dispatch(SetIsLoading(false))
    }

    useEffect(() => {
        refresh().then()
    }, [])

    const onCreate = useCallback(async () => {
        dispatch(SetIsLoading(true))

        let res = await client.CreateAsync(createdData);
        if (res.succeeded) {
            onClose()
        }
        dispatch(SetIsLoading(false))
    }, [dispatch, createdData])

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCreatedData({...createdData, packagesIds: event.target.value as number[]});
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <div className={classes.main}>
            <Modal open={true} className={classes.modal} onClose={onClose}>
                <div className={classes.paper}>
                    <Box>
                        <h2>{t('create')}</h2>
                    </Box>
                    <Box my={5} width={"100%"} display={"flex"}>
                        <FormControl fullWidth={true} className={classes.formControl}>
                            <InputLabel id="demo-mutiple-chip-label">Package Id</InputLabel>
                            <Select
                                labelId="demo-mutiple-chip-label"
                                id="demo-mutiple-chip"
                                multiple
                                value={createdData.packagesIds}
                                onChange={handleChange}
                                input={<Input id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {(selected as string[])?.map((value) => (
                                            <Chip key={value} label={value} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {results?.map((id) => (
                                    <MenuItem key={id} value={id} >
                                        {id}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box my={5} width={"100%"} display={"flex"}>
                        <TextField
                            style={{marginInline: 5}}
                            required
                            label={t("promoCode")}
                            fullWidth
                            value={createdData.code}
                            onChange={(e) => setCreatedData({
                                ...createdData,
                                code: e.target.value
                            })}
                        />
                        <TextField
                            style={{marginInline: 5}}
                            required
                            type={"number"}
                            label={t("discount")}
                            fullWidth
                            value={createdData.discountPercentage}
                            onChange={(e) => setCreatedData({
                                ...createdData,
                                discountPercentage: Number(e.target.value)
                            })}
                        />
                        <TextField
                            style={{marginInline: 5}}
                            required
                            type={"date"}
                            label={t("expiredOn")}
                            fullWidth
                            value={parseDate(createdData.ExpiryDate || undefined, true)}
                            onChange={(e) => {
                                setCreatedData({
                                    ...createdData,
                                    ExpiryDate: new Date(e.target.value)
                                })
                            }
                            }
                        />
                        <TextField
                            style={{marginInline: 5}}
                            required
                            type={"number"}
                            label={t("UsageLimit")}
                            fullWidth
                            value={createdData.UsageLimit}
                            onChange={(e) => setCreatedData({
                                ...createdData,
                                UsageLimit: Number(e.target.value)
                            })}
                        />

                    </Box>
                    <Box width={"100%"} mt={2} display={"flex"} justifyContent={"flex-end"}>
                        <Button
                            disabled={!(createdData.code && createdData.ExpiryDate && createdData.discountPercentage)}
                            onClick={onCreate}
                            variant="contained" color="primary"
                            className={classes.addUser}>
                            {t("save")}
                        </Button>
                    </Box>
                </div>
            </Modal>
        </div>
    )
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 1300,
            overflow: "scroll",
            padding: "3%",
            alignSelf: "center"
        },
        main: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 21

        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            minWidth: 1200,
            overflow: "scroll",

            padding: theme.spacing(2, 4, 3),
        },
        Chip: {
            color: "white"
        },
        redChip: {
            backgroundColor: "red"
        },
        blueChip: {
            backgroundColor: "blue"
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        addUser: {
            marginRight: theme.spacing(1),
            color: "white"

        },
        contentWrapper: {
            margin: '40px 16px',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: '32%',
            maxWidth: '100%',
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }),
);
export default ActionsModal
