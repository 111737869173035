import React, {FunctionComponent, useState} from "react";
import {Box, Grid, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {DashboardFilter, DashboardFilterType} from "Types/Dashboard";
import DateRangeIcon from '@material-ui/icons/DateRange';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DateFilter from "Components/Views/Home/components/DateFilter";
import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core/styles";

interface IProps {
    data: Object
    onAdd: (e: DashboardFilter) => void;
    onRemove: (e: DashboardFilterType) => void;
    filters: DashboardFilter[]
}

const DataArrayFromObject: FunctionComponent<IProps> = ({data, filters, onRemove, onAdd}) => {
    const [dashboardValueDate, setDashboardValueData] = useState<DashboardFilter | undefined>()
    const classes = useStyles()
    const humanizeText = (string: string) => {
        return string.replace(/\W+/g, " ")
            .split(/ |\B(?=[A-Z])/)
            .map(word => word.toLowerCase())
            .join(' ');
    };

    function stringToEnumValue<T>(str: string, enumObj: T): T[keyof T] | false {
        const strUpperCase = (str.charAt(0).toUpperCase() + str.slice(1)) as keyof T;
        if (strUpperCase in enumObj) {
            return enumObj[strUpperCase];
        } else {
            return false;
        }
    }

    return (
        <>
            {
                dashboardValueDate &&
                <DateFilter value={dashboardValueDate}
                            modalType={dashboardValueDate.from ? "EDIT" : "ADD"}
                            onClose={() => setDashboardValueData(undefined)}
                            open={true} onAdd={onAdd}/>
            }
            <Grid container spacing={3} alignItems={"center"} justifyContent={"center"}>
                {
                    Object.keys(data).map((i) => {
                            const typeAsEnum = stringToEnumValue(i, DashboardFilterType)
                            return (
                                <Grid key={i} item md={4}>
                                    <Paper elevation={2} style={{minWidth: "200px"}}>
                                        <Box px={2} py={1}
                                             className={classes.container}>
                                            <Box className={classes.titleContainer}>
                                                <Typography><b>{humanizeText(i)}</b> </Typography>
                                                <Typography>{data[i as keyof object]}</Typography>
                                            </Box>
                                            {typeAsEnum && <Box>
                                                <DateRangeIcon className={classes.addIcon} onClick={() => {
                                                    const typeAsEnum = stringToEnumValue(i, DashboardFilterType)
                                                    setDashboardValueData({
                                                        type: typeAsEnum as DashboardFilterType,
                                                        from: filters?.find(x => x.type === typeAsEnum)?.from,
                                                        to: filters?.find(x => x.type === typeAsEnum)?.to

                                                    })
                                                }} fontSize={'medium'}/>
                                                {filters.some((x => x.type === typeAsEnum)) &&
                                                    <HighlightOffIcon className={classes.removeIcon} onClick={() => {
                                                        onRemove(typeAsEnum as DashboardFilterType)
                                                    }} fontSize={'medium'}/>
                                                }
                                            </Box>}
                                        </Box>
                                    </Paper>
                                </Grid>

                            )
                        }
                    )
                }
            </Grid>
        </>
    )
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {display: 'flex', flexDirection: 'row', justifyContent: 'space-between'},
        titleContainer: {display: 'flex', flexDirection: 'column', alignItems: 'flex-start'},
        addIcon: {color: theme.palette.primary.main, cursor: 'pointer'},
        removeIcon: {
            cursor: 'pointer',
            color: 'red'
        },
    }),
);


export default React.memo(DataArrayFromObject);
