import React, {useState} from "react";
import {Grid, Modal, TextField} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ScootersClient from "../../../../Api/ScootersClient";
import {ScooterConfig} from "Types/Scooters";

interface IProps {
    onClose: () => void
    initialValue: ScooterConfig
}

const ScooterConfigModal = (props: IProps) => {
    const classes = useStyles()
    const [errorMsg, setErrorMsg] = useState('');
    const {t} = useTranslation("scooters")
    const [config, setConfig] = useState<ScooterConfig>(props.initialValue)
    const client = new ScootersClient()
    const onSubmit = async () => {
        if (config) {
            const res = await client.AddScooterConfigAsync(config)
            if (res.succeeded) {
                props.onClose()
            } else {
                setErrorMsg(t('resolveMaintenanceFailedError'))
            }
        } else {
            setErrorMsg(t('requiredFieldsError'))
        }
    }
    return (
        <Modal
            open={true}
            onClose={props.onClose}
            className={classes.modal}
        >
            <div className={classes.paper}>
                <h2 id="simple-modal-title">{t("maintenanceResolve")}</h2>
                <Grid justifyContent={"center"} alignItems={"center"} container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            type={"number"}
                            required
                            label={t("minimumChargePercentageToStart")}
                            fullWidth
                            value={config.minimumChargePercentageToStart}
                            onChange={(e) => setConfig({...config, minimumChargePercentageToStart: Number(e.target.value)})}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            type={"number"}
                            required
                            label={t("timeSinceLastRide")}
                            fullWidth
                            value={config.timeSinceLastRide}
                            onChange={(e) => setConfig({...config, timeSinceLastRide: Number(e.target.value)})}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            type={"number"}
                            required
                            label={t("timeSinceLastLog")}
                            fullWidth
                            value={config.timeSinceLastLog}
                            onChange={(e) => setConfig({...config, timeSinceLastLog: Number(e.target.value)})}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color={'error'}>
                            {errorMsg}
                        </Typography>
                    </Grid>
                    
                    <Grid style={{textAlign: "end"}} item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                        >
                            {t("saveButton")}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    )
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: "scroll",

        },
        contentWrapper: {
            margin: '40px 16px',

        },
        paper: {
            maxWidth: 1200,
            backgroundColor: theme.palette.background.paper,

            padding: theme.spacing(2, 4, 3),
            margin: 'auto',
            overflow: 'hidden',
        },
    }),
)
export default ScooterConfigModal
