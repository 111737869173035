import IAppStateModel from "../Types/AppState";

const initialState: IAppStateModel = {
    alert:{
        open:false,
    },
    isLoading: false,


}

export default initialState;
