import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import {ApiResponse, ClientFactory, GetClientConfig} from "@simplify9/simplyapiclient";
import CookiesManager from "../Utils/CookiesManager";
import {IPagedSearch} from "Types/Paged";
import {ICreateVoucherModal} from "Types/Vouchers";

class VouchersClient {
    client: IClient;
    locale: string;

    constructor() {
        this.client = ClientFactory({...GetClientConfig()});
        this.locale = CookiesManager.getLocale();
    }


    async SearchAsync(
        search: IPagedSearch<{ code: string }>
    ): Promise<ApiResponse> {
        let query = `vouchers?limit=${search.limit}&offset=${search.offset}`
        if (search.search?.code)
            query += `&code=${search.search?.code}`
        const rs: ApiResponse = this.client.SimplyGetAsync(query);
        return rs;
    }


    async CreateAsync(data: ICreateVoucherModal): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync(`vouchers`, data);
        return rs;
    }

    async UpdateAsync(data: ICreateVoucherModal): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync(`vouchers/update`, data);
        return rs;
    }
}

export default VouchersClient;
